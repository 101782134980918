import { queryFilter } from 'utils/proptypes';
import base from './baseApi';

const { urls, methods, execute } = base();
const getDegrees = async (queryFilter: queryFilter) => {
  const method = methods.GET;

  const url = `${urls.degrees.degrees}`;

  const response = await execute(method, url, { authorization: false }, queryFilter);

  return response;
};

const getDegreeDetail = async (queryFilter: queryFilter) => {
  const { id } = queryFilter;

  const method = methods.GET;

  const url = `${urls.degrees.degreeDetail}/${id}`;

  const response = await execute(method, url, { authorization: false });

  return response;
};

const uploadImage = (payload: { file?: any }) => {
  const { file } = payload;
  const method = methods.POST;
  const url = `${urls.images}`;
  const response = execute(method, url, { authorization: true }, file);
  return response;
};

const postDegree = async (payload: any) => {
  const method = methods.POST;

  const url = `${urls.degrees.degreeDetail}`;

  const response = await execute(method, url, { authorization: true }, payload);

  return response;
};

const updateDegree = async (payload: any) => {
  const method = methods.PUT;

  const url = `${urls.degrees.degreeDetail}/${payload.id}`;

  const response = await execute(method, url, { authorization: true }, payload.payload);

  return response;
};

export { getDegrees, getDegreeDetail, uploadImage, postDegree, updateDegree };
