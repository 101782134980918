import PaginateTable from 'components/table/paginate';
import { status, searchMaxLength, pageCount, routePaths } from 'utils/constants';
import { useEffect, useState, useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { initUser } from 'utils/initData';
import { useTranslation } from 'react-i18next';
import { degreeItemType } from 'utils/proptypes';
import NoResult from 'components/commonComponent/noResult';
import SpinnerComponent from 'components/spinner';
import { isEqual } from 'lodash';
import 'styles/styles.scss';
import { getDegrees, updateDegree } from 'api/degreesApi';
import DataTable from 'components/commonComponent/DataTable';
import { HiDocumentDownload, HiDotsVertical, HiOutlineCog, HiPlus, HiPencilAlt, HiRefresh, HiX } from 'react-icons/hi';
import debouce from 'lodash.debounce';
import { Button } from 'flowbite-react';
import './degrees.scss'
import { useNavigate } from 'react-router-dom';
import HeaderAdmin from '../adminHeader';
import { showToast } from 'utils/utils';

const AdminDegrees = () => {
  const [t] = useTranslation();
  const navigate = useNavigate()
  const queryClient = useQueryClient();

  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEntities, setTotalEntities] = useState(0);
  const [degrees, setDegrees] = useState([]);
  const [degreeData, setDegreeData] = useState<any[]>([]);

  const { isLoading, data } = useQuery(['getDegrees', currentPage, searchValue], () => getDegrees({
    page: currentPage,
    limit: pageCount,
    searchQuery: searchValue,
  }), {
    staleTime: Infinity,
    onSuccess: ({ data }) => {
      setTotalEntities(data.totalEntities);
    },
    onError: () => {
      setDegrees([]);
    },
  });



  const { mutate: updateStatus } = useMutation('updateDegree', {
    mutationFn: updateDegree,
    onSuccess: () => {
      showToast('success', t('degree.updateDegreeStatusSuccessful'))
      queryClient.invalidateQueries('getDegrees')
    },
    onError: () => {
      showToast('error', t('degree.createDegreeError'))
    }
  });

  const handleAddDegree = () => {
    navigate(routePaths.ADD_DEGREE)
  }

  const handleOpenDetailDegree = (id: string) => {
    navigate(`${routePaths.AMDIN_DEGREES}/${id}`)

  }


  const handleChangeStatus = (item: any) => {
    const newStatus = item.status === status.ACTIVE ? status.INACTIVE : status.ACTIVE
    const payload = {
      payload: { status: newStatus },
      id: item.id
    }
    updateStatus(payload)
  }

  useEffect(() => {
    const degreesTemp = data ? data?.data?.entities.map((item: degreeItemType) => {
      return {
        title: <p className='font-medium text-gray-800	'>{item.title}</p>,
        university: item?.institution?.name,
        pacing: item.pacing.charAt(0).toUpperCase() + item.pacing.slice(1),
        students: item?.subscriberNumber,
        degreeType: item?.degreeType?.name,
        status: item?.status === status.ACTIVE ? (<div className="flex">
          <div className="flex items-center">
            <span className="rounded-full bg-green-400  w-3 h-3  mr-3 " />
          </div> <span>{status.ACTIVE}</span>
        </div>) : (<div className="flex">
          <div className="flex items-center">
            <span className="rounded-full bg-red-500 w-3 h-3 mr-3 " />
          </div> <span>{status.INACTIVE}</span>
        </div>),
        action: (<div className='flex'>
          <Button className="button-edit mr-2" pill={true} onClick={() => handleOpenDetailDegree(item.id)}>
            <HiPencilAlt className="mr-1 h-4 w-4" />
            Edit
          </Button>
          {
            item?.status === status.ACTIVE ? (
              <Button className="button-deactivate" pill={true} onClick={() => handleChangeStatus(item)}>
                <HiX className="mr-1 h-4 w-4" />
                Deactivate
              </Button>
            )
              :
              (
                <Button className="button-activate" pill={true} onClick={() => handleChangeStatus(item)}>
                  <HiRefresh className="mr-1 h-4 w-4" />
                  Activate
                </Button>
              )
          }

        </div>
        )
      };
    }) : [];
    setDegreeData(degreesTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setCurrentPage(0)
    setTotalEntities(data?.data.totalEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const handleChange = (e: any) => {
    setSearchValue(e.target.value);
  };
  const debouncedResults = useMemo(() => {
    return debouce(handleChange, 700);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleOpenDetail = () => { }

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const columns = [
    {
      name: t('degree.name'),
      selector: 'title',
      isStatus: false,
      isRating: false,
    },
    {
      name: t('degree.university'),
      selector: 'university',
      isStatus: false,
      isRating: false,
    },
    {
      name: t('degree.pacing'),
      selector: 'pacing',
      isStatus: false,
      isRating: false,
    },
    {
      name: t('degree.degreeType'),
      selector: 'degreeType',
      isStatus: false,
      isRating: false,
    },
    {
      name: t('degree.students'),
      selector: 'students',
      isStatus: false,
      isRating: false,
    },
    {
      name: t('degree.status'),
      selector: 'status',
      isStatus: false,
      isRating: false,
    },
    {
      name: '',
      selector: 'action',
      isStatus: false,
      isRating: false,
    },
  ];

  const Header = useMemo(() => {
    return (
      <div className="w-full mb-4 sm:mb-0 text-gray-600 flex flex-1 flex-row items-center justify-between">
        <div className='flex w-3/4'>
          <input
            className="w-1/2 border-1 border-gray-300 bg-white h-9 rounded-lg text-sm focus:outline-none"
            type="search"
            name="search"
            maxLength={searchMaxLength}
            onChange={debouncedResults}
            placeholder="Search" /><span className="text-lg text-gray-200 ml-3 mr2">|</span><div className="cursor-pointer text-gray-500 w-9 h-9 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-white dark:bg-gray-600 focus:!ring-2 group flex h-min w-fit items-center justify-center p-0.5 ml-1 text-center font-medium focus:z-10 rounded-lg">
            <HiOutlineCog className="h-6 w-6" />
          </div>

          <div className="cursor-pointer text-gray-500 w-9 h-9 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-white dark:bg-gray-600 focus:!ring-2 group flex h-min w-fit items-center justify-center p-2.5 text-center font-medium focus:z-10 rounded-lg">
            <HiDotsVertical className="h-6 w-6" />
          </div>
        </div>
        <div className="flex w-full sm:w-auto justify-start sm:justify-end flex-row gap-2">
          <Button pill={true} color="success" onClick={handleAddDegree} className="add-action custom-button-style">
            <HiPlus className="mr-1 h-4 w-4 " />
            Add a Degree
          </Button>
          <Button className="button-export">
            <HiDocumentDownload className="mr-1 h-4 w-4" />
            {t('export')}
          </Button>
        </div>
      </div >
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  return (
    <>
      <HeaderAdmin>
        {Header}
      </HeaderAdmin>
      {!isEqual(degrees[0], initUser) && totalEntities === 0 && !isLoading ? <NoResult /> :
        (<>
          {isLoading ? <SpinnerComponent /> : <DataTable columns={columns} data={degreeData} onRowClicked={handleOpenDetail} />}
          <PaginateTable setCurrentPage={setCurrentPage} currentPage={currentPage} totalEntities={totalEntities} isLoadingTable={isLoading} />
        </>)
      }
    </>
  );
};
export default AdminDegrees;
