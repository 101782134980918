import { HiX } from 'react-icons/hi';
import './courseItem.scss'

const CourseItem = (props: any) => {
  const { course, handleRemoveCourse } = props
  return (<div className="flex w-full h-20 course-item py-2 ">
    <div className="w-2/6 h-full py-1 flex justify-center"  >
      <img
        className="rounded-lg object-cover h-full"
        alt="test name only"
        src={course.imageUrl ? course.imageUrl : ''}
      />
    </div>
    <div className="w-3/6 flex justify-center items-center p-1"><p className='title'>{course.title}</p></div>
    <div className='w-1/6 flex justify-end items-center pr-3'>
      <span
        onClick={() => handleRemoveCourse(course.id)}
        className="rounded-sm bg-white/50 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 "
      >
        <HiX className='w-5 h-5' />
      </span>
    </div>
  </div>)
}


export default CourseItem