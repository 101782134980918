import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  degree: {},
};

const setDegreeDetail = (state: any, action: any) => {
  state.degree = action.payload;
};

const degreeDetailSlice = createSlice({
  name: 'degreeDetail',
  initialState,
  reducers: {
    setDegreeDetail,
  },
});

export default degreeDetailSlice;
