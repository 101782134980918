import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  courseCategories: [],
};

const setCourseCategories = (state: any, action: any) => {
  state.courseCategories = action.payload;
};

export const courseCategoriesSlice = createSlice({
  name: 'courseCategories',
  initialState,
  reducers: {
    setCourseCategories,
  },
});
export default courseCategoriesSlice;
