import { ClockIcon, /*EyeIcon,*/ GlobeAltIcon } from '@heroicons/react/24/outline';
import './summary.scss';
import moment from 'moment';
import { renderLanguage } from 'utils/utils';

const Summary = (props: any) => {
  const {item, isDegree} = props;

  const description = isDegree ? item.shortDescription : item.courseOpenEdxData?.short_description;

  return (
    <div className="w-full bg-gray-900 text-white flex">
      <div className="w-full max-w-8xl py-6 sm:px-14 px-12">
        <p className="text-3xl mb-2">{item.title}</p>
        <p className="mb-2">{description} </p>
        <p className="text-xs mb-2">
          <span>{item?.institution?.name}</span>
        </p>

        {/* <p className="text-xs mb-2">
          Created by{' '}
          <span className="underline underline-offset-auto text-blue">
            {item.createdBy ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}` : ''}
          </span>
        </p> */}
        <div className="flex">
          <div className="flex mr-5">
            <ClockIcon className="w-4 h-4 text-white mr-1" />
            <p className="text-xs">
              Last updated <span className="underline underline-offset-auto text-blue"> {moment(item.modified).format('MM/YYYY')}</span>
            </p>
          </div>
          <div className="flex mr-5">
            <GlobeAltIcon className="w-4 h-4 text-white mr-1" />
            <span className="text-xs">{renderLanguage(item.language)} </span>
          </div>
          {/* <div className="flex">
            <EyeIcon className="w-4 h-4 text-white mr-1" />
            <span className="text-xs">{item.subscriberNumber} students</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Summary;
