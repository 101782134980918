import { HiPencilAlt, HiRefresh, HiX, HiCurrencyDollar } from 'react-icons/hi';
import HeaderTable from 'components/table/headerTable';
import PaginateTable from 'components/table/paginate';
import { apiStatus, exportFileName, pageCount, status, BUTTON_COLOR, page_default, limit_default } from 'utils/constants';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getUserByFilter, updateActiveUserById } from 'api/userApi';
import { initUser } from 'utils/initData';
import { useTranslation } from 'react-i18next';
import { positionType, roleType, userType, degreeType } from 'utils/proptypes';
import * as XLSX from 'xlsx';
import UserModal from 'components/modal/userModal';
import NoResult from 'components/commonComponent/noResult';
import { toast } from 'react-toastify';
import SpinnerComponent from 'components/spinner';
import { isEqual } from 'lodash';
import '../styles/styles.scss';
import { getPositions } from 'api/positionApi';
import { getRoles } from 'api/rolesApi';
import { getDegrees } from 'api/degreesApi';
import ButtonIconWithText from '../components/button/buttonIconWithText';
import { messageErrors } from '../utils/utils';
import SubscriptionModal from '../components/modal/subscriptionModal';
import { getPlans } from '../api/subscriptionApi';

const UserManagementPage = () => {
  const [t] = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEntities, setTotalEntities] = useState(0);
  const [users, setUsers] = useState([initUser]);
  const [targetUser, setTargetUser] = useState(initUser);
  const [openModal, setOpenModal] = useState(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const defaultPositions: [positionType] = [{ name: '', id: '' }];
  const defaultPlans: [positionType] = [{ name: '', id: '' }];
  const defaultRoles: [roleType] = [{ displayName: '', id: '' }];
  const defaultDegrees: [degreeType] = [{ title: '', id: '' }];
  const [positions, setPositions] = useState(defaultPositions);
  const [plans, setPlans] = useState(defaultPlans);
  const [roleOptions, setRoleOptions] = useState(defaultRoles);
  const [degreeOptions, setDegreeOptions] = useState(defaultDegrees);

  const handleClickAddUser = () => {
    setTargetUser(initUser);
    setOpenModal(true);
    setIsEdit(false);
  };

  const getUsers = useMutation('getUsers', {
    mutationFn: getUserByFilter,
    onSuccess: ({ data }) => {
      setUsers(data.entities);
      setTotalEntities(data.totalEntities);
    },
    onError: error => {
      setUsers([initUser]);
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const fetchUsers = () => {
    getUsers.mutate({ page: currentPage, limit: pageCount, searchQuery: searchValue });
  };

  const handleSearch = () => {
    getUsers.mutate({ page: 0, limit: pageCount, searchQuery: searchValue });
    setCurrentPage(0);
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, [currentPage]);

  const handleClickEdit = (item: userType) => {
    setTargetUser(item);
    setIsEdit(true);
    setOpenModal(true);
  };

  const handleClickSubscription = (item: userType) => {
    setTargetUser(item);
    setIsEdit(true);
    setOpenSubscriptionModal(true);
  };

  const { refetch: fetchRoles } = useQuery(
    'getRoles',
    () =>
      getRoles({
        page: page_default,
        limit: limit_default,
      }),
    {
      enabled: false,
      onSuccess: ({ data }) => {
        setRoleOptions(data.entities);
      },
      onError: () => {
        setRoleOptions(defaultRoles);
      },
    },
  );

  const { refetch: fetchPosition } = useQuery('getPosition', () => getPositions(), {
    enabled: false,
    onSuccess: ({ data }) => {
      setPositions(data.entities);
    },
    onError: () => {
      setPositions(defaultPositions);
    },
  });

  const { refetch: fetchDegree } = useQuery(
    'getDegrees',
    () =>
      getDegrees({
        page: page_default,
        limit: limit_default,
      }),
    {
      enabled: false,
      onSuccess: ({ data }) => {
        setDegreeOptions(data.entities);
      },
      onError: () => {
        setDegreeOptions(defaultDegrees);
      },
    },
  );

  const { refetch: fetchPlans } = useQuery('getPlans', () => getPlans(), {
    enabled: false,
    onSuccess: ({ data }) => {
      if (Array.isArray(data.entities)) {
        const options = data.entities.map((item: any) => {
          return { value: item.id, label: item.name, interval: item.interval, duration: item.duration };
        });
        setPlans(options);
      }
    },
    onError: () => {
      setPlans(defaultPlans);
    },
  });

  const handleActiveUser = async (data: userType) => {
    const response = await updateActiveUserById(data.id, { isActive: !data.isActive });
    response.status === apiStatus.SUCCESS && fetchUsers();
  };
  const exportUserHandler = () => {
    const name = exportFileName.USER;
    if (users[0] !== initUser && users.length > 0) {
      const exportedData = users.map((user: userType) => {
        return {
          'First name': user.firstName,
          'Last name': user.lastName,
          Email: user.emailAddress,
          Position: user.position,
          Status: user.isActive,
        };
      });
      const wb = XLSX.utils.json_to_sheet(exportedData);
      const wbout = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wbout, wb, 'test');
      XLSX.writeFile(wbout, name);
    }
  };

  useEffect(() => {
    fetchPosition();
    fetchRoles();
    fetchDegree();
    fetchPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full">
      <HeaderTable
        placeholderSearch="Search for users"
        buttonName="Add User"
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        handleAddClick={handleClickAddUser}
        handleOnClickExport={exportUserHandler}
        fetchData={handleSearch}
      />

      {!isEqual(users[0], initUser) && totalEntities === 0 && <NoResult />}
      {!isEqual(users[0], initUser) && users.length > 0 && (
        <div className="overflow-x-auto relative">
          <table className="w-full text-sm text-left dark:text-gray-400">
            <thead className="text-sm text-gray-600 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="py-3 px-4">
                  {t('userManagementPage.fullName')}
                </th>
                <th scope="col" className="py-3 px-4 hidden-mobile">
                  {t('userManagementPage.email')}
                </th>
                <th scope="col" className="py-3 px-4 hidden-mobile-tablet">
                  {t('userManagementPage.position')}
                </th>
                <th scope="col" className="py-3 hidden-mobile-tablet">
                  {t('userManagementPage.status')}
                </th>
                <th scope="col" className="py-3 w-6" />
                <th scope="col" className="py-3 w-6" />
              </tr>
            </thead>
            <tbody>
              {users?.map((item: userType, key) => {
                return (
                  <tr
                    key={`${key}-list-user`}
                    className="font-medium text-gray-900 bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600"
                  >
                    <td className="py-3 px-4 break-word dark:text-white font-semibold">{`${item.firstName || ''} ${item.lastName || ''}`}</td>
                    <td className="py-3 px-4 hidden-mobile font-medium text-gray-900 dark:text-white">{item.emailAddress}</td>
                    <td className="hidden-mobile-tablet py-3 px-4 font-medium text-gray-900 dark:text-white">{item.position?.name}</td>
                    <td className="hidden-mobile-tablet py-3 px-4">
                      <div className="flex flex-row items-center">
                        {item.isActive ? (
                          <span className="w-3 h-3 bg-green-400 rounded-lg mr-2 " />
                        ) : (
                          <span className="w-3 h-3 bg-red-500 rounded-lg mr-2 " />
                        )}
                        {item.isActive ? status.ACTIVE : status.INACTIVE}
                      </div>
                    </td>
                    <td className="py-3 flex">
                      <ButtonIconWithText
                        text={t('userManagementPage.edit')}
                        onClick={() => handleClickEdit(item)}
                        className="edit-btn cursor-pointer p-0.5 text-white bg-blue-600
                      border-gray-300 hover:bg-blue-700 focus:ring-4
                      focus:ring-blue-300 focus:!ring-2 group flex h-min
                      w-fit items-center justify-center text-center
                      font-medium focus:z-10 rounded-lg mr-2"
                        Icon={HiPencilAlt}
                        color={BUTTON_COLOR.BLUE}
                      />
                      <ButtonIconWithText
                        text={t('userManagementPage.subscription')}
                        onClick={() => handleClickSubscription(item)}
                        className="subscription-btn cursor-pointer p-0.5 text-white text-yellow-400
                      border-yellow-300 border-1 hover:bg-yellow-700 focus:ring-4
                      focus:ring-yellow-300 focus:!ring-2 group flex h-min
                      w-fit items-center justify-center text-center
                      font-medium focus:z-10 rounded-lg mr-2"
                        Icon={HiCurrencyDollar}
                        color={BUTTON_COLOR.YELLOW}
                      />

                      <ButtonIconWithText
                        text={item.isActive ? <span>{t('userManagementPage.deactivate')}</span> : <span>{t('userManagementPage.reactivate')}</span>}
                        onClick={() => handleActiveUser(item)}
                        Icon={item.isActive ? HiX : HiRefresh}
                        color={item.isActive ? BUTTON_COLOR.RED : BUTTON_COLOR.GRAY}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {getUsers.isLoading && <SpinnerComponent />}
          <PaginateTable
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalEntities={totalEntities}
            isLoadingTable={getUsers.isLoading}
          />
        </div>
      )}
      <UserModal
        headerTitle={isEdit ? t('userManagementPage.editUser') : t('userManagementPage.addUser')}
        isEdit={isEdit}
        openModal={openModal}
        setOpenModal={setOpenModal}
        targetData={targetUser}
        successFunc={fetchUsers}
        positionOptions={positions}
        listRole={roleOptions}
        listDegree={degreeOptions}
      />
      {openSubscriptionModal && (
        <SubscriptionModal
          openModal={openSubscriptionModal}
          setOpenModal={setOpenSubscriptionModal}
          modalData={targetUser}
          successFunc={fetchUsers}
          planOptions={plans}
        />
      )}
    </div>
  );
};
export default UserManagementPage;
