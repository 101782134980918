/**
* Last Modified: June 14, 2023
* Last Modified By: Sheriff Issaka
* Modification Description: Add file header
* Description: Handles the enrollment and access management for a course
**/

import { Button, Card, Dropdown, Spinner } from 'flowbite-react';
import { Trans, useTranslation } from 'react-i18next';
import './CardCourseEnroll.scss';
import { CURRENCY_OPTIONS, routePaths } from 'utils/constants';
import { formatPrice, getLevelNameSubscription, getLevelPacing, messageErrors } from 'utils/utils';
import PaymentFlutterWaveButton from 'components/payment/flutterwave/paymentFlutterwaveButton';
import { useDispatch, useSelector } from 'react-redux';
import { ratesSelector } from 'redux/selectors';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getExchangeRates } from 'api/subscriptionApi';
import { courseDetailAction, ratesAction } from 'redux/actions';
import { useNavigate } from 'react-router-dom';
import { enrollCourse } from 'api/coursesApi';
import { getToken } from '../../components/Auth0';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import React from 'react';

const CardCourseEnroll = (props: any) => {
  const { onViewCourseOpenEdx, userInfo } = props;
  const [selectedCurrency, setSelectedCurrency] = useState(CURRENCY_OPTIONS.find(item => item.value === 'USD'));
  const [course, setCourse] = useState(props.course);
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rates } = useSelector(ratesSelector);
  const languageCode = i18n.language;
  const { isLoading } = useQuery(['getExchangeRates'], () => getExchangeRates(), {
    staleTime: Infinity,
    onSuccess: data => dispatch(ratesAction.setRates(data?.data?.conversion_rates)),
  });
  const mutation = useMutation('enrollCourse', { mutationFn: enrollCourse });

  useEffect(() => {
    setCourse(props.course);
  }, [props.course]);

  const handleEnrollNow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!userInfo?.id) {
      navigate(routePaths.LOGIN_PAGE);
    } else {
      navigate(routePaths.SUBSCRIPTION);
    }
  };

  const handleGetStarted = async () => {
    if (!userInfo?.id) {
      navigate(routePaths.LOGIN_PAGE);
      return;
    }
    const userAuth0Token = await getToken();
    const edxCourseId: any = course.edxCourseId;
    const body = { edxCourseId, userAuth0Token };
    mutation.mutate(body, {
      onSuccess: () => {
        const newCourse = cloneDeep(course);
        newCourse.isEnrollment = true;
        newCourse.isPurchase = false;
        newCourse.isUpgrade = false;
        newCourse.isStart = false;
        dispatch(courseDetailAction.setCourseDetail(newCourse));
        setCourse(newCourse);
      },
      onError: (error: any) => {
        const message: string = messageErrors(error, t);
        toast.error(message);
      },
    });
  };

  const renderViewYourCourse = () => (
    <div className="container-card">
      <div className="information">
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{t('coursePage.continueLearning')}</h5>
        <p className="text-sm mb-3 mt-3">{t('coursePage.enrolledDescription')}</p>
      </div>
      <div className="enroll">
        <div className="w-full mb-2 button-enroll">
          <Button pill={true} color="success" onClick={() => onViewCourseOpenEdx()} className="custom-button-style">
            {t('enrollCard.viewYourCourse')}
          </Button>
        </div>
      </div>
    </div>
  );

  const renderStart = () => (
    <div className="container-card">
      <div className="information">
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{t('coursePage.beginEnrollment')}</h5>
        <p className="text-sm mb-3 mt-3">{t('coursePage.enrollmentDescription')}</p>
      </div>
      <div className="enroll">
        <div className="w-full mb-2 button-started">
          {mutation.isLoading ? (
            <Button>
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
              {t('loading')} ...
            </Button>
          ) : (
            <Button pill={true} color="success" onClick={() => handleGetStarted()} className="custom-button-style">
              {t('enrollCard.getStarted')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );

  const renderPurchaseAccess = () => (
    <div className="container-card">
      <div className="information">
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{t('coursePage.individualAccess')}</h5>
        <p className="text-sm mb-3 mt-3">{t('coursePage.individualAccessDescription')}</p>
      </div>
      <div className="enroll">
        <div className="w-full mb-2 button-started">
          {mutation.isLoading ? (
            <Button>
              <div className="mr-3">
                <Spinner size="sm" light={true} />
              </div>
              {t('loading')} ...
            </Button>
          ) : (
            <PaymentFlutterWaveButton
              txRef={`my-yam-course-${new Date().getTime()}`}
              courseId={course.id}
              amount={rates[`${selectedCurrency?.value}`] * course.priceUSD}
              currency={selectedCurrency?.value}
              label={t('enrollCard.individualAccess')}
              callback={() => handleGetStarted()}
            />
          )}
        </div>
        <div className="flex justify-center">
          <div className="mt-2 relative">
            {!isLoading ? (
              <Dropdown
                className="price-dropdown"
                arrowIcon={true}
                inline={true}
                placement="bottom"
                label={
                  <div className="font-bold">
                    <span>{selectedCurrency?.symbol}</span>
                    <span>{formatPrice(rates[`${selectedCurrency?.value}`] * course.priceUSD, selectedCurrency?.value || 'USD', languageCode)}</span>
                  </div>
                }
              >
                <div className="h-48 w-24 dropdown-item">
                  {CURRENCY_OPTIONS.map((item, index) => (
                    <Dropdown.Item key={index} onClick={() => setSelectedCurrency(item)}>
                      {item.label}
                    </Dropdown.Item>
                  ))}
                </div>
              </Dropdown>
            ) : (
              <div className="loading-currency">
                <Spinner size="md" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const renderUpgrade = () => (
    <div className="container-card">
      <div className="information">
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{t('coursePage.upgradeSubscription')}</h5>
        <p className="text-sm mb-3 mt-3">
          <Trans
            i18nKey="coursePage.upgradeSubscriptionDescription"
            values={{ name: getLevelNameSubscription(getLevelPacing(course?.pacing)) }}
            components={{
              span: <span className="font-bold" />,
            }}
          />
        </p>
      </div>
      <div className="enroll">
        <div className="w-full mb-2 button-started">
          <Button pill={true} color="success" onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleEnrollNow(e)} className="custom-button-style">
            {t('enrollCard.upgradePlan')}
          </Button>
        </div>
      </div>
    </div>
  );

  const renderUnavailable = () => (
    <div className="container-card">
      <div className="information">
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{t('coursePage.unavailableTitle')}</h5>
        <p className="text-sm mb-3 mt-3">{t('coursePage.unavailableDescription')}</p>
      </div>
    </div>
  );

  return (
    <div className="w-11/12 card-enroll">
      <Card href="#">
        {course.isUnavailable && renderUnavailable()}
        {course.isEnrollment && renderViewYourCourse()}
        {course.isPurchase && renderPurchaseAccess()}
        {course.isUpgrade && renderUpgrade()}
        {course.isStart && renderStart()}
        <hr className="border-gray-300" />
      </Card>
    </div>
  );
};

export default CardCourseEnroll;
