import FileUpload from 'components/commonComponent/fileUpload';
import { useState } from 'react';
import { updateUpdatedProfile, updateUserById, updateUserImage } from 'api/userApi';
import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useUser from 'hooks/useUser';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/selectors';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import FormComponent from 'components/form/form';
import GroupButton from 'components/button/groupButton';
import validator from 'validator';
import ChangePasswordModal from '../components/modal/ChangePasswordModal';
import '../styles/styles.scss';
import { messageErrors } from '../utils/utils';

type updateProfileProps = {
  isNavigatedFromDashboard?: boolean;
};
const UpdateProfile = (props: updateProfileProps) => {
  const { isNavigatedFromDashboard = false } = props;
  const [t] = useTranslation();
  const { fetchCurrentUser } = useUser();
  const { userInfo } = useSelector(userSelector);
  const navigate = useNavigate();
  const [file, setFile] = useState('');
  const [imgUrl, setImgUrl] = useState(userInfo?.image?.url || '');
  const [imgSizeError, setImgSizeError] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleRemoveImage = () => {
    setFile('');
    setImgUrl('');
  };

  const changePasswordHandler = () => {
    setOpenModal(true);
  };

  const handleUploadImage = async (e: any) => {
    const file = e.target.files[0];
    if (file.size > 8000000) {
      setImgSizeError(true);
      return;
    }
    setImgUrl(URL.createObjectURL(file));
    file.isUploaded = true;
    setFile(file);
    setImgSizeError(false);
  };

  const handleUpdateUser = async (values: any) => {
    await updateUserById(userInfo.id, _.omit({ ...values, updatedProfile: true }, ['organizations', 'roles']));

    if (file !== '') {
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await updateUserImage({ id: userInfo.id, file: formData });
      setImgUrl(data.image.url);
    }
  };

  const handleClickSkip = async () => {
    skipUpdateProfile();
  };

  const { refetch: skipUpdateProfile } = useQuery('skip-update-user', () => updateUpdatedProfile(userInfo.id, { updatedProfile: false }), {
    enabled: false,
    onSuccess: async () => {
      await fetchCurrentUser();
      isNavigatedFromDashboard && navigate('/');
    },
  });

  const mutationUpdateUser = useMutation('update-user', {
    mutationFn: handleUpdateUser,
    onSuccess: async () => {
      await fetchCurrentUser();
      const message: string = t('user.updateSuccess');
      toast.success(message);
      isNavigatedFromDashboard && navigate('/');
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const handleSubmit = (values: any) => {
    mutationUpdateUser.mutate(values);
  };

  const onValidate = (values: any) => {
    const errors: any = {};
    if (!values.firstName) errors.firstName = t('requiredField');
    if (!values.lastName) errors.lastName = t('requiredField');
    if (!values.emailAddress) errors.emailAddress = t('requiredField');
    if (!validator.isEmail(values.emailAddress)) errors.emailAddress = t('invalidEmail');

    return errors;
  };

  const renderUserForm = (isNavigatedFromDashboard: boolean) => {
    return (
      <div className="flex flex-wrap items-center shadow-sm bg-white p-6 rounded-lg justify-center gap-4">
        <FileUpload handleUploadImage={handleUploadImage} imgUrl={imgUrl} handleRemoveImage={handleRemoveImage} />
        {imgSizeError && <div className="text-red-600 text-xs font-normal mt-1 veri-modal height-16">{t('userProfilePage.imageSizeError')}</div>}
        <Formik enableReinitialize onSubmit={handleSubmit} initialValues={userInfo || {}} validate={onValidate}>
          <Form className="grid gap-4 lg:grid-cols-2 grid-rows-2 w-full sm:grid-cols-1">
            <FormComponent label={t('userProfilePage.firstName')} id="firstName" type="text" placeholder="Jese" name="firstName" />
            <FormComponent label={t('userProfilePage.lastName')} id="lastName" type="text" placeholder="Leos" name="lastName" />
            <FormComponent
              label={t('userProfilePage.email')}
              id="emailAddress"
              type="email"
              placeholder="jeseleos@milemarker.io"
              name="emailAddress"
              disabled={true}
            />
            {isNavigatedFromDashboard && (
              <div className="flex items-end justify-end">
                <GroupButton
                  className="items-center justify-center"
                  buttons={[
                    {
                      type: 'button',
                      text: t('userProfilePage.skip'),
                      classType: 'white',
                      action: handleClickSkip,
                    },
                    {
                      type: 'submit',
                      text: t('userProfilePage.saveAndContinue'),
                      classType: 'blue',
                      isLoading: mutationUpdateUser.isLoading,
                    },
                  ]}
                />
              </div>
            )}
            {!isNavigatedFromDashboard && (
              <div className="flex items-end justify-end">
                <GroupButton
                  className="items-center justify-center"
                  buttons={[
                    {
                      type: 'button',
                      text: t('userProfilePage.changePassword'),
                      classType: 'white',
                      action: changePasswordHandler,
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                      isLoading: mutationUpdateUser.isLoading,
                    },
                  ]}
                />
              </div>
            )}
          </Form>
        </Formik>
      </div>
    );
  };

  return (
    <>
      {isNavigatedFromDashboard && (
        <div className="flex flex-col items-center pt-24 mx-auto h-full bg-gray-50">
          <h1 className="text-lg">
            <strong>{t('userProfilePage.almostDone')}</strong>
          </h1>
          <p className="pb-10 pt-4 px-2">{t('userProfilePage.addYourInfo')}</p>
          <div className="bg-white shadow-sm rounded-lg m-auto">{renderUserForm(true)}</div>
        </div>
      )}
      {!isNavigatedFromDashboard && (
        <div className="w-1/2 p-8 custom-w-full">
          {renderUserForm(false)}
          <ChangePasswordModal nameHeader="Change Password" openModal={openModal} setOpenModal={setOpenModal} />
        </div>
      )}
    </>
  );
};
export default UpdateProfile;
