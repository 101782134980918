import { Progress } from 'flowbite-react';
import { HiChevronRight } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';

const DegreeItem = (props: any) => {
  const { imageUrl, name, id, hasProgress, handleOpenDetail, handleClickOnCard } = props;
  const [t] = useTranslation();

  const placeholder = require('assets/image/placeholder.png');

  return (
    <div
      className="card-item rounded-lg shadow-md bg-white focus:outline-none cursor-pointer "
      onClick={handleClickOnCard ? () => handleClickOnCard(id, name) : undefined}
    >
      <img className="rounded-t-lg object-cover" alt="test name only" src={imageUrl ? imageUrl : placeholder} />
      <div className="py-1 px-5">
        <div>
          <span className="text-md font-normal text-gray-900 truncate">University of Alberta</span>
        </div>
        <div>
          <p className="program-title text-xl font-semibold text-gray-900 truncate break-normal border-b border-gray-100 mb-2 pb-1">{name}</p>
        </div>
      </div>
      <div className="px-5 time">
        <p className="text-sm font-normal text-gray-500 truncate">Beginner 1-3 months</p>
        <p className="text-sm font-normal text-gray-500 truncate">Application February 1, 2023</p>
      </div>
      {hasProgress ? (
        <div className="p-5 progress">
          <Progress progress={45} size="sm" color="green" />
          <div className="text-sm	mt-1 text-gray-600 font-light font-small dark:text-gray mb-1">45% completed</div>
        </div>
      ) : (
        <div className="pb-5 pl-5">
          <span onClick={() => handleOpenDetail(id)} className="flex mt-5 items-center text-sm font-semibold primary rounded cursor-pointer">
            {t('degree.courseItem.viewDetails')}
            <HiChevronRight className="w-5 h-5" />
          </span>
        </div>
      )}
    </div>
  );
};
export default DegreeItem;
