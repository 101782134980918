import { useParams } from 'react-router-dom';
import AddDegree from './addDegree';
import { useQuery } from 'react-query';
import { getDegreeDetail } from 'api/degreesApi';
import _ from 'lodash';
const EditDegree = () => {
  const params = useParams();

  const { data, isLoading, isFetching } = useQuery(['getDegreeDetail', params.degreeId], () => getDegreeDetail({ id: params.degreeId }), {
    staleTime: Infinity,
  });

  return !_.isEmpty(data?.data) ? <AddDegree isEdit degree={data?.data} isLoading={isLoading} isFetching={isFetching} /> : null;
};
export default EditDegree;
