import { Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { createOrganization, updateOrganizationById } from 'api/organizationApi';
import CustomModalHeader from './customModalHeader';
import { Formik, Form } from 'formik';
import GroupButton from '../button/groupButton';
import FormComponent from '../form/form';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { initOrganization } from 'utils/initData';
import { messageErrors } from '../../utils/utils';

type organizationModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  targetData: any,
  headerTitle?: string,
  successFunc: Function,
  isEdit: boolean,
};
const OrganizationModal = (props: organizationModalProps) => {
  const { openModal, setOpenModal, targetData, headerTitle, successFunc, isEdit } = props;
  const [t] = useTranslation();

  const handleAddAndUpdate = async (name: any) => {
    if (isEdit) await updateOrganizationById({ id: targetData.id, name });
    else await createOrganization({ name });
  };

  const mutation = useMutation('organization', {
    mutationFn: handleAddAndUpdate
  });

  const handleSubmit = (values: any, props: any) => {
    mutation.mutate(values.name, {
      onSuccess: () => {
        toggleHandler(props)
        successFunc();
        const message: string = isEdit ? t('organizationPage.editSuccess') : t('organizationPage.saveSuccess');
        toast.success(message);
      },
      onError: (error) => {
        const message: string = messageErrors(error, t);
        toast.error(message);
      }
    });
  };

  const onValidate = (values: any) => {
    const errors: any = {};
    if (isEmpty(values.name)) errors.name = t('requiredField');
    return errors;
  };

  const toggleHandler = (props: any) => {
    setOpenModal(!openModal)
    props.resetForm();
  }

  return (
    <Modal show={openModal} size="md" popup={true}>
      <Formik
          enableReinitialize
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          initialValues={targetData || initOrganization}
          validate={onValidate}
        >
        {(formikProps) => {
          return (
            <Form>
              <CustomModalHeader title={headerTitle} toggle={() => toggleHandler(formikProps)} />
              <Modal.Body>
                <FormComponent label={t('organizationModal.name')} id="name" typeq="text" placeholder="Jese Leos" name="name" />
                <GroupButton
                  className="items-center justify-center pt-1 pb-2"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => toggleHandler(formikProps),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                      isLoading: mutation.isLoading,
                    },
                  ]}
                />
              </Modal.Body>
            </Form>
          )
        }
        }
        </Formik>
    </Modal>
  );
};
export default OrganizationModal;
