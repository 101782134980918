import HeaderAdmin from '../adminHeader';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routePaths, pacingOptions } from 'utils/constants';
import { Label, Textarea, Button } from 'flowbite-react';
import Select from 'react-select';
import './addDegree.scss';
import FileUpload from './fileUpload';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getInstitutions } from 'api/institutionApi';
import { getDegreeTypes } from 'api/degreeTypesApi';
import { getCourses } from 'api/coursesApi';
import { institutionAction, degreeTypesAction, coursesAction } from 'redux/actions';
import { institutionSelector, degreeTypesSelector, coursesSelector, userSelector } from 'redux/selectors';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import CustomSelect from 'components/commonComponent/customSelect';
import { Form, Formik, useFormik } from 'formik';
import { optionType } from 'utils/proptypes';
import { postDegree, updateDegree, uploadImage } from 'api/degreesApi';
import CourseItem from './courseItem';
import InputOption from './inputOption';
import _ from 'lodash';
import { showToast } from 'utils/utils';
// import { degreeDetailAction } from 'redux/actions';


const initialDegree = {
  title: '',
  shortDescription: '',
  description: '',
  institutionId: '',
  degreeTypeId: '',
  pacing: '',
  imageId: null,
  courseIds: [],
};

const AddDegree = (props: any) => {
  const { isEdit, degree, isLoading, isFetching } = props
  const [initialValues, setInitialValues] = useState(initialDegree)
  const { institutions } = useSelector(institutionSelector);
  const { degreeTypes } = useSelector(degreeTypesSelector);
  const { courses } = useSelector(coursesSelector);
  const { userInfo } = useSelector(userSelector);

  const navigate = useNavigate();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();


  const [file, setFile] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [imgSizeError, setImgSizeError] = useState(false);
  const [institutionOptions, setInstitutionOptions] = useState([]);
  const [degreeTypesOptions, setDegreeTypesOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [selectedCoursesOption, setSelectedCoursesOption] = useState<any[]>([]);
  const [selectedInstitutionOption, setSelectedInstitutionOption] = useState<any>(null)
  const [selectedDegreeTypeOption, setSelectedDegreeTypeOption] = useState<any>(null)
  const [selectedPacingOption, setSelectedPacingOption] = useState<any>(null)
  const [selectedCoures, setSelectedCourses] = useState<any[]>([]);
  const [placeholderMultiSelect, setPlaceholderMultiSelect] = useState('Select')

  const { mutate: addDegreeMutation } = useMutation('addDegree', {
    mutationFn: postDegree,
    onSuccess: () => {
      showToast('success', t('degree.createDegreeSuccessful'))
      queryClient.invalidateQueries('getDegrees')
      navigate(routePaths.AMDIN_DEGREES);
    },
    onError: () => {
      showToast('error', t('degree.createDegreeError'))
    }
  });

  const { mutate: updateegreeMutation } = useMutation('updateDegree', {
    mutationFn: updateDegree,
    onSuccess: () => {
      showToast('success', t('degree.updateDegreeSuccessful'))
      queryClient.invalidateQueries('getDegreeDetail')
      queryClient.invalidateQueries('getDegrees')
    },
    onError: () => {
      showToast('error', t('degree.createDegreeError'))
    }
  });

  useQuery('getInstitutions', getInstitutions,
    {
      staleTime: Infinity,
      onSuccess: ({ data }) => {
        dispatch(institutionAction.setInstitutions(data.entities));
      },
    }
  )

  useQuery('getDegreeTypes', getDegreeTypes,
    {
      staleTime: Infinity,
      onSuccess: ({ data }) => {
        dispatch(degreeTypesAction.setDegreeTypes(data.entities));
      },
    }
  )

  useQuery('getCourses', () => getCourses({ page: 0, limit: 0 }),
    {
      staleTime: Infinity,
      onSuccess: ({ data }) => {
        dispatch(coursesAction.setCourses(data));
      },
    }
  )

  const handleBack = () => {
    navigate(routePaths.AMDIN_DEGREES)
  };

  const handleUploadImage = async (e: any) => {
    const file = e.target.files[0];
    if (file.size > 8000000) {
      setImgSizeError(true);
      return;
    }
    setImgUrl(URL.createObjectURL(file));
    file.isUploaded = true;
    setFile(file);
    setImgSizeError(false);
  };

  const handleRemoveImage = () => {
    setFile('');
    setImgUrl('');
  };

  const addDegreeSchema = Yup.object().shape({
    title: Yup.string().max(60, t('degree.validate.maximum60Charaters')).required(t('degree.validate.requiredField')),
    shortDescription: Yup.string().max(500, t('degree.validate.maximum500Charaters')),
    description: Yup.string().max(2000, t('degree.validate.maximum2000Charaters')).required(t('degree.validate.requiredField')),
    institutionId: Yup.string().required(t('degree.validate.requiredField')),
    degreeTypeId: Yup.string().required(t('degree.validate.requiredField')),
    pacing: Yup.string()
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addDegreeSchema,
    onSubmit: () => {
      const values = _.omitBy(formik.values, v => v === '');
      if (isEdit) {
        const payload = {
          payload: values,
          id: degree.id
        }
        updateegreeMutation(payload)
      } else
        addDegreeMutation(values);
    },
    enableReinitialize: true,

  });

  const handleSubmit = async () => {
    let imageId;
    if (file !== '' && _.isEmpty(formik.errors)
    ) {
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await uploadImage({ file: formData });
      imageId = data.id;
    }
    await formik.setFieldValue('imageId', imageId);
    setTimeout(() => formik.handleSubmit());
  };

  const handleChange = (options: any, props: any) => {
    switch (props.name) {
      case 'institutionId':
        setSelectedInstitutionOption(options)
        formik.setFieldValue('institutionId', options?.value);
        break;
      case 'degreeTypeId':
        setSelectedDegreeTypeOption(options)
        formik.setFieldValue('degreeTypeId', options?.value);
        break;
      case 'pacing':
        setSelectedPacingOption(options)
        formik.setFieldValue('pacing', options?.value);
        break;
    }
  };

  const handleSelectCourse = (options: any) => {

    const noDefaultOptions = options.filter((option: optionType) => option.value !== '' && option.label !== '');

    setSelectedCoursesOption(noDefaultOptions);

    const courseIds = noDefaultOptions.map((option: optionType) => option.value);

    formik.setFieldValue('courseIds', courseIds);
  };

  const handleRemoveCourse = (courseId: string) => {
    const newSelectedCourses = selectedCoures.filter(item => item.id !== courseId)
    const newSelectedCoursesOption = newSelectedCourses.map((item: any) => {
      return {
        label: item.title,
        value: item.id
      }
    })
    setSelectedCoursesOption(newSelectedCoursesOption);

    const courseIds = newSelectedCoursesOption.map((option: optionType) => option.value);
    formik.setFieldValue('courseIds', courseIds);
  }

  useEffect(() => {
    if (isEdit) {
      const courseIds = degree.courses?.map((course: any) => course.id);
      const selectedCourse = courseOptions?.filter((course: any) => courseIds?.find((courseId: string) => course.value === courseId)) || [];
      setSelectedCoursesOption(selectedCourse)
      setSelectedCourses(degree?.courses);


      const selectedInstitution = institutionOptions.filter((item: any) => item.value === degree.institution?.id)
      setSelectedInstitutionOption(selectedInstitution)

      const selectedDegreeType = degreeTypesOptions.filter((item: any) => item.value === degree.degreeType?.id)
      setSelectedDegreeTypeOption(selectedDegreeType)

      const selectedPacing = pacingOptions.filter((item: any) => item.value === degree.pacing)
      setSelectedPacingOption(selectedPacing)

      setPlaceholderMultiSelect(`${degree.courses?.length} ${t('degree.selectedItem')}`)

      setInitialValues({
        title: degree.title,
        shortDescription: degree.shortDescription,
        description: degree.description,
        institutionId: degree.institution?.id,
        degreeTypeId: degree.degreeType?.id,
        pacing: degree.pacing,
        imageId: degree.image?.id,
        courseIds: courseIds,
      });
      const imgUrlTemp = degree.image ? degree.image?.url : ''
      setImgUrl(imgUrlTemp)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [degree, institutionOptions, degreeTypesOptions, pacingOptions, courseOptions]);

  useEffect(() => {
    const newSelectedCoursesOption = [...selectedCoursesOption]
    const selectedCoursesTemp = newSelectedCoursesOption.map((item: any) => {
      return courses.find((itm: any) => itm.id === item.value)
    })

    setSelectedCourses(selectedCoursesTemp)
    if (selectedCoursesOption.length > 0) {
      setPlaceholderMultiSelect(selectedCoursesOption.length > 0 ? `${selectedCoursesOption.length} ${t('degree.selectedItem')}` : `${t('Select')}`)

    } else setPlaceholderMultiSelect('Select')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoursesOption]
  )

  useEffect(() => {
    const institutionsTemp = institutions.map((item: any) => ({
      label: item.name,
      value: item.id,
    }));
    setInstitutionOptions(institutionsTemp);
  }, [institutions]);

  useEffect(() => {
    const degreeTypesTemp = degreeTypes.map((item: any) => ({
      label: item.name,
      value: item.id,
    }));
    setDegreeTypesOptions(degreeTypesTemp);
  }, [degreeTypes]);

  useEffect(() => {
    const coursesTemp = courses?.map((item: any) => ({
      label: item.title,
      value: item.id,
    }));
    setCourseOptions(coursesTemp);
  }, [courses]);

  const Header = useMemo(() => {
    return (
      <div className="w-full mb-4 sm:mb-0 text-gray-600 flex flex-1 flex-row items-center">
        <div className="flex cursor-pointer" onClick={handleBack}>
          <HiArrowNarrowLeft className="h-6 w-6 mr-2" />
          <span>{t('button.back')}</span>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function classNameforInput(error: any, touched: any) {
    if (error && touched) {
      return `p-5 mb-1 bg-red-50 border border-red-500 text-red-900 placeholder-red-700 
      text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block
      w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 resize-none`
    }
    return `p-5 mb-1 bg-gray-50 border border-gray-100 text-gray-900 text-sm rounded-lg 
      focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 
      dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 
      dark:focus:border-blue-500 resize-none`;
  }

  return (
    <>

      <HeaderAdmin>{Header}</HeaderAdmin>
      {(!isLoading || !isFetching) && (
        <div className=" grow bg-white">
          <div className="p-5 add-degree-title">
            <p className="text-2xl font-medium">{isEdit ? t('degree.editDegree') : t('degree.addDegree')}</p>
          </div>
          <div className="p-5 w-full">
            <Formik
              enableReinitialize={true}
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={addDegreeSchema}
              className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
            >
              {props => (
                <Form>
                  <div className="xl:flex lg:flex md:block sm:block">
                    <div className=" xl:w-2/3 lg:w-2/3 md:w-full sm:w-full">
                      <div className="mb-1">
                        <div className="mb-2 block">
                          <Label htmlFor="Title Name" value={t('degree.titleName')} />
                        </div>

                        <Textarea
                          className={classNameforInput(formik.errors?.title, formik?.touched.title)}
                          id="title"
                          name="title"
                          placeholder="Max 60 characters"
                          onChange={formik.handleChange}
                          rows={1}
                          value={formik.values.title}

                        />
                        <div className="h-6">
                          {formik.errors?.title && formik?.touched.title && (
                            <p className="text-sm text-red-600 dark:text-red-500">{formik.errors?.title}</p>
                          )}
                        </div>
                      </div>
                      <div id="textarea" className="mb-1">
                        <div className="mb-2 block">
                          <Label htmlFor="Short Description" value={t('degree.shortDescription')} />
                        </div>
                        <Textarea
                          className={classNameforInput(formik.errors?.shortDescription, formik?.touched.shortDescription)}
                          id="shortDescription"
                          name="shortDescription"
                          rows={4}
                          placeholder="Max 500 characters"
                          onChange={formik.handleChange}
                          value={formik.values.shortDescription}

                        />
                        <div className="h-6">
                          {formik.errors?.shortDescription && formik?.touched?.shortDescription && (
                            <p className="text-sm text-red-600 dark:text-red-500">{formik.errors?.shortDescription}</p>
                          )}
                        </div>
                      </div>
                      <div id="textarea" className="mb-1">
                        <div className="mb-2 block">
                          <Label htmlFor="Description" value={t('degree.description')} />
                        </div>
                        <Textarea
                          className={classNameforInput(formik.errors?.description, formik?.touched.description)}
                          id="description"
                          name="description"
                          rows={6}
                          placeholder="Max 2000 characters"
                          onChange={formik.handleChange}
                          value={formik.values.description}

                        />
                        <div className="h-6">
                          <div className="h-6">
                            {formik.errors?.description && formik?.touched?.description && (
                              <p className="text-sm text-red-600 dark:text-red-500">{formik.errors?.description}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="xl:flex lg:flex md:block sm:block mb-5">
                        <div className="xl:flex lg:flex md:flex sm:block lg:w-2/3 md:w-full sm:w-full">
                          <div className="xl:w-1/2 lg:w-1/2 md:w-full sm:w-full xl:pr-3 lg:pr-3 md:pr-3 sm:pr-0">
                            <div className="mb-2 block">
                              <Label htmlFor="Institution" value={t('degree.institution')} />
                            </div>
                            <Select
                              id="institutionId"
                              name="institutionId"
                              className={formik.errors?.institutionId && formik?.touched?.institutionId ? 'errors' : ''}
                              isSearchable={false}
                              isClearable={false}
                              options={institutionOptions}
                              onChange={handleChange}
                              value={selectedInstitutionOption}
                            />
                            <div className="h-6">
                              {formik.errors?.institutionId && formik?.touched?.institutionId && (
                                <p className="text-sm text-red-600 dark:text-red-500">{formik.errors?.institutionId}</p>
                              )}
                            </div>
                          </div>
                          <div className=" xl:w-1/2 lg:w-1/2 md:w-full sm:w-full xl:pr-3 lg:pr-3 md:pr-0 sm:pr-0">
                            <div className="mb-2 block">
                              <Label htmlFor="Degree Type" value={t('degree.degreeType')} />
                            </div>
                            <Select
                              id="degreeTypeId"
                              name="degreeTypeId"
                              className={formik.errors?.degreeTypeId && formik?.touched?.degreeTypeId ? 'errors' : ''}
                              isSearchable={false}
                              isClearable={false}
                              options={degreeTypesOptions}
                              value={selectedDegreeTypeOption}
                              onChange={handleChange}
                            />
                            <div className="h-6">
                              {formik.errors?.degreeTypeId && formik?.touched?.degreeTypeId && (
                                <p className="text-sm text-red-600 dark:text-red-500">{formik.errors?.degreeTypeId}</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="xl:w-1/3 lg:w-1/3 md:w-full sm:w-full">
                          <div className="xl:w-full lg:w-full md:w-full sm:w-full ">
                            <div className="mb-2 block">
                              <Label htmlFor="Pacing" value={t('degree.pacing')} />
                            </div>
                            <Select
                              id="pacing"
                              name="pacing"
                              className={formik.errors?.pacing && formik?.touched?.pacing ? 'errors' : ''}
                              isSearchable={false}
                              isClearable={false}
                              options={pacingOptions}
                              value={selectedPacingOption}
                              onChange={handleChange}
                            />
                            <div className="h-6">
                              {formik.errors?.pacing && formik?.touched?.pacing && (
                                <p className="text-sm text-red-600 dark:text-red-500">{formik.errors?.pacing}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="border-gray-300 mb-5" />
                      <div>
                        <p className="text-lg font-medium mb-5">{t('degree.selectCourses')}</p>
                        <CustomSelect
                          formProps={props}
                          onChange={handleSelectCourse}
                          options={courseOptions}
                          isMulti={true}
                          value={selectedCoursesOption}
                          id="degreeIds"
                          selectName="degreeIds"
                          isDisabled={false}
                          labelName={t('degree.courses')}
                          inputOption={InputOption}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          controlShouldRenderValue={false}
                          placeholder={placeholderMultiSelect}
                        />
                      </div>

                      <div className='py-5'>

                        <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3  gap-8 sm:grid-cols-2">
                          {selectedCoures.map(item => {
                            return (<CourseItem key={item.id} course={item} handleRemoveCourse={handleRemoveCourse} />)
                          }
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="xl:w-1/3 lg:w-1/3 md:w-full sm:w-full xl:ml-5 lg:ml-5 ">
                      <div className="shadow-md bg-white rounded-lg	h-80 mb-5 border border-gray-200">
                        <p className="text-lg p-5">{t('degree.uploadImage')}</p>
                        <FileUpload handleUploadImage={handleUploadImage} imgUrl={imgUrl} handleRemoveImage={handleRemoveImage} isEdit={isEdit} />
                        {imgSizeError && (
                          <div className="text-red-600 text-xs font-normal mt-1 veri-modal height-16">{t('userProfilePage.imageSizeError')}</div>
                        )}
                      </div>
                      <div className="shadow-md bg-white rounded-lg p-5 border border-gray-200">
                        <p className="text-lg mb-2">{t('degree.publish')}</p>
                        <div className="flex justify-between">
                          <p className="font-medium"> {isEdit ? t('degree.lastPublished') : t('degree.publishAt')}</p>
                          <p>{isEdit ? moment(degree.modified).format(' MMMM DD, YYYY') : moment().format(' MMMM DD, YYYY')}</p>
                        </div>
                        <div className="flex justify-between">
                          <p className="font-medium"> {isEdit ? t('degree.lastPublishedBy') : t('degree.publishBy')}</p>
                          <p className="text-blue-400">{isEdit ? `${degree?.createdBy?.firstName} ${degree?.createdBy?.lastName}` : `${userInfo.firstName} ${userInfo.lastName}`}</p>
                        </div>
                        <hr className="border-gray-300 mt-5" />
                        <div className="mt-5 flex justify-end">
                          <Button pill={true} color="success" onClick={handleSubmit} className="add-action custom-button-style">
                            {isEdit ? t('button.update') : t('button.publish')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}

    </>
  );
};
export default AddDegree;
