import LanguageSelect from 'components/commonComponent/languageSelect';
import { useTranslation } from 'react-i18next';
import './footer.scss';

const logo = require('../../assets/image/svg/YAM-logo.svg').default;

const FooterComponent = () => {
  const [t] = useTranslation();

  return (
    <footer className="w-full bg-gray-900 shadow-md footer">
      <div className="max-w-8xl mx-auto  py-4 sm:px-8 px-4 flex md:flex-row flex-col items-center justify-between text-white">
        <div className="flex items-center md:flex-row flex-col ">
          <img src={logo} alt="logo" className="" />
          <span className="text-xs pl-4 md:mt-0 mt-2">{t('footer.copyright')} © 2023 Yam Education, Inc. {t('footer.allRightsReserved')}</span>
        </div>
        <ul className="flex items-center text-xs gap-4 md:mt-0 mt-2">
          <li>
            <a href="https://yam-edu.com/terms">{t('footer.termsOfUse')}</a>
          </li>
          <li>
            <a href="https://yam-edu.com/privacy">{t('footer.privacyNotice')}</a>
          </li>
          <li>
            <LanguageSelect id="footer" />
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default FooterComponent;
