import { Modal } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import CustomModalHeader from './customModalHeader';
import FormComponent from '../form/form';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import GroupButton from 'components/button/groupButton';
import { createCategory, updateCategoryById } from 'api/courseCatetoriesApi';

type userOrganizationModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  targetData: any,
  headerTitle: string,
  organizationId?: string,
  successFunc: Function,
  isEdit:boolean
};
const CategoryModal = (props: userOrganizationModalProps) => {
  const {
    openModal,
    setOpenModal,
    targetData,
    successFunc,
    headerTitle,
    isEdit
  } = props;

  const [t] = useTranslation();

  const ValidateSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, t('userManagementPage.nameLengthMin'))
      .max(100, t('userManagementPage.nameLengthMax'))
      .nullable()
      .required(t('userManagementPage.requiredField')),
  });

  type categoryType = {
    name: string,
  };

  const initCategory: categoryType = {
    name: '',
  };

  const [category, setCategory] = useState(initCategory);

  const handleAddAndUpdateUser = async (userPayload: any) => {
    if (isEdit) {
      const id = targetData.id
      await updateCategoryById(id, userPayload);
    } else {
      
      await createCategory(userPayload);
    }
    
  };

  const mutation = useMutation('create-update-courseCtg', { mutationFn: handleAddAndUpdateUser });

  const handleSubmit = (value: any, props: any) => {
    const payload = {
      ...value,
      name: isEmpty(value.name) ? null : value.name,
    };
    
    mutation.mutate(
      { ...payload, emailVerified: true },
      {
        onSuccess: () => {
          setOpenModal(!openModal);
          const message: string = isEdit ? t('categoryPage.editSuccessMessage') : t('categoryPage.createSuccessMessage');
          toast.success(message);
          props.resetForm();
          successFunc();
        },
        onError: async (error: any) => {
          const message: string = `${error.response.data.errors?.[0].detail}`;
          toast.error(message);
        },
      },
    );
  };

  const cancelHandler = (props: any) => {
    setOpenModal(!openModal);
    props.resetForm();
  };

  useEffect(() => {
    if (isEdit) {
      setCategory({
        name:targetData.name,
      });
    }else{
      setCategory({
        ...initCategory,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetData, openModal]);



  return (
    <Modal className="admin-modal" show={openModal} size="2xl" popup={true}>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={category}
        validationSchema={ValidateSchema}
        className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
      >
        {props => (
          <Form>
            <CustomModalHeader title={headerTitle} toggle={() => cancelHandler(props)} />
            <Modal.Body>
              <div className="flex flex-col px-4 gap-4">
                <FormComponent
                  label={`${t('admin.courses.category')} *`}
                  id="category"
                  type="text"
                  placeholder="catégorie"
                  name="name"
                />

                <GroupButton
                  className="items-center justify-center pt-1 pb-2"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => cancelHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                      isLoading: mutation.isLoading,
                    },
                  ]}
                />
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default CategoryModal;
