import SidebarComponent from './sidebar';
import './mainLayout.scss';
const MainLayout = (props: any) => {
  const { children } = props;

  return (
    <div className="admin-main-layout">
      <div className="w-full flex flex-row justify-center ">
        <SidebarComponent isDashboard={true} />
        <div className="w-full h-screen overflow-scroll flex flex-col bg-gray-50">{children}</div>
        <div className="h-screen block" />
      </div>
    </div>
  );
};
export default MainLayout;
