import Select from 'react-select';
import { optionType } from 'utils/proptypes';
import { useFormikContext } from 'formik';
import _, { isEmpty } from 'lodash';
import { Label } from 'flowbite-react';
import { useState, useEffect } from 'react';

type CustomSelectProps = {
  value: optionType[],
  onChange: Function,
  isMulti: boolean,
  id: string,
  options?: optionType[],
  selectName?: string,
  isDisabled?: boolean,
  labelName: string,
  formProps: any,
  inputOption?: any,
  closeMenuOnSelect?: boolean
  hideSelectedOptions?: boolean
  controlShouldRenderValue?: boolean,
  placeholder?: string
};

const CustomSelect = (props: CustomSelectProps) => {
  const { onChange, options, selectName, labelName, value, isMulti, isDisabled, id, formProps, inputOption, closeMenuOnSelect, hideSelectedOptions, controlShouldRenderValue, placeholder } = props;
  const [focus, setFocus] = useState(false);

  const initOption: optionType = { value: '', label: '' };
  const formikContext = useFormikContext();
  const error = _.get(formikContext, `errors[${selectName}]`, '');
  const touched = _.get(formikContext, `touched[${selectName}]`, '');

  useEffect(() => {
    setFocus(!!touched);
  }, [error, touched]);

  const handleFocus = () => {
    setFocus(true);
  };


  return (
    <div className="custom-multi-select">
      <div className="mb-1.5">
        <Label value={labelName} />
      </div>
      <Select
        isDisabled={isDisabled}
        id={id}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        controlShouldRenderValue={controlShouldRenderValue}
        className={`${error && focus ? "errors" : ''} block w-full disabled:cursor-not-allowed border-gray-100 disabled:opacity-50 bg-gray-50 text-gray-900 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 rounded-lg p-2.5 text-sm`}
        name={selectName}
        onFocus={handleFocus}
        value={isEmpty(value) || (Array.isArray(value) && value[0]?.value === '') ? [] : value}
        options={options || [initOption]}
        defaultValue={initOption}
        onChange={option => onChange(option, formProps)}
        components={inputOption && {
          Option: inputOption
        }}
        placeholder={placeholder}
      />
      {error && touched && <div className="text-red-600 text-xs font-normal mt-1 veri-modal height-16">{error}</div>}
    </div>
  );
};
export default CustomSelect;
