/**
* Last Modified: June 14, 2023
* Last Modified By: Sheriff Issaka
* Modification Description: Add file header
* Description: Select currency renew plan modal
**/

import { Button, Dropdown, Modal, Spinner } from 'flowbite-react';
import CustomModalHeader from './customModalHeader';
import { CURRENCY_OPTIONS } from 'utils/constants';
import { getExchangeRates } from 'api/subscriptionApi';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './selectCurrencyRenewPlanModal.scss';
import PaymentFlutterWaveButton from 'components/payment/flutterwave/paymentFlutterwaveButton';
import { ratesSelector } from 'redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { ratesAction } from 'redux/actions';
import { formatPrice } from 'utils/utils';

const SelectCurrencyRenewPlanModal = (props: any) => {
  const { openModal, setOpenModal, currentPlan, handleRenewSuccessful } = props;
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const [priceLast, setPriceLast] = useState(currentPlan.priceUSD / 100 || 0);
  const [symbol, setSymbol] = useState('$');
  const [currency, setCurrency] = useState('USD');
  const [chooseSub, setChooseSub] = useState(false);
  const [isLoadingCurrency, setIsLoadingCurrency] = useState(false);
  const [isExchangeratesError, setExchangeratesError] = useState(false);
  const { rates } = useSelector(ratesSelector);
  const languageCode = i18n.language;
  const toggleHandler = () => {
    setOpenModal(!openModal);
  };

  const { isLoading } = useQuery(['getExchangeRates'], () => getExchangeRates(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: data => {
      setExchangeratesError(false);
      dispatch(ratesAction.setRates(data.data.conversion_rates));
    },
    onError: () => {
      setExchangeratesError(true);
    },
  });

  const handleChangeCurrency = async (selectedCurrency: any) => {
    setIsLoadingCurrency(true);
    if (isLoading) {
      setIsLoadingCurrency(false);
      return;
    }

    const price = (currentPlan.priceUSD / 100) * rates[`${selectedCurrency?.value}`];
    setSymbol(selectedCurrency.symbol);
    setPriceLast(price);
    setCurrency(selectedCurrency?.value);
    setIsLoadingCurrency(false);
  };

  const txRef = useMemo(() => {
    return `my-yam-subscription-${new Date().getTime()}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chooseSub]);

  const renderPriceLabel = () => {
    return (
      <div className="plan-currencies">
        <sup className="plan-currency">{symbol}</sup>
        <span className="plan-amount">{formatPrice(priceLast, currency, languageCode)}</span>
      </div>
    );
  };

  const renderPriceItem = (isError: boolean) => {
    if (isError) {
      return renderPriceLabel();
    }

    return (
      <Dropdown className="price-dropdown-renew" arrowIcon={true} inline={true} placement="bottom" label={renderPriceLabel()}>
        <div className="h-48 w-24s dropdown-item">
          {CURRENCY_OPTIONS.map(item => (
            <Dropdown.Item key={item.label} onClick={() => handleChangeCurrency(item)}>
              {item.label}
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown>
    );
  };

  return (
    <Modal show={openModal} size="lg" popup={true}>
      <CustomModalHeader title="" toggle={() => toggleHandler()} />
      <div className="renew-currency-modal">
        <div className="renew-currency-modal-title">
          <span className="renew-currency-modal-title-text">
            <Trans i18nKey="renewCurrencyWarning.title" values={{ name: currentPlan.name }} components={{ bold: <strong />, span: <span /> }} />
          </span>
        </div>
        <div className="renew-currency-modal-guide">
          {!isExchangeratesError ? t('renewCurrencyWarning.guideText') : t('renewCurrencyWarning.guideTextError')}
        </div>
        <div className="renew-currency-modal-select">
          {!isLoadingCurrency && !isLoading ? (
            renderPriceItem(isExchangeratesError)
          ) : (
            <div className="loading-currency">
              <Spinner size="md" />
            </div>
          )}
        </div>
        <div className="renew-currency-modal-buttons">
          <div>
            <div>
              <Button disabled={isLoading || isLoadingCurrency} pill={true} onClick={() => toggleHandler()} className="custom-button-style">
                {/*className="button-cancel"*/}
                {t('renewCurrencyWarning.buttonCancel')}
              </Button>
            </div>
            <div className="button-apply">
              <PaymentFlutterWaveButton
                planId={currentPlan?.id}
                txRef={txRef}
                amount={priceLast}
                currency={currency}
                label={t('renewCurrencyWarning.buttonApply')}
                callback={() => handleRenewSuccessful()}
                setChooseSub={setChooseSub}
                handleCreatedBill={() => setOpenModal(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectCurrencyRenewPlanModal;
