import { useState, useEffect, useMemo } from 'react';
import '../home/home.scss';
import ProgramItem from 'components/commonComponent/programItem';
import Select from 'react-select';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner, TextInput } from 'flowbite-react';
import { useMutation, useQuery } from 'react-query';
import { getCourses } from 'api/coursesApi';
import { getInstitutions } from 'api/institutionApi';
import { getCourseCategories } from 'api/courseCatetoriesApi';
import { coursesCount, pacingOptions, routePaths } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { coursesAction, institutionAction, courseCategoriesAction } from 'redux/actions';
import { coursesSelector, institutionSelector, courseCategoriesSelector } from 'redux/selectors';
import './courses.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import { courseType } from '../../utils/proptypes';
import { HiSearch } from 'react-icons/hi';
import debouce from 'lodash.debounce';
import NoResult from 'components/commonComponent/noResult';
import FooterComponent from '../../components/layouts/footer';

const Courses = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { totalEntities, courses } = useSelector(coursesSelector);
  const { institutions } = useSelector(institutionSelector);
  const { courseCategories } = useSelector(courseCategoriesSelector);

  const [searchValue, setSearchValue] = useState('');
  const [coursesData, setCoursesData] = useState([]);
  const [totalCourses, setTotalCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [institutionOptions, setInstitutionOptions] = useState([]);
  const [courseCategoriesOptions, setCourseCategoriesOptions] = useState([]);
  const [isRequest, setIsRequest] = useState(true);

  // Quan update
  const [selectInstitution, setSelectInstitution] = useState<any>(null);
  const [selectcourseCategory, setSelectcourseCategory] = useState<any>(null);
  const [selectPacing, setSelcetedPacing] = useState<any>(null);

  const { isLoading: isLoadingInstitutions, isFetched: isFetchedInstitutions } = useQuery('getInstitutions', getInstitutions, {
    staleTime: Infinity,
    onSuccess: ({ data }) => {
      dispatch(institutionAction.setInstitutions(data.entities));
    },
  });

  const { isLoading: isLoadingCourseCategories, isFetched: isFetchedCourseCategories } = useQuery('getCourseCategories', getCourseCategories, {
    staleTime: Infinity,
    onSuccess: ({ data }) => {
      dispatch(courseCategoriesAction.setCourseCategories(data.entities));
    },
  });

  const {
    mutate: getDegreesMutation,
    isLoading: isLoadingGetCourses,
    isSuccess: isSuccessGetCourses,
  } = useMutation('getCourses', {
    mutationFn: getCourses,
    retry: false,
    onSuccess: ({ data }) => {
      dispatch(coursesAction.setCourses(data));
    },
  });

  function makeupParams(courseMatchedItem: any, institutionMatchedItem: any, matchPacingOption: any, searchQuery: any) {
    let addingParams = {};

    // if selectedItem is not null
    // we need to search by params
    if (courseMatchedItem) {
      addingParams = {
        ...addingParams,
        courseCategoryId: courseMatchedItem.value,
      };
    }

    if (institutionMatchedItem) {
      addingParams = {
        ...addingParams,
        institutionId: institutionMatchedItem.value,
      };
    }

    if (matchPacingOption) {
      addingParams = {
        ...addingParams,
        pacing: matchPacingOption.value,
      };
    }

    if (searchQuery) {
      addingParams = {
        ...addingParams,
        searchQuery: searchQuery,
      };
    }

    return addingParams;
  }

  function makeupQuery(courseMatchedItem: any, institutionMatchedItem: any, matchPacingOption: any, searchQuery: any) {
    let addingParams = {};

    // if selectedItem is not null
    // we need to search by params
    if (courseMatchedItem) {
      addingParams = {
        ...addingParams,
        category: courseMatchedItem.label,
      };
    }

    if (institutionMatchedItem) {
      addingParams = {
        ...addingParams,
        institution: institutionMatchedItem.label,
      };
    }

    if (matchPacingOption) {
      addingParams = {
        ...addingParams,
        pacing: matchPacingOption.label,
      };
    }

    if (searchQuery) {
      addingParams = {
        ...addingParams,
        searchQuery: searchQuery,
      };
    }

    return addingParams;
  }

  const fetchMoreCourses = () => {
    setCurrentPage(currentPage + 1);

    const initParams = {
      page: currentPage + 1,
      limit: coursesCount,
    };

    getDegreesMutation({ ...initParams, ...makeupParams(selectcourseCategory, selectInstitution, selectPacing, searchValue) });
  };

  const handleOpenDetail = (courseId: string) => {
    navigate(`${routePaths.COURSES}/${courseId}`);
  };

  useEffect(() => {
    if (courses?.length >= 0) {
      let finalCourses: any = [];
      if (Array.isArray(courses)) {
        finalCourses = courses.filter(
          (item: courseType) =>
            !(
              (item.enrollmentStart && item.enrollmentStart >= moment().utc().format()) ||
              (item.enrollmentEnd && item.enrollmentEnd <= moment().utc().format())
            ),
        );
      }
      setCoursesData(state => {
        let result = [];

        if (currentPage === 0) {
          result = finalCourses;
        } else {
          result = [...state, ...finalCourses];
        }

        return result;
      });
      setTotalCourses(state => {
        let result = [];

        if (currentPage === 0) {
          result = courses;
        } else {
          result = [...state, ...courses];
        }

        return result;
      });
    }

    setIsRequest(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses]);

  useEffect(() => {
    setCoursesData([]);
  }, []);

  useEffect(() => {
    let institutionMatchedItem: any = null;
    const selectedItem: any = Object.fromEntries(searchParams);

    if (!isLoadingInstitutions && isFetchedInstitutions) {
      const institutionsTemp = institutions.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setInstitutionOptions(institutionsTemp);

      institutionMatchedItem = institutionsTemp.find((item: any) => {
        return selectedItem && selectedItem.institution && item.label.toLowerCase() === selectedItem.institution.toLowerCase();
      });

      setSelectInstitution(institutionMatchedItem);
    }

    let courseMatchedItem: any = null;
    let matchPacingOption: any = null;

    if (!isLoadingCourseCategories && isFetchedCourseCategories) {
      const courseCategoriesTemp = courseCategories.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setCourseCategoriesOptions(courseCategoriesTemp);

      courseMatchedItem = courseCategoriesTemp.find((item: any) => {
        return selectedItem && selectedItem.category && item.label.toLowerCase() === selectedItem.category.toLowerCase();
      });

      setSelectcourseCategory(courseMatchedItem);

      matchPacingOption = pacingOptions.find((option: any) => {
        return selectedItem && selectedItem.pacing && option.label.toLowerCase() === selectedItem.pacing.toLowerCase();
      });
      setSelcetedPacing(matchPacingOption);

      setSearchValue(selectedItem.searchQuery);
    }

    if (!isLoadingCourseCategories && isFetchedCourseCategories && !isLoadingInstitutions && isFetchedInstitutions) {
      // make up param
      // courseMatchedItem
      // institutionMatchedItem
      const initParams = {
        page: currentPage,
        limit: coursesCount,
      };

      // if selectedItem is not null
      // we need to search by params
      getDegreesMutation({
        ...initParams,
        ...(selectedItem && { ...makeupParams(courseMatchedItem, institutionMatchedItem, matchPacingOption, selectedItem.searchQuery) }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInstitutions, isFetchedInstitutions, isLoadingCourseCategories, isFetchedCourseCategories]);

  const handleChange = (e: any) => {
    const searchQuery: string = e.target.value;
    setCurrentPage(0);
    setIsRequest(true);
    setSearchValue(searchQuery);
    const queryItem = makeupQuery(selectcourseCategory, selectInstitution, selectPacing, searchQuery);
    setSearchParams({ ...queryItem });

    const initParams = {
      page: currentPage,
      limit: coursesCount,
    };
    getDegreesMutation({ ...initParams, ...makeupParams(selectcourseCategory, selectInstitution, selectPacing, searchQuery) });
  };

  const debouncedResults = useMemo(() => {
    return debouce(handleChange, 700);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectcourseCategory, selectInstitution, selectPacing]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handleChangeSelect = (itemType: any, targetValue: any) => {
    const initParams = {
      page: 0,
      limit: coursesCount,
    };

    let addingParams = {};
    let queryString = null;
    // no matter what happen
    // after change selected item
    // we call API to get the list item by param
    switch (itemType) {
      case 'institutionId':
        addingParams = makeupParams(selectcourseCategory, targetValue, selectPacing, searchValue);
        queryString = makeupQuery(selectcourseCategory, targetValue, selectPacing, searchValue);
        setCurrentPage(0);
        setSelectInstitution(targetValue);
        break;
      case 'courseCategoryId':
        addingParams = makeupParams(targetValue, selectInstitution, selectPacing, searchValue);
        queryString = makeupQuery(targetValue, selectInstitution, selectPacing, searchValue);
        setCurrentPage(0);
        setSelectcourseCategory(targetValue);
        break;
      case 'pacing':
        addingParams = makeupParams(selectcourseCategory, selectInstitution, targetValue, searchValue);
        queryString = makeupQuery(selectcourseCategory, selectInstitution, targetValue, searchValue);
        setCurrentPage(0);
        setSelcetedPacing(targetValue);
        break;
      default:
        break;
    }

    if (queryString) {
      setSearchParams({ ...queryString });
    }

    // when there is no return we will call api with new filter anyway
    // get paging
    // get other selected values
    getDegreesMutation({ ...initParams, ...addingParams });
  };

  return (
    <div className="courses">
      <div id="scrollableDiv">
        <InfiniteScroll
          // inverse
          dataLength={coursesData.length}
          next={fetchMoreCourses}
          hasMore={totalCourses.length < totalEntities}
          scrollableTarget="scrollableDiv"
          scrollThreshold={0.8}
          loader={<></>}
        >
          <div className="w-full mx-auto max-screen lg:px-0 px-6 h-full ">
            <div className="filter fixed">
              <div className="bg-filter">
                <div className=" py-5 courses__nav">
                  <div className=" courses-filter">
                    <div className="2xl:w-1/3 xl:w-1/3 md:w-1/3 xs:w-full mb-2 courses__nav-item">
                      <Select
                        isSearchable={false}
                        isClearable
                        options={institutionOptions}
                        placeholder={t('common.institution')}
                        onChange={(value: any) => handleChangeSelect('institutionId', value)}
                        value={selectInstitution}
                      />
                    </div>
                    <div className="2xl:w-1/3 xl:w-1/3 md:w-1/3 xs:w-full mb-2 courses__nav-item">
                      <Select
                        isSearchable={false}
                        isClearable
                        options={courseCategoriesOptions}
                        placeholder={t('common.category')}
                        onChange={(value: any) => handleChangeSelect('courseCategoryId', value)}
                        value={selectcourseCategory}
                      />
                    </div>
                    <div className="2xl:w-1/3 xl:w-1/3 md:w-1/3 xs:w-full mb-2 pacing courses__nav-item">
                      <Select
                        isSearchable={false}
                        isClearable
                        options={pacingOptions}
                        placeholder={t('common.pacing')}
                        onChange={(value: any) => handleChangeSelect('pacing', value)}
                        value={selectPacing}
                      />
                    </div>
                  </div>
                  <div className="2xl:w-2/5 xl:w-1/4 md:w-full xs:w-full courses-search ">
                    <TextInput
                      className="search-course-input"
                      rightIcon={HiSearch}
                      id="base"
                      type="text"
                      sizing="md"
                      defaultValue={searchValue}
                      placeholder={t('placeHolder.searchCourses')}
                      onChange={debouncedResults}
                    />
                  </div>
                </div>
              </div>
            </div>
            {isLoadingGetCourses && coursesData.length === 0 ? (
              <div className="loading-scroll">
                <Spinner aria-label="spinner example" />
              </div>
            ) : (
              <>
                {coursesData.length > 0 && !isRequest ? (
                  <div className="mh-scroll">
                    {searchValue !== '' && searchValue !== undefined && (
                      <div className="pb-4 pt-4 flex justify-center">
                        <div>
                          <Trans
                            i18nKey="searchResults"
                            values={{
                              count: coursesData?.length?.toLocaleString('en-US'),
                              searchValue: searchValue,
                              results: coursesData?.length >= 2 ? 'results' : 'result',
                            }}
                            components={{
                              italic: <i />,
                              bold: <strong />,
                              span: <span className="text-primary font-semibold" />,
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mb-10 px-1">
                      {coursesData.map((course: any) => {
                        return (
                          <div key={course.id} className="py-2">
                            <ProgramItem key={course.id} data={course} isDegree={false} handleOpenDetail={() => handleOpenDetail(course.id)} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  !isRequest &&
                  !isLoadingGetCourses &&
                  isSuccessGetCourses && (
                    <div className="mh-noresult">
                      <NoResult />
                    </div>
                  )
                )}
              </>
            )}
          </div>
          {totalCourses.length < totalEntities && coursesData.length !== 0 ? (
            <div className="loading-scroll">
              <Spinner aria-label="spinner example" />
            </div>
          ) : (
            !isLoadingGetCourses && isSuccessGetCourses && <FooterComponent></FooterComponent>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Courses;
