import { useEffect, useState } from 'react';
import { Modal } from 'flowbite-react';
import { useMutation } from 'react-query';
import CustomModalHeader from './customModalHeader';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
import { get } from 'lodash';
import CustomSelect from '../commonComponent/customSelect';
import GroupButton from '../button/groupButton';
import './courseEditModal.scss';
import FormComponent from '../form/form';
import { getSubscriptionsByUserId } from '../../api/userApi';
import { createSubscriptionAdmin } from '../../api/subscriptionApi';
import SpinnerComponent from '../spinner';

const SubscriptionModal = (props: any) => {
  const { openModal, setOpenModal, modalData, planOptions, successFunc } = props;
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [initData, setInitData] = useState({ plan: undefined, expiration: '' });
  const [t] = useTranslation();
  const fetchSubscription = async (userId: string) => {
    if (userId) {
      const { data } = await getSubscriptionsByUserId(userId);
      if (Array.isArray(data.entities) && data.entities.length) {
        const option: any = {
          value: data.entities[0]?.plan?.id,
          label: data.entities[0]?.plan?.name,
        };
        setSelectedOption(option);
        setInitData({
          plan: option,
          expiration: data.entities[0]?.expiredAt,
        });
      } else {
        setSelectedOption({});
        setInitData({
          plan: undefined,
          expiration: '',
        });
      }
    }
  };

  const handleSubmit = (value: any, props: any) => {
    if (!(moment(value.expiration).format('YYYY-MM-DD') === initData.expiration && get(initData, 'plan.value') === get(value, 'plan.value'))) {
      const payload = {
        userId: modalData?.id,
        planId: value.plan.value,
        expiredAt: moment(value.expiration).format('YYYY-MM-DD'),
      };
      mutation.mutate(payload, {
        onSuccess: () => {
          setOpenModal(!openModal);
          const message: string = t('subscription.updatedSuccess');
          toast.success(message);
          props.resetForm();
          setSelectedOption({});
          successFunc();
        },
        onError: async () => {
          const message: string = t('somethingsWentWrong');
          toast.error(message);
        },
      });
    }
  };

  const cancelHandler = (props: any) => {
    setOpenModal(false);
    props.resetForm();
  };
  const handleChange = (item: any, props: any) => {
    let expiration = initData.expiration;
    if (!initData.expiration || (initData.expiration && initData.expiration < moment.utc().format())) {
      let duration = item.duration;
      if (!duration) {
        const interval = item.interval;
        if (interval.toLowerCase() === 'monthly') {
          duration = 30;
        }
      }
      if (duration) {
        expiration = moment().utc().day(duration).format('YYYY-MM-DD');
      } else {
        expiration = moment().utc().format('YYYY-MM-DD');
      }
    }

    setSelectedOption(item);
    props.setFieldValue('plan', item);
    props.setFieldValue('expiration', expiration);
  };

  const handleUpdate = async (payload: any) => {
    await createSubscriptionAdmin(payload);
  };

  const mutation = useMutation('update-subscription', { mutationFn: handleUpdate });

  const ValidateSchema = Yup.object().shape({
    plan: Yup.object().required(t('requiredField')),
    expiration: Yup.string().required(t('requiredField')),
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchSubscription(modalData?.id);
      setLoading(false);
    })();
  }, [modalData, openModal]);

  if (!modalData || !openModal) {
    return null;
  }
  if (loading) {
    return <SpinnerComponent />;
  }

  return (
    <Modal className="admin-modal subscription-modal" show={openModal} size="2xl" popup={true}>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initData}
        validationSchema={ValidateSchema}
        className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
      >
        {props => (
          <Form>
            <CustomModalHeader title="Edit Subscription" toggle={() => cancelHandler(props)} />
            <Modal.Body className="subscription-modal-body">
              <div className="text-center font-light">Set the subscription plan and expiration for {modalData.emailAddress}</div>
              <div className="flex flex-col px-4 gap-4 mt-50">
                <CustomSelect
                  formProps={props}
                  onChange={handleChange}
                  options={planOptions}
                  isMulti={false}
                  value={selectedOption}
                  id="plan"
                  selectName="plan"
                  labelName={`${t('userManagementPage.plan')} *`}
                />
                <FormComponent label={`${t('userManagementPage.expiration')} *`} id="expiration" type="date" name="expiration" />
                <GroupButton
                  className="actions items-center justify-center pt-1 pb-2"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => cancelHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                      isLoading: mutation.isLoading,
                    },
                  ]}
                />
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default SubscriptionModal;
