import Summary from 'components/commonComponent/summary';
import CardDegreeEnroll from './CardDegreeEnroll';
import { Tabs } from 'flowbite-react';
import CourseItem from './courseItem';
import { useDispatch, useSelector } from 'react-redux';
import { degreeDetailSelector, userSelector } from 'redux/selectors';
import { degreeDetailAction } from 'redux/actions';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { getDegreeDetail } from 'api/degreesApi';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import './degreeDetail.scss';
import { Helmet } from 'react-helmet';
import { compareDate } from '../../utils/utils';

const OverviewTab = (props: any) => {
  const { degree } = props;

  return (
    <>
      <div className="border border-gray-300 p-4">
        <div
          dangerouslySetInnerHTML={{
            __html: degree?.description,
          }}
        />
      </div>
    </>
  );
};

const CurriculumTab = (props: any) => {
  const [t] = useTranslation();
  const { courses } = props;
  return (
    <>
      <h2 className="text-2xl font-semibold tracking-tight text-gray-900 dark:text-white mb-5">{t('degree.coursesInThisProgram')}</h2>
      {courses?.map((course: any) => {
        return <CourseItem key={course.id} course={course} />;
      })}
    </>
  );
};

const DegreeDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [t] = useTranslation();

  const { degree } = useSelector(degreeDetailSelector);
  const { userInfo } = useSelector(userSelector);

  const [isEnrollLoading, setEnrollLoading] = useState(true);

  const getDegreeDetailMutation = useMutation('getDegreeDetail', {
    mutationFn: getDegreeDetail,
    onSuccess: ({ data }) => {
      dispatch(degreeDetailAction.setDegreeDetail(data));
      setEnrollLoading(false);
    },
    onError: () => {
      setEnrollLoading(false);
    },
  });

  const handleViewCourseOpenEdx = async () => {
    const openedxUrl = process.env.REACT_APP_OPENEDX_URL;
    const courseUrl = openedxUrl + '/dashboard/';
    window.location.href = courseUrl;
  };

  useEffect(() => {
    getDegreeDetailMutation.mutate({ id: params.degreeId });
    // eslint-disable-next-line
  }, []);

  const isEnroll = get(userInfo, 'degrees', []).find((x: any) => x.id === degree.id);
  //TODO: check isSubscribed base on subscription

  const isSubscribed = get(userInfo, 'subscriptions', []).length > 0;
  const expiredDate = get(userInfo, 'subscriptions[0].expiredAt', null);
  let isExpired = false;
  if (compareDate(expiredDate, new Date())) {
    isExpired = true;
  }

  const titleCard = () => {
    if (!isSubscribed) {
      return t('degree.titleCard.beginEnrollment');
    } else {
      if (!isExpired && isEnroll) {
        return t('degree.titleCard.youHaveJoined');
      } else {
        return t('degree.titleCard.scheduleEnrollmentMeeting');
      }
    }
  };

  const description = !isExpired && isEnroll ? t('degree.enrolledDescription') : t('degree.enrollmentDescription');

  return (
    <>
      <Helmet>
        <title>{degree.title ? `${degree.title} | ${t('title.YAMEducation')} ` : t('title.default')}</title>
        <meta name="description" content={degree?.shortDescription} />
      </Helmet>
      <Summary item={degree} isDegree />
      <div className="container-card-enroll">
        <CardDegreeEnroll
          isDegree
          isEnroll={isEnroll}
          isSubscribed={isSubscribed}
          isEnrollLoading={isEnrollLoading}
          degree={degree}
          title={titleCard()}
          description={description}
          price={degree.price}
          userInfo={userInfo}
          onViewCourseOpenEdx={handleViewCourseOpenEdx}
          reloadDegreeDetails={() => {
            setEnrollLoading(true);
            getDegreeDetailMutation.mutate({ id: params.degreeId });
          }}
        />
      </div>
      <div className="degree-details-tabs max-w-8xl py-6 sm:px-14 px-12  grow container-degree">
        <div className="container-tab">
          <Tabs.Group aria-label="Tabs with underline" style="underline" className="tab-group">
            <Tabs.Item active={true} title="Overview">
              <OverviewTab degree={degree} />
            </Tabs.Item>
            <Tabs.Item title="Curriculum">
              <CurriculumTab courses={degree?.courses} />
            </Tabs.Item>
          </Tabs.Group>
        </div>
      </div>
    </>
  );
};
export default DegreeDetail;
