import { Dropdown } from "flowbite-react";
import { useMemo } from "react";
import './languageSelect.scss'
import i18n from '../../@core/locales/index';
import { listLanguage, openedxCookieKey } from "utils/constants";
import { getCookie, setCookie } from "utils/cookie";
import { useSelector } from "react-redux";
import { userSelector } from "redux/selectors";
import { isEmpty } from "lodash";
import { changeLanguage } from "api/userApi";

const LanguageSelect = (props: any) => {
    const { id } = props;
    const { userInfo } = useSelector(userSelector);

    const changeUserLanguageSetting = async (languageSetting: any) => {
        const { code: languageCode } = languageSetting;
        await changeLanguage({ language: languageCode });
    }

    const handleOnSelectLanguage = (item: any) => {
        setCookie(openedxCookieKey, item.code);
        i18n.changeLanguage(item.code)

        // call API to change language
        if (!isEmpty(userInfo)) {
            changeUserLanguageSetting(item)
        }
    }

    const renderListLanguage = () => {
        return (
            listLanguage.map((item: any, index: number) => {
                return (
                    <Dropdown.Item key={`${id}-${item.code}-${index}`} onClick={() => handleOnSelectLanguage(item)}>
                        {item.label}
                    </Dropdown.Item>
                )
            })
        )
    }

    const labelLanguage = useMemo(() => {
        // this is for dropdown label
        if (i18n.language) {
            const languageCode = getCookie(openedxCookieKey)
            const currentLang = i18n.language
            let targetLanguage = listLanguage.find((item: any) => {
                return item.code === currentLang
            });
            if (!targetLanguage) {
                targetLanguage = listLanguage.find((item: any) => {
                    return item.code === languageCode
                });
            }
            if (!targetLanguage) {
                return listLanguage[0].label
            }
            return targetLanguage.label;
        }
    // eslint-disable-next-line
    }, [i18n, i18n.language])

    return (
      <div className="custom-language" id={id}>
        <Dropdown
            label={labelLanguage}
            inline={true}
            floatingArrow={true}
            id={`${id}-selecter`}
            placement={id === 'footer' ? "top" : "bottom"}
        >
            {
                renderListLanguage()
            }
        </Dropdown>
      </div>
    )
}

export default LanguageSelect;
