import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  courses: [],
  totalEntities: 0,
};

const setCourses = (state: any, action: any) => {
  state.courses = action.payload.entities;
  state.totalEntities = action.payload.totalEntities;
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setCourses,
  },
});
export default coursesSlice;
