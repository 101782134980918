import { useEffect, useState } from 'react';
import { Modal } from 'flowbite-react';
import { useMutation } from 'react-query';
import CustomModalHeader from './customModalHeader';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CustomSelect from '../commonComponent/customSelect';
import GroupButton from '../button/groupButton';
import { updateCourseById } from '../../api/coursesApi';
import './courseEditModal.scss';
import CustomCheckbox from 'components/form/customCheckbox';
import FormComponent from 'components/form/form';

const CourseEditModal = (props: any) => {
  const { openModal, setOpenModal, modalData, courseCategories, successFunc } = props;
  type formDataEditCourseType = {
    courseCategories?: string;
    entitlementAvailable?: boolean;
    priceUSD?: number;
    includeInPlan?: boolean;
  };
  const defaultEditCourseData: formDataEditCourseType = {
    courseCategories: '',
    entitlementAvailable: false,
    priceUSD: 0,
    includeInPlan: false,
  };
  const [formDataEditCourse, setFormDataEditCourse] = useState<formDataEditCourseType>(defaultEditCourseData);
  const [selectedOption, setSelectedOption] = useState<any>();
  
  const [t] = useTranslation();

  const ValidateSchema = Yup.object().shape({
    courseCategories: Yup.string().required(t('requiredField')),
    ...(formDataEditCourse.entitlementAvailable && { priceUSD: Yup.number().min(0).required(t('requiredField')) }),
  });

  const handleSubmit = (value: any, props: any) => {
    const payload = {
      courseCategoryId: value.courseCategories,
      entitlementAvailable: value.entitlementAvailable,
      ...(value.entitlementAvailable && { priceUSD: Number(value.priceUSD) }),
      includeInPlan: value.includeInPlan,
    };

    mutation.mutate(payload, {
      onSuccess: () => {
        setOpenModal(!openModal);
        const message: string = t('admin.courses.editSuccessMessage');
        toast.success(message);
        props.resetForm();
        setSelectedOption([]);
        successFunc();
      },
      onError: async (error: any) => {
        const message: string = `${error.response.data.errors?.[0].detail}`;
        toast.error(message);
      },
    });
  };

  const cancelHandler = (props: any) => {
    props.resetForm();
    setOpenModal(false);
  };

  const handleChange = (item: any, props: any) => {
    setSelectedOption(item);
    setFormDataEditCourse({
      ...formDataEditCourse,
      courseCategories: item.value,
      priceUSD: props.values.priceUSD,
    });
    props.setFieldValue('courseCategories', item.value);
  };

  const onChangeEntitlement = (e: any, props: any) => {
    setFormDataEditCourse({
      ...formDataEditCourse,
      entitlementAvailable: e.target.checked,
      priceUSD: props.values.priceUSD,
    });
  };

  const onChangeIncludeInPlan = (e: any, props: any) => {
    setFormDataEditCourse({
      ...formDataEditCourse,
      includeInPlan: e.target.checked,
      priceUSD: props.values.priceUSD,
    });
  };

  const handleUpdate = async (payload: any) => {
    await updateCourseById(modalData.id, payload);
  };

  const mutation = useMutation('update-category', { mutationFn: handleUpdate });

  useEffect(() => {
    setSelectedOption({
      label: modalData?.courseCategory?.name,
      value: modalData?.courseCategory?.id,
    });
    setFormDataEditCourse({
      entitlementAvailable: modalData?.entitlementAvailable,
      courseCategories: modalData?.courseCategory?.id,
      includeInPlan: modalData?.includeInPlan,
      priceUSD: modalData?.priceUSD || 0,
    });
    // eslint-disable-next-line
  }, [modalData, openModal]);

  return (
    <Modal className="admin-modal course-edit-modal" show={openModal} size="2xl" popup={true}>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={formDataEditCourse}
        validationSchema={ValidateSchema}
        className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
      >
        {props => (
          <Form>
            <div className='px-2 course-edit-header'>
              <CustomModalHeader title={`Edit "${modalData?.title}"`} toggle={() => cancelHandler(props)} />
            </div>
            <Modal.Body className="course-edit-modal-body">
              <div className="flex flex-col px-4 gap-4">
                <CustomSelect
                  formProps={props}
                  onChange={handleChange}
                  options={courseCategories}
                  isMulti={false}
                  value={selectedOption}
                  id="courseCategories"
                  selectName="courseCategories"
                  labelName={`${t('admin.courses.category')} *`}
                />
                <CustomCheckbox
                  formProps={props}
                  onChange={onChangeEntitlement}
                  id="entitlementAvailable"
                  name="entitlementAvailable"
                  checked={formDataEditCourse?.entitlementAvailable}
                  labelName={t('admin.courses.checkboxIndividualPurchase')}
                />
                {formDataEditCourse?.entitlementAvailable && (
                  <>
                    <FormComponent
                      label={`${t('admin.courses.priceUSD')} *`}
                      id="priceUSD"
                      type="number"
                      placeholder="0"
                      name="priceUSD"
                      isFieldRequire={true}
                    />
                  </>
                )}
                <CustomCheckbox
                  formProps={props}
                  onChange={onChangeIncludeInPlan}
                  id="includeInPlan"
                  name="includeInPlan"
                  checked={formDataEditCourse?.includeInPlan}
                  labelName={t('admin.courses.includeInPlan')}
                />
                <GroupButton
                  className="actions items-center justify-center pt-1 pb-2"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => cancelHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                      isLoading: mutation.isLoading,
                    },
                  ]}
                />
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default CourseEditModal;
