import ProgramItem from './programItem';
import './slideList.scss';
import { HiChevronDoubleRight, HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DegreeItem from './DegreeItem';
import { courseType } from '../../utils/proptypes';
import moment from 'moment';
//#region

/************************************
1. if you want to change the dimensions of the slides you will need to edit the slideWidth variable here 👇 and the $slide-width variable in the CSS.
************************************/
const slideWidth = 21.8;

const sleep = (ms = 0) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const createItem = (position: number, idx: number, listData: any) => {
  const item: any = {
    styles: {
      transform: `translateX(${position * slideWidth}rem)`,
    },
    data: listData[idx],
  };
  const length = listData.length;

  switch (position) {
    case length - 1:
    case length + 1:
      item.styles = { ...item.styles, opacity: 0 };
      break;
    case length:
      break;
    default:
      // opacity: 0 to prevent lag layout
      item.styles = { ...item.styles };
      break;
  }

  return item;
};

const CarouselSlideItem = (props: any) => {
  const { pos, idx, listData, degreeItem, isEarnYourDegree, handleOnClickViewCourseDetail } = props;
  const item = createItem(pos, idx, listData);

  return (
    <li className="carousel__slide-item" style={item.styles}>
      {isEarnYourDegree ? (
        <DegreeItem
          key={item.data.id}
          name={item?.data.title}
          imageUrl={item?.data?.image?.url}
          id={item.data.id}
          isCourse
          hasProgress={false}
          handleOpenDetail={() => handleOnClickViewCourseDetail(item.data.id)}
        />
      ) : (
        <ProgramItem isDegree={!!degreeItem} data={item.data} />
      )}
    </li>
  );
};

const CarouselItem = (props: any) => {
  const { data, degreeItem, isEarnYourDegree, handleOnClickViewCourseDetail } = props;
  const keys = Array.from(Array(data.length).keys());

  const [items, setItems] = useState(keys);
  const [isTicking, setIsTicking] = useState(false);
  const [, setActiveIdx] = useState(0);
  const bigLength = items.length;

  const prevClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems(prev => {
        return prev.map((_, i) => prev[(i + jump) % bigLength]);
      });
    }
  };

  const nextClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems(prev => {
        return prev.map((_, i) => prev[(i - jump + bigLength) % bigLength]);
      });
    }
  };

  useEffect(() => {
    if (isTicking) sleep(300).then(() => setIsTicking(false));
  }, [isTicking]);

  useEffect(() => {
    setActiveIdx((data.length - (items[0] % data.length)) % data.length) // prettier-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <div className="carousel__wrap">
      <div className="carousel__inner">
        <button className="carousel__btn carousel__btn--prev" onClick={() => prevClick()}>
          <HiChevronLeft className="h-8 w-8 text-gray-900" />
        </button>
        <div className="carousel__container">
          <ul className="carousel__slide-list">
            {items.map((pos, i) => (
              <CarouselSlideItem
                key={i}
                idx={i}
                pos={pos}
                listData={data}
                degreeItem={degreeItem}
                isEarnYourDegree={isEarnYourDegree}
                handleOnClickViewCourseDetail={handleOnClickViewCourseDetail}
              />
            ))}
          </ul>
        </div>
        <button className="carousel__btn carousel__btn--next" onClick={() => nextClick()}>
          <HiChevronRight className="h-8 w-8 text-gray-900" />
        </button>
      </div>
    </div>
  );
};
//#endregion

const SlideItem = (props: any) => {
  const { data, title, handleOnClickExploreAll, degreeItem, isEarnYourDegree, handleOnClickViewCourseDetail, isCourse = false } = props;
  const [t] = useTranslation();

  const repeatData = () => {
    let finalData: courseType[] = data;
    if (isCourse) {
      if (Array.isArray(data)) {
        finalData = data.filter(

            (item: courseType) => !((item.enrollmentEnd && item.enrollmentStart >= moment().utc().format())),


          /*(item: courseType) =>
            !(
              (item.enrollmentEnd && item.enrollmentStart >= moment().utc().format()) ||
              (item.enrollmentEnd && item.enrollmentEnd <= moment().utc().format())
            ),*/
        );
      }
    } else {
      finalData = data;
    }

    let returnData = finalData;
    if (finalData.length && finalData.length < 9) {
      const repeatTime = Math.round(9 / finalData.length + 0.5);

      for (let i = 1; i < repeatTime; i++) {
        returnData = [...returnData, ...finalData];
      }
    }
    return returnData;
  };

  return (
    <>
      <div className="slide-item">
        <div className="flex justify-between items-center pb-3">
          <span className="text-gray-900 text-xl font-semibold">{title}</span>
          <div className="flex items-center cursor-pointer text-gray-900 hover:text-blue-700" onClick={() => handleOnClickExploreAll()}>
            <span className="mr-1 text-sm font-medium">{t('common.exploreAll')}</span>
            <HiChevronDoubleRight className="w-4 h-4" />
          </div>
        </div>
        <CarouselItem
          data = {repeatData()}
          degreeItem={degreeItem}
          isEarnYourDegree={isEarnYourDegree}
          handleOnClickViewCourseDetail={handleOnClickViewCourseDetail}
        />
      </div>
    </>
  );
};

export default SlideItem;
