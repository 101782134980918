import NavbarComponent from '../components/layouts/navbar';
import FooterComponent from 'components/layouts/footer';
import SidebarComponent from 'components/layouts/sidebar';

const DashboardPage = () => {
  return (
    <div className="w-full flex flex-row justify-center ">
      <SidebarComponent isDashboard={true} />
      <div className="w-full h-full flex flex-col justify-between bg-gray-50">
        <div className="w-full overflow-y-hidden border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800">
          <NavbarComponent />
        </div>
        <FooterComponent />
      </div>
      <div className="h-screen block" />
    </div>
  );
};
export default DashboardPage;
