import { updateCurrentUser } from 'api/userApi';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/useUser';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/selectors';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import FormComponent from 'components/form/form';
import GroupButton from 'components/button/groupButton';
import './student-profile.scss';
import { messageErrors } from '../../utils/utils';
import { routePaths } from '../../utils/constants';

const StudentProfile = () => {
  const [t] = useTranslation();
  const { fetchCurrentUser } = useUser();
  const { userInfo } = useSelector(userSelector);

  const handleUpdateUser = async (values: any) => {
    await updateCurrentUser({
      firstName: values.firstName,
      lastName: values.lastName,
    });
  };

  const mutationUpdateUser = useMutation('update-user', {
    mutationFn: handleUpdateUser,
    onSuccess: async () => {
      await fetchCurrentUser();
      const message: string = t('user.updateSuccess');
      toast.success(message);
      window.location.href = routePaths.HOME;
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const handleSubmit = (values: any) => {
    mutationUpdateUser.mutate(values);
  };

  const onValidate = (values: any) => {
    const errors: any = {};
    if (!values.firstName) errors.firstName = t('requiredField');
    if (!values.lastName) errors.lastName = t('requiredField');
    return errors;
  };

  const renderUserForm = () => {
    return (
      <div className="profile-container flex flex-wrap items-center shadow-sm bg-white p-6 rounded-lg justify-center gap-4">
        <div className="profile-title mb-1">{t('userProfilePage.almostDone')}</div>
        <Formik enableReinitialize onSubmit={handleSubmit} initialValues={userInfo || {}} validate={onValidate}>
          <Form className="w-full">
            <div className="mb-3.5">
              <FormComponent label={`${t('userProfilePage.firstName')} *`} id="firstName" type="text" placeholder="Jese" name="firstName" />
            </div>
            <div className="mb-8">
              <FormComponent label={`${t('userProfilePage.lastName')} *`} id="lastName" type="text" placeholder="Leos" name="lastName" />
            </div>
            <div className="flex items-end justify-center">
              <GroupButton
                className="items-center justify-center"
                buttons={[
                  {
                    type: 'submit',
                    text: t('userProfilePage.saveAndContinue'),
                    classType: 'blue',
                    isLoading: mutationUpdateUser.isLoading,
                  },
                ]}
              />
            </div>
          </Form>
        </Formik>
      </div>
    );
  };

  return (
    <>
      <div className="profile-page overflow-auto flex flex-col items-center pt-14 mx-auto bg-gray-50">
        <div className="bg-white shadow-sm rounded-lg m-auto">{renderUserForm()}</div>
      </div>
    </>
  );
};
export default StudentProfile;
