import SlideItem from 'components/commonComponent/slideList';
import { HiSearch } from 'react-icons/hi';
import './home.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useEffect, useState, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getDegrees } from 'api/degreesApi';
import { useDispatch, useSelector } from 'react-redux';
import { coursesAction, degreesAction } from 'redux/actions';
import { coursesSelector, degreesSelector } from 'redux/selectors';
import { getCourses } from 'api/coursesApi';
import _, { isEmpty } from 'lodash';
import { SUBSCRIPTION_TYPE, coursesCount, reloginUser, routePaths } from 'utils/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner, TextInput } from 'flowbite-react';
import ProgramItem from 'components/commonComponent/programItem';
import debouce from 'lodash.debounce';
import NoResult from 'components/commonComponent/noResult';
import SpinnerComponent from 'components/spinner';
import { useAuth0 } from 'components/Auth0';
import RenewWarningModal from 'components/modal/renewWarningModal';
import { deleteCookie, getCookie } from 'utils/cookie';
import SelectCurrencyRenewPlanModal from 'components/modal/selectCurrencyRenewPlanModal';
import useUser from 'hooks/useUser';
import { toast } from 'react-toastify';
import { isPlanExpired } from 'utils/utils';
import { status } from 'utils/constants';
import { getCourseEnrollment } from 'api/userApi'

const BannerSearch = (props: any) => {
  const { debouncedResults } = props
  const { t } = useTranslation();

  return (
    <div className="banner-search shadow-md flex flex-col items-center text-white">
      <h1 className="font-semibold text-xl md:text-4xl ">{t('guestHomePage.bannerTitle')}</h1>
      <h2 className="font-normal text-base md:text-xl py-3 mb-4">{t('guestHomePage.bannerDescription')}</h2>
      <div className="rounded-3xl search">
        <TextInput
          rightIcon={HiSearch}
          id="base"
          type="text"
          sizing="md"
          placeholder={t('placeHolder.searchCourses')}
          onChange={debouncedResults}
          autoFocus
        />
      </div>
    </div>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isReloginUser = getCookie(reloginUser);

  const { user: userInfo } = useAuth0();
  const { fetchCurrentUser } = useUser();

  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([])
  const [totalEntitiesSearch, setTotalEntitiesSearch] = useState(0)

  const [showModalCurrencyRenew, setShowModalCurrencyRenew] = useState(false)
  const [showExpriedPlanModal, setShowExpriedPlanModal] = useState(userInfo &&
    isPlanExpired(_.get(userInfo, 'subscriptions[0].expiredAt', null)) && _.get(userInfo, 'subscriptions[0].type') !== SUBSCRIPTION_TYPE.FREE && isReloginUser );

  const { degrees } = useSelector(degreesSelector);
  const { courses } = useSelector(coursesSelector);


  const [courseEntities, setCourseEntities] = useState<any[]>([]);
  const [isLoadingCourseEntities, setIsLoadingCourseEntities] = useState(true);

  const fetchCourseEntities = async () => {
    try {
      setIsLoadingCourseEntities(true);
      const courseEnrollment = await getCourseEnrollment();
      setCourseEntities(courseEnrollment?.data);
    } catch (error) {
      setCourseEntities([]);
    } finally {
      setIsLoadingCourseEntities(false);
    }
  };
  

  useEffect(() => {
    fetchCourseEntities();
  }, []);

  const degreeProgramsExploreAll = () => {
    navigate(routePaths.DEGREES);
  };

  const courseExplorerExploreAll = () => {
    navigate(routePaths.COURSES);
  };

  const myCoursesExploreAll = () => {
    navigate(routePaths.MY_LEARNING);
  };

  const getDegreesMutation = useMutation('getDegrees', {
    mutationFn: getDegrees,
    retry: false,
    onSuccess: ({ data }) => {
      dispatch(degreesAction.setDegrees(data));
    },
  });

  const getCoursesMutation = useMutation('getCourses', {
    mutationFn: getCourses,
    retry: false,
    onSuccess: ({ data }) => {
      dispatch(coursesAction.setCourses(data));
    },


  });


  const { isLoading, isFetching, data } = useQuery(['getCourses', currentPage, searchValue], () => getCourses({
    page: currentPage,
    limit: coursesCount,
    searchQuery: searchValue,
  }), {
    staleTime: Infinity,
    enabled: searchValue !== '',
    onSuccess: ({ data }) => {
      setTotalEntitiesSearch(data.totalEntities);
    },
    onError: () => {
      setSearchResults([]);
    },
  });

  const fetchMoreCourses = () => {
    setCurrentPage(currentPage + 1);
  };

  const getCourseAndDegree = () => {
    getDegreesMutation.mutate({
      page: 0,
      limit: 20,
      status: status.ACTIVE,
    });
    getCoursesMutation.mutate({
      page: 0,
      limit: 20,
    });
  };

  const handleOpenDetail = (courseId: string) => {
    navigate(`${routePaths.COURSES}/${courseId}`);
  };


  const handleChange = (e: any) => {
    setSearchValue(e.target.value);
  };
  const debouncedResults = useMemo(() => {
    return debouce(handleChange, 700);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    setCurrentPage(0)
    if (searchValue === '') {
      setSearchResults([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  useEffect(() => {
    const entities = data?.data?.entities
    if (entities?.length >= 0) {
      setSearchResults(state => {
        let result = [];

        if (currentPage === 0) {
          result = entities;
        } else {
          result = [...state, ...entities];
        }

        return result;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);


  useEffect(() => {
    getCourseAndDegree();
    // eslint-disable-next-line
  }, []);

  const renderCourses = () => {

    return (
      <>
        <div className='pt-5 flex justify-center'>
          <div>
            <Trans
              i18nKey="searchResults"
              values={{ count: searchResults?.length?.toLocaleString("en-US"), searchValue: searchValue }}
              components={{
                italic: <i />, bold: <strong />, span: <span className='text-primary font-semibold' />
              }} />
          </div>
        </div>

        <div className="w-full mx-auto max-screen lg:px-0 px-6 h-full courses pt-5">
          <div id="scrollableDiv">
            <InfiniteScroll
              // inverse
              dataLength={searchResults?.length}
              next={fetchMoreCourses}
              hasMore={searchResults?.length < totalEntitiesSearch}
              scrollableTarget="scrollableDiv"
              scrollThreshold={0.8}
              loader={<span className="flex justify-center mt-5 mb-5">
                <Spinner aria-label="spinner example" />
              </span>}
            >
              <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 sm:grid-cols-2">
                {searchResults?.map((course: any) => {
                  return (
                    <div key={course.id} className="py-2">
                      <ProgramItem key={course.id} data={course} isDegree={false} handleOpenDetail={() => handleOpenDetail(course.id)} />
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          </div>
        </div></>
    )
  }

  const handleCloseExpiredModal = (isClose: any) => {
    // remove cookie
    deleteCookie(reloginUser);
    setShowExpriedPlanModal(isClose);
  }

  const currentPlan = _.get(userInfo, 'subscriptions[0].plan', null);

  const handleRenewSuccessful = async () => {
    setShowModalCurrencyRenew(false);
    await fetchCurrentUser()
    const message: string = t('subscription.createdSuccess');
    toast.success(message);
    navigate(routePaths.HOME);
  }

  return (
    <>
      <BannerSearch debouncedResults={debouncedResults} />
      <div className='grow'>
        {!isLoadingCourseEntities && (!isLoading || !isFetching) ? (
          searchValue !== '' ?
            (searchResults?.length > 0 ? (renderCourses()) : (<NoResult />))
            : (
              <>
              { /* My Courses */}
                <div className="custom-w-screen mx-auto py-4 sm:px-8 px-4">
                  {!isEmpty(courseEntities) && (
                    <SlideItem
                      data={courseEntities}
                      title={t('guestHomePage.myCoursesTitle')}
                      handleOnClickExploreAll={myCoursesExploreAll}
                      isCourse
                    />
                  )}
                </div>
                { /* Course Explorer */}
                <div className="custom-w-screen mx-auto py-4 sm:px-8 px-4">
                  {!isEmpty(courses) && (
                    <SlideItem
                      data={courses}
                      title={t('guestHomePage.coursesTitle')}
                      handleOnClickExploreAll={courseExplorerExploreAll}
                      isCourse
                    />
                  )}
                </div>
                {   /* Degree Programs */}
                <div className="bg-gray-100 py-6">
                  <div className="w-full mx-auto max-screen lg:px-0 px-6">
                    {!isEmpty(degrees) && (
                      <SlideItem
                        degreeItem={true}
                        data={degrees}
                        title={t('guestHomePage.degreeProgramTitle')}
                        handleOnClickExploreAll={degreeProgramsExploreAll} />
                    )}
                  </div>
                </div>
              </>
            )

        ) : <SpinnerComponent />
        }
      </div>
      <RenewWarningModal 
        openModal={showExpriedPlanModal} 
        setOpenModal={(isClose: any) => handleCloseExpiredModal(isClose)} 
        handleRenew={() => {handleCloseExpiredModal(false); setShowModalCurrencyRenew(true);}}
        />
      {
        userInfo && (
          <SelectCurrencyRenewPlanModal 
            openModal={showModalCurrencyRenew} 
            currentPlan={currentPlan} 
            handleRenewSuccessful={() => handleRenewSuccessful()}
            setOpenModal={(isClose: any) => setShowModalCurrencyRenew(isClose)} 
          />
        )
      }
    </>
  );
};

export default Home;
