import { routePaths, userRoles } from './constants';

const perimissionProfiles = [
  {
    type: userRoles.STANDARD_USER,
    defaultRedirectUrl: routePaths.DASHBOARD_PAGE,
    permittedRoutes: [
      routePaths.FILE_REVIEW_PAGE,
      routePaths.DASHBOARD_PAGE,
      routePaths.PROFILE_PAGE,
      routePaths.UPDATE_PROFILE,
      routePaths.VERSION_PAGE,
    ],
    customActions: null,
  },
  {
    type: userRoles.SUPER_ADMIN,
    defaultRedirectUrl: routePaths.DASHBOARD_PAGE,
    permittedRoutes: [
      routePaths.FILE_REVIEW_PAGE,
      routePaths.DASHBOARD_PAGE,
      routePaths.PROFILE_PAGE,
      routePaths.UPDATE_PROFILE,
      routePaths.VERSION_PAGE,
      routePaths.ORGANIZATION_PAGE,
      routePaths.USERS_PAGE,
      routePaths.ORGANIZATION_SETTING,
      routePaths.ADMIN_COURSES,
      routePaths.AMDIN_DEGREES,
      routePaths.ADD_DEGREE,
      routePaths.EDIT_DEGREE,
      routePaths.ADMIN_CATEGORIE,
      routePaths.ADMIN_INSTITUTIONS
    ],
    customActions: null,
  },
  {
    type: userRoles.ORGANIZATION_ADMIN,
    defaultRedirectUrl: routePaths.DASHBOARD_PAGE,
    permittedRoutes: [
      routePaths.FILE_REVIEW_PAGE,
      routePaths.DASHBOARD_PAGE,
      routePaths.PROFILE_PAGE,
      routePaths.UPDATE_PROFILE,
      routePaths.VERSION_PAGE,
      routePaths.ORGANIZATION_SETTING,
      routePaths.ADMIN_COURSES,
    ],
    customActions: null,
  },
];

export { perimissionProfiles };
