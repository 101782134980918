import userSlice from './slice/userSlice';
import roleSlice from './slice/roleSlice';
import degreesSlice from './slice/degreesSlice';
import institutionSlice from './slice/institutionSlice';
import degreeCategoriesSlice from './slice/degreeCategoriesSlice';
import degreeTypesSlice from './slice/degreeTypesSlice';
import coursesSlice from './slice/coursesSlice';
import courseCategoriesSlice from './slice/courseCategoriesSlice';
import courseTypesSlice from './slice/courseTypesSlice';
import courseDetailSlice from './slice/courseDetailSlice';
import degreeDetailSlice from './slice/degreeDetailSlice';
import ratesSlice from './slice/ratesSlice';

const userAction = userSlice.actions;
const roleAction = roleSlice.actions;
const coursesAction = coursesSlice.actions;
const courseCategoriesAction = courseCategoriesSlice.actions;
const courseTypesAction = courseTypesSlice.actions;
const courseDetailAction = courseDetailSlice.actions;
const degreesAction = degreesSlice.actions;
const institutionAction = institutionSlice.actions;
const degreeCategoriesAction = degreeCategoriesSlice.actions;
const degreeTypesAction = degreeTypesSlice.actions;
const degreeDetailAction = degreeDetailSlice.actions;
const ratesAction = ratesSlice.actions;

export {
  userAction,
  roleAction,
  degreesAction,
  institutionAction,
  degreeCategoriesAction,
  degreeTypesAction,
  coursesAction,
  courseCategoriesAction,
  courseTypesAction,
  courseDetailAction,
  degreeDetailAction,
  ratesAction,
};
