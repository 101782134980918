
import { Badge, Button } from 'flowbite-react';
import _ from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import './myPurchases.scss';
import { useNavigate } from 'react-router-dom';
import { routePaths, SUBSCRIPTION_TYPE } from 'utils/constants';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/selectors';
import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import SpinnerComponent from 'components/spinner';
import { compareDate, getBillingStatus, getInterval, isPlanExpired } from 'utils/utils';
import { useMutation } from 'react-query';
import { getMyBillings } from 'api/userApi';
import SelectCurrencyRenewPlanModal from 'components/modal/selectCurrencyRenewPlanModal';
const receiptMinus = require('../../assets/image/svg/receipt-minus.svg').default || '';

const MyPurchases = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const defaultPlan = {
    name: '',
    amount: '',
    currency: '',
    interval: '',
    duration: '',
  };

  const { fetchCurrentUser } = useUser();
  const [billings, setBillings] = useState([]);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [showModalCurrencyRenew, setShowModalCurrencyRenew] = useState(false)

  const { userInfo } = useSelector(userSelector);
  const { mutate: funGetMyBillings } = useMutation('getMyBillings', {
    mutationFn: getMyBillings,
    onSuccess: ({ data }) => {     
      setBillings(data.entities);
    },
  });

  useEffect(() => {
    funGetMyBillings({ page: 0, limit: 1000 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentPlan = _.get(userInfo, 'subscriptions[0].plan', defaultPlan);
  const expiredDate = _.get(userInfo, 'subscriptions[0].expiredAt', null);

  const renderCurrentPlanName = () => {
    const { name, type, interval, priceUSD } = currentPlan;
    return type === SUBSCRIPTION_TYPE.FREE ? (
      `${name} ${SUBSCRIPTION_TYPE.FREE}`
    ) : (
      <>
        <div>
          <span className="text-primary font-semibold">{`${name} `}</span>
          <span className="font-bold">{`$${priceUSD / 100} `} </span>
          <span>{`${t('myPurchasesPage.per')} ${getInterval(interval)}`} </span>
        </div>
      </>
    );
  };

  const renderProjectedTotal = () => {
    const { priceUSD, type } = currentPlan;
    return type === SUBSCRIPTION_TYPE.FREE ? (
      <span className="font-bold">$0</span>
    ) : (
      <>
        <span className="font-bold">{`$${priceUSD / 100}`}</span>
        {' ' + t('myPurchasesPage.totalExcludingTax')}
      </>
    );
  };

  const handleRenewSuccessful = async () => {
    setShowModalCurrencyRenew(false);
    setIsLoadingUser(true);
    await fetchCurrentUser()
    const message: string = t('subscription.createdSuccess');
    toast.success(message);
    setIsLoadingUser(false);
  }

  const handleUpgradeSubcription = () => {
    navigate(routePaths.SUBSCRIPTION);
  };

  const renderNextBilling = () => {
    const { type } = currentPlan;
    return type === SUBSCRIPTION_TYPE.FREE ? (
      <Trans
        i18nKey="myPurchasesPage.nextBilling"
        components={{
          italic: <i />,
          bold: <strong />,
          span: <span onClick={handleUpgradeSubcription} className="text-primary cursor-pointer font-semibold" />,
        }}
      />
    ) : (!expiredDate || compareDate(expiredDate, new Date())) ? (
      <>
        {
          !expiredDate ? (t('myPurchasesPage.nextBillingNonExpiredDay')) : (
            <span>
              <Trans
                i18nKey="myPurchasesPage.nextBillingExpiredDay"
                values={{ date: moment(new Date(expiredDate)).format('DD MMM YYYY') }}
                components={{
                  italic: <i />,
                  bold: <strong />,
                }}
              />
            </span>
          )
        }
        <Badge color="failure" className="next-billing-badge">
          {t('myPurchasesPage.expiredText')}
        </Badge>
      </>
    ) : (
      <Trans
        i18nKey="myPurchasesPage.renewDate"
        values={{ date: moment(expiredDate).format('DD MMM YYYY') }}
        components={{
          italic: <i />,
          bold: <strong />,
        }}
      />
    );
  };

  const handleRenew = () => {
    setShowModalCurrencyRenew(true);
  }

  return (
    <div className="my-purchases grow">
      <div className="banner ">
        <div className="text mx-auto max-screen">{t('myPurchasesPage.title')}</div>
      </div>
      {!isLoadingUser ? (
        <div className="content">
          <div className="w-full mx-auto max-screen mt-8 mb-2">
            <div className="plan-overview">
              <div className="card-title">
                <h1>{t('myPurchasesPage.planOverview')}</h1>
              </div>
              {currentPlan === defaultPlan && <div className="no-plan">{t('myPurchasesPage.noPlan')}</div>}
              {currentPlan !== defaultPlan && (
                <div className="content">
                  <div className="row">
                    <div className="grid grid-cols-8">
                      <span className="flex items-center">{t('myPurchasesPage.currentPlan')}</span>
                      <span className="font-bold flex items-center col-span-4">{renderCurrentPlanName()}</span>

                      <div className="flex justify-end col-span-3">
                        {currentPlan.type === SUBSCRIPTION_TYPE.FREE ? (
                          <Button pill={true} color="success" onClick={handleUpgradeSubcription} className="button-upgrade custom-button-style">
                            {t('myPurchasesPage.upgradeSubscription')}
                          </Button>
                        ) : (
                          <>
                            <Button pill={true} color="success" onClick={handleUpgradeSubcription} className="button-change custom-button-style">
                              {t('myPurchasesPage.changeSubscription')}
                            </Button>
                            {isPlanExpired(expiredDate) && (
                              <Button pill={true} color="success" onClick={() => handleRenew()} className='button-renew custom-button-style'>{t('myPurchasesPage.renewSubscription')}</Button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="grid grid-cols-8">
                      <span>{t('myPurchasesPage.billingFor')}</span>
                      <span className="col-span-7 font-bold">1 student</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="grid grid-cols-8">
                      <span>{t('myPurchasesPage.projectedTotal')}</span>
                      <span className="col-span-7">{renderProjectedTotal()}</span>
                    </div>
                  </div>
                  <div className="row last">
                    <div className="grid grid-cols-8">
                      <span>{t('myPurchasesPage.nextBillingDate')}</span>
                      <div className="col-span-7">
                        <div className="next-billing-item">{renderNextBilling()}</div>
                      </div>

                      {/* todo */}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="padding-block"></div>
            <div className="plan-overview">
              <div className="card-title">
                <h1>{t('myPurchasesPage.billingHistory')}</h1>
                {!_.isEmpty(billings) && <div className="descriotion">{t('myPurchasesPage.billingHistoryDescription')}</div>}
              </div>
              <div className="content">
                {_.isEmpty(billings) && (
                  <div className="no-billing">
                    <img src={receiptMinus} alt="logo" />
                    {t('myPurchasesPage.notBillingHistory')}
                  </div>
                )}
                {!_.isEmpty(billings) &&
                  billings.map((bill: any) => {
                    return (
                      <div key={`${bill.transactionId}-${bill.payType}-bills`} className="row-2">
                        <div className="grid grid-cols-8">
                          <span>{moment(bill.payAt).format('DD MMM YYYY')}</span>
                          <span className="col-span-7">
                            {bill.payType === 'COURSE' && (
                              <>
                                "{bill.course.title}" course for "Individual Purchase" with <span className="font-bold">${bill.priceUSD / 100}</span>{' '}
                                (Total including tax)
                                <span className={`status ${getBillingStatus(bill.status)}`}>{getBillingStatus(bill.status)}</span>
                              </>
                            )}
                            {bill.payType === 'PLAN' && (
                              <>
                                {bill.plan?.name} <span className="font-bold">${bill.priceUSD / 100}</span> per {getInterval(bill?.plan?.interval)}{' '}
                                (Total including tax)
                                <span className={`status ${getBillingStatus(bill.status)}`}>{getBillingStatus(bill.status)}</span>
                              </>
                            )}
                            {bill.payType === 'UPGRADE' && (
                              <>
                                You <span className="font-bold">upgrade</span> from "{bill.subscriptionOld?.plan?.name}" to "{bill.plan?.name}" with{' '}
                                <span className="font-bold">${bill.priceUSD / 100}</span>
                                <span className={`status ${getBillingStatus(bill.status)}`}>{getBillingStatus(bill.status)}</span>
                              </>
                            )}
                            {bill.payType === 'RENEW' && (
                              <>
                                You <span className="font-bold">renew</span> "{bill.plan?.name}" plan with{' '}
                                <span className="font-bold">${bill.priceUSD / 100}</span> per {getInterval(bill?.plan?.interval)} (Total including{' '}
                                tax)
                                <span className={`status ${getBillingStatus(bill.status)}`}>{getBillingStatus(bill.status)}</span>
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="padding-block"></div>
          </div>
        </div>
      ) : (
        <SpinnerComponent />
      )}

    <SelectCurrencyRenewPlanModal 
      openModal={showModalCurrencyRenew} 
      currentPlan={currentPlan} 
      handleRenewSuccessful={() => handleRenewSuccessful()}
      setOpenModal={(isClose: any) => setShowModalCurrencyRenew(isClose)} 
    />
    </div>
  );
};
export default MyPurchases;
