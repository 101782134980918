const httpMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

const userRoles = {
  SUPER_ADMIN: 'SuperAdmin',
  ORGANIZATION_ADMIN: 'OrganizationAdmin',
  STANDARD_USER: 'StandardUser',
};

const SystemRoleLevel = {
  SUPER_ADMIN: 1,
  ORGANIZATION_ADMIN: 2,
  STANDARD_USER: 3,
};

const roleOptions = [
  { label: 'Super Admin', value: userRoles.SUPER_ADMIN },
  { label: 'Organization Admin', value: userRoles.ORGANIZATION_ADMIN },
  { label: 'Standard User', value: userRoles.STANDARD_USER },
];

const pacingOptions = [
  { label: 'Self', value: 'self' },
  { label: 'Instructor', value: 'instructor' },
];

const routePaths = {
  TEST_LINK:'/test',
  FILE_REVIEW_PAGE: '/review/:id',
  LOGIN_PAGE: '/login',
  VERSION_PAGE: '/version',
  LOGOUT_PAGE: '/logout',
  NOT_FOUND_PAGE: '/not-found',
  PROFILE_PAGE: '/profile',
  UPDATE_PROFILE: '/update-profile',
  ORGANIZATION_PAGE: '/organization',
  ROLES_PAGE: '/roles',
  USERS_PAGE: '/users',
  DASHBOARD_PAGE: '/admin-dashboard',
  ORGANIZATION_SETTING: '/organization-setting/:organizationId',
  CHANGE_PASSWORD: '/change-password',
  HOME: '/',
  INSTITUTIONS: '/institutions',
  CoursEnroleList: '/CoursEnroleList',
  DEGREES: '/degrees',
  COURSES: '/courses',
  MY_COURSES: '/my-courses',
  SUBSCRIPTION: '/subscription',
  // SUBSCRIPTION_UPGRADE: '/subscription/:changeSubscription',
  MY_PURCHASES: '/my-purchases',
  MY_DEGREES: '/my-degree',
  COURSE_DETAIL: '/courses/:title/:edxOrg/:edxNumber',
  DEGREE_DETAIL: '/degrees/:title/:degreeId',
  AMDIN_DEGREES: '/admin-degrees',
  ADMIN_COURSES: '/admin-courses',
  ADD_DEGREE: '/admin-degrees/create',
  MY_LEARNING: '/my-learning',
  EDIT_DEGREE: '/admin-degrees/:degreeId',
  ADMIN_CATEGORIE: '/admin-categorie',
  ADMIN_INSTITUTIONS: "/admin-institutions",

  REDIRECT_COURSE: 'https://studio.courses.yam-edu.com/',
  REDIRECT_ANALYTICS: 'https://data.courses.yam-edu.com/'
};

const status = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

const apiStatus = {
  SUCCESS: 200,
};

const pageCount = 15;
const coursesCount = 12;
const degreesCount = 12;

const exportFileName = {
  ORGANIZATION: 'ListOrganization.csv',
  USER: ' ListUser.csv',
  USER_ORGANIZATION: 'UserOrganization.csv',
  ADMIN_COURSES: 'courses.csv',
};

const roles = ['admin', 'user', 'super-admin'];

const tokenKey = 'token';
const openedxCookieKey = 'openedx-language-preference';
const reloginUser = 're-login-user';

const searchMaxLength = 500;

const delayShowToast = 2000;

const acceptImageTypeFile = '.png, .jpeg, .jpg, .gif';

const BUTTON_COLOR = {
  BLUE: 'bg-blue-600 hover:bg-blue-700',
  YELLOW: 'warning',
  RED: 'bg-red-500 hover:bg-red-800',
  GRAY: 'bg-gray-300 hover:bg-gray-500',
  OUTLINE: 'bg-white border border-gray-200 hover:bg-blue-100 hover:border-blue-600',
};

const page_default = 0;
const limit_default = 0;

const listLanguage = [
  {
    label: 'English',
    id: 1,
    code: 'en',
  },
  {
    label: 'French',
    id: 2,
    code: 'fr',
  },
];
const PLAN_INTERVAL = {
  YEARLY: 'year',
  QUARTERLY: 'quarter',
  MONTHLY: 'month',
  WEEKLY: 'week',
  DAILY: 'day',
  HOURLY: 'hour',
};

const BILLING_STATUS = {
  PENDING: 'pending',
  FAILED: 'failed',
  REFUND: 'refund',
  PAYMENT: 'payment',
  SUCCESSFUL: 'successful',
};

const INTERVAL = {
  MONTH: 'month',
  YEAR: 'year',
};

const SUBSCRIPTION_TYPE = {
  FREE: 'FREE',
  COURSE_ACCESS: 'COURSE_ACCESS',
  SELF_PACED_DEGREE_PROGRAM: 'SELF_PACED_DEGREE_PROGRAM',
  INSTRUCTOR_PACED_DEGREE_PROGRAM: 'INSTRUCTOR_PACED_DEGREE_PROGRAM',
};

const SUBSCRIPTION_TYPE_DISPLAY = {
  FREE: 'Basic',
  COURSE_ACCESS: 'Course Access',
  SELF_PACED_DEGREE_PROGRAM: 'Self-Paced Degree Program',
  INSTRUCTOR_PACED_DEGREE_PROGRAM: 'Instructor-Paced Degree Program',
};

const PACING = {
  SELF: 'self',
  INSTRUCTOR: 'instructor',
};

const CURRENCY_OPTIONS = [
  {
    symbol: '£',
    label: 'GBP (£)',
    value: 'GBP',
  },
  {
    symbol: '$',
    label: 'CAD ($)',
    value: 'CAD',
  },
  {
    symbol: 'Fr',
    label: 'XAF (Fr)',
    value: 'XAF',
  },
  {
    symbol: '$',
    label: 'CLP ($)',
    value: 'CLP',
  },
  {
    symbol: '$',
    label: 'COP ($)',
    value: 'COP',
  },
  {
    symbol: '£',
    label: 'EGP (£)',
    value: 'EGP',
  },
  {
    symbol: '€',
    label: 'EUR (€)',
    value: 'EUR',
  },
  {
    symbol: '₵',
    label: 'GHS (₵)',
    value: 'GHS',
  },
  {
    symbol: 'Fr',
    label: 'GNF (Fr)',
    value: 'GNF',
  },
  {
    symbol: 'Sh',
    label: 'KES (Sh)',
    value: 'KES',
  },
  {
    symbol: 'MK',
    label: 'MWK (MK)',
    value: 'MWK',
  },
  {
    symbol: 'د.م.',
    label: 'MAD (د.م.)',
    value: 'MAD',
  },
  {
    symbol: '₦',
    label: 'NGN (₦)',
    value: 'NGN',
  },
  {
    symbol: 'Fr',
    label: 'RWF (Fr)',
    value: 'RWF',
  },
  {
    symbol: 'Le',
    label: 'SLL (Le)',
    value: 'SLL',
  },
  {
    symbol: 'R',
    label: 'ZAR (R)',
    value: 'ZAR',
  },
  {
    symbol: 'Sh',
    label: 'TZS (Sh)',
    value: 'TZS',
  },
  {
    symbol: 'Sh',
    label: 'UGX (Sh)',
    value: 'UGX',
  },
  {
    symbol: '$',
    label: 'USD ($)',
    value: 'USD',
  },
  {
    symbol: 'Fr',
    label: 'XOF (Fr)',
    value: 'XOF',
  },
  {
    symbol: 'ZK',
    label: 'ZMW (ZK)',
    value: 'ZMW',
  },
];
export {
  httpMethods,
  userRoles,
  roleOptions,
  routePaths,
  status,
  roles,
  pageCount,
  coursesCount,
  degreesCount,
  apiStatus,
  exportFileName,
  tokenKey,
  searchMaxLength,
  delayShowToast,
  acceptImageTypeFile,
  BUTTON_COLOR,
  page_default,
  limit_default,
  SystemRoleLevel,
  listLanguage,
  INTERVAL,
  SUBSCRIPTION_TYPE,
  SUBSCRIPTION_TYPE_DISPLAY,
  PACING,
  openedxCookieKey,
  pacingOptions,
  reloginUser,
  PLAN_INTERVAL,
  BILLING_STATUS,
  CURRENCY_OPTIONS,
};
