import base from './baseApi';

const { urls, methods, execute } = base();
const getDegreeTypes = async () => {
  const method = methods.GET;

  const url = `${urls.degreeTypes.degreeTypes}`;

  const response = await execute(method, url, { authorization: false });

  return response;
};

export { getDegreeTypes };
