import base from './baseApi';

const { urls, methods, execute } = base();

const createCategory = (payload: {
  name: string;
}) => {
  const method = methods.POST;
  const url = urls.courseCategories.courseCategories;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};
const updateActiveCategorieById = (id: string, payload: { isActive: boolean }) => {
  const method = methods.PATCH;
  const url = `${urls.courseCategories.courseCategories}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const getCourseCategories = async () => {
  const method = methods.GET;

  const url = `${urls.courseCategories.courseCategories}`;
  
  const response = await execute(method, url, { authorization: false });

  return response;
};

const updateCategoryById = (id: any, payload: any) => {
  const method = methods.PUT;
  const url = `${urls.courseCategories.courseCategories}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const deleteCourseCategories = async (id: any) => {
  const method = methods.DELETE;

  const url = `${urls.courseCategories.courseCategories}/${id}`;
  
  const response = await execute(method, url, { authorization: true });

  return response;
};


export { getCourseCategories, updateCategoryById, createCategory, deleteCourseCategories,updateActiveCategorieById };
