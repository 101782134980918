import { Sidebar } from 'flowbite-react';
// import { UsersIcon, BuildingOfficeIcon } from '@heroicons/react/20/solid';
import { HiOutlineCog, HiOutlineLogout, HiMenuAlt2, HiX, HiOutlineUser } from 'react-icons/hi';
import { AcademicCapIcon, BuildingLibraryIcon, DocumentTextIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import { useLocation, useNavigate } from 'react-router-dom';
import { routePaths, userRoles } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import './styles.scss';
import { useState } from 'react';
import cn from 'classnames';
import { useAuth0 } from 'components/Auth0';
import { Button } from 'flowbite-react';

const logo = require('../../assets/image/svg/logo.svg').default;
type sidebarProps = {
  isDashboard?: boolean,
};
const SidebarComponent = (props?: sidebarProps) => {
  const location = useLocation();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { logout: handleLogout, user: userInfo, userRole } = useAuth0();

  const [isShowMenu, setIsShowMenu] = useState(false);

  const orgId = _.get(userInfo, 'organizations[0].id', 'orgId');
  const url = location.pathname;

  const checkActivePage = (page: string): any => {
    return url.split('/')[1] === page.split('/')[1] ? 'font-semibold' : undefined;
  };

  const toggleMenu = () => {
    setIsShowMenu(isShowMenu => !isShowMenu);
  };
  const isDashboard = props?.isDashboard || false;

  const getMenu = () => {
    return (
      <Sidebar.ItemGroup>
        {/* <Sidebar.Item href="/" icon={HiOutlineHome} active={checkActivePage(routePaths.DASHBOARD_PAGE)}>
          {t('sidebar.dashboard')}
        </Sidebar.Item>
        <Sidebar.Item icon={HiOutlineUser} active={checkActivePage(routePaths.PROFILE_PAGE)}>
          <p onClick={() => navigate(routePaths.PROFILE_PAGE)} className={checkActivePage(routePaths.PROFILE_PAGE)}>
            {t('sidebar.userProfile')}
          </p>
        </Sidebar.Item> */}

        {userRole === userRoles.SUPER_ADMIN && (
          <>
            <Sidebar.Item icon={HiOutlineUser} active={checkActivePage(routePaths.USERS_PAGE)}>
              <p onClick={() => navigate(routePaths.USERS_PAGE)} className={checkActivePage(routePaths.USERS_PAGE)}>
                {t('sidebar.userManagement')}
              </p>
            </Sidebar.Item>
            <Sidebar.Item className="sidebar-item" icon={AcademicCapIcon} active={checkActivePage(routePaths.AMDIN_DEGREES)}>
              <p onClick={() => navigate(routePaths.AMDIN_DEGREES)} className={checkActivePage(routePaths.AMDIN_DEGREES)}>
                {t('sidebar.degrees')}
              </p>
              
            </Sidebar.Item>
            
            <Sidebar.Item className="sidebar-item" icon={NewspaperIcon} active={checkActivePage(routePaths.ADMIN_COURSES)}>
              <p onClick={() => navigate(routePaths.ADMIN_COURSES)} className={checkActivePage(routePaths.ADMIN_COURSES)}>
                {t('sidebar.courses')}
              </p>
            </Sidebar.Item>
            <Sidebar.Item className="sidebar-item" icon={DocumentTextIcon} active={checkActivePage(routePaths.ADMIN_CATEGORIE)}>
              <p onClick={() => navigate(routePaths.ADMIN_CATEGORIE)} className={checkActivePage(routePaths.ADMIN_CATEGORIE)}>
                {t('sidebar.Courscategorie')}
              </p>
              
            </Sidebar.Item>
            <Sidebar.Item className="sidebar-item" icon={BuildingLibraryIcon} active={checkActivePage(routePaths.ADMIN_CATEGORIE)}>
              <p onClick={() => navigate(routePaths.ADMIN_INSTITUTIONS)} className={checkActivePage(routePaths.ADMIN_INSTITUTIONS)}>
                {t('sidebar.Institutions')}
              </p>
            </Sidebar.Item>
            {/* <Sidebar.Item icon={HiOutlineUserGroup} active={checkActivePage(routePaths.ORGANIZATION_PAGE)}>
              <p onClick={() => navigate(routePaths.ORGANIZATION_PAGE)} className={checkActivePage(routePaths.ORGANIZATION_PAGE)}>
                {t('sidebar.organization')}
              </p>
            </Sidebar.Item> */}
          </> 
        )}
        {userRole === userRoles.ORGANIZATION_ADMIN && orgId !== 'orgId' && (
          <Sidebar.Item icon={HiOutlineCog} active={checkActivePage(routePaths.ORGANIZATION_SETTING)}>
            <p onClick={() => navigate(`/organization-setting/${orgId}`)} className={checkActivePage(routePaths.ORGANIZATION_SETTING)}>
              {t('sidebar.organizationSettings')}
            </p>
          </Sidebar.Item>
        )}
        <div className="flex sm:hidden  border border-gray-200 border-t" />
        <span
          onClick={() => handleLogout()}
          className="flex flex-row sm:hidden cursor-pointer text-base font-normal p-2 sign-out p-2 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        >
          <HiOutlineLogout className="h-6 w-6 mr-3 text-red-500" />
          <span className="font-medium text-red-500">{t('logout')}</span>
        </span>
      </Sidebar.ItemGroup>
    );
  };

  return (
    <>
      {/* for desktop */}
      <div className="sidebar flex flex-col h-screen bg-white overflow-x-hidden overflow-y-hidden border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 text-left">
        <Sidebar aria-label="Sidebar with logo branding">
          <a aria-labelledby="flowbite-sidebar-logo-:r8:" className="border-b border-gray-200 pt-4 pb-5 flex items-center pl-5" href="/">
            <img alt="Yam Edu logo" className="mr-3 h-9 sm:h-9" src={logo} />
            {/* <span className="ml-1 hidden sm:flex self-center whitespace-nowrap text-xl font-semibold dark:text-white">Yam Edu</span> */}
          </a>
          {isDashboard && getMenu()}
        </Sidebar>
        <div className="flex flex-col lg:flex-row justify-between mb-4 px-0 lg:px-4">
          <div className="cursor-pointer py-1.5 text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-white dark:bg-gray-600 focus:!ring-2 group flex h-min w-fit items-center justify-center focus:z-10 rounded-md">
            <HiOutlineCog className="h-6 w-6" />
          </div>
          <div
            onClick={() => handleLogout()}
            className="flex lg:hidden cursor-pointer py-1.5 px-2.5 text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-white dark:bg-gray-600 focus:!ring-2 group flex h-min w-fit items-center justify-center focus:z-10 rounded-md"
          >
            <HiOutlineLogout className="h-6 w-6" />
          </div>
          <Button pill={true} color="success" onClick={handleLogout} className="add-action custom-button-style">
            {t('logout')}
          </Button>

        </div>
      </div>

      {/*  for mobile */}
      <div className="flex sm:hidden nav-menu-mobile">
        <a aria-labelledby="flowbite-sidebar-logo-:r7:" className="flex items-center" href="/">
          <img alt="MileMarker logo" className="mr-3 h-9 sm:h-9" src={logo} />
          {/* <span className="ml-1 text-xl font-semibold dark:text-white">Yam Edu</span> */}
        </a>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center text-sm text-gray-900 rounded-lg hover:bg-gray-100 p-2 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={toggleMenu}
        >
          <span className="sr-only">Open main menu</span>
          <HiMenuAlt2 className="h-7 w-7" />
        </button>
      </div>

      <div
        className={cn('', {
          'show-dropdow-menu': isShowMenu,
          hidden: !isShowMenu,
        })}
      >
        <Sidebar>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center text-sm text-gray-900 p-2 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={toggleMenu}
          >
            <HiX className="h-8 w-8" />
          </button>
          {getMenu()}
        </Sidebar>
      </div>
    </>
  );
};
export default SidebarComponent;
