import { useTranslation } from 'react-i18next';
const searchLogoPath = require('../../assets/image/svg/search-normal.svg').default || '';
const NoResult = () => {
  const [t] = useTranslation();
  return (
    <div className="flex flex-col mt-50 items-center">
      <img src={searchLogoPath} alt="logo" />
      <div className="text-blue-600 text-3xl font-semibold mt-6 primary">{t('searchResult.title')}</div>
      <div className="text-900 text-lg font-normal mt-2">{t('searchResult.subTitle')}</div>
    </div>
  );
};
export default NoResult;
