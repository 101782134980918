import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  degreeCategories: [],
};

const setDegreeCategories = (state: any, action: any) => {
  state.degreeCategories = action.payload;
};

export const degreeCategoriesSlice = createSlice({
  name: 'degreeCategories',
  initialState,
  reducers: {
    setDegreeCategories,
  },
});
export default degreeCategoriesSlice;
