import Routes from './router/';
import { QueryClient, QueryClientProvider } from 'react-query';
import i18next from '@core/locales';
import { I18nextProvider } from 'react-i18next';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { ToastContainer } from 'react-toastify';
import { delayShowToast } from './utils/constants';
import 'react-toastify/dist/ReactToastify.css';
import { Auth0Provider } from 'components/Auth0';
import ReactGA from "react-ga4";

function App() {
  if (typeof window !== 'undefined') {
    injectStyle();
  }

  ReactGA.initialize("G-DWFXRMMMZB");

  const queryClient = new QueryClient();
  return (
    <div className="App">
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        }}
      >
        <QueryClientProvider client={queryClient}>
          <I18nextProvider i18n={i18next}>
            <Routes />
            <ToastContainer
              position="top-right"
              autoClose={delayShowToast}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </I18nextProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </div>
  );
}

export default App;
