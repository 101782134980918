// eslint-disable-next-line
import { queryFilter } from 'utils/proptypes';
import base from './baseApi';

const { urls, methods, execute } = base();
const getOrganizationByFilter = (queryFilter: queryFilter) => {
  const { page, limit, searchQuery } = queryFilter;
  const method = methods.GET;

  const url = `${urls.organization.organizations}/?page=${page}&limit=${limit}&searchQuery=${searchQuery}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getOrganizations = () => {
  const method = methods.GET;
  const url = urls.organization.organizations;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getOrganizationById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.organization.filter}/${id}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const createOrganization = (payload: { name: string }) => {
  const method = methods.POST;
  const url = `${urls.organization.organizations}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const updateOrganizationById = (payload: { id: any, name: string }) => {
  const method = methods.PUT;
  const url = `${urls.organization.organizations}/${payload.id}`;
  const response = execute(method, url, { authorization: true }, { name: payload.name });
  return response;
};

const updateStatusOrganizationById = (id: any, payload: { isActive: boolean }) => {
  const method = methods.PATCH;
  const url = `${urls.organization.organizations}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

export { getOrganizationByFilter, getOrganizationById, updateOrganizationById, getOrganizations, createOrganization, updateStatusOrganizationById };
