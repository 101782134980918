/* eslint-disable camelcase */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from "i18next-browser-languagedetector";
import languagePackageEng from './en.json';
import languagePackageFr from './fr.json';

const detectionOptions = {
  order: ['path'],
  lookupFromPathIndex: 0,
  // checkWhitelist: true
}

i18next.use(detector).use(initReactI18next).init({
  debug: true,
  detection: detectionOptions,
  fallbackLng: 'en',
  resources: {
    en: {
      translation: languagePackageEng,
    },
    fr: {
      translation: languagePackageFr,
    }
  },
});

export default i18next;
