// eslint-disable-next-line
export default {
  baseUrl: `${process.env.REACT_APP_BASE_API_URL}/v1`,
  user: {
    users: 'Users',
    getCurrentUser: 'Users/CurrentUser',
    filter: 'Users/filter',
    userControllerChangeEmailAddress: 'Users/ChangeEmailAddress',
    setRoles: 'Users/SetRoles',
    userOrganization: 'Users/Organization',
    changePassword: 'Users/change-password',
    getUserByVerifiedCode: 'Users/VerifiedCode',
    courseEnrollment: 'users/courses/enrollment',
    getMyDegrees: '/users/degrees/myDegree',
    getMyBillings: '/users/billings',
    degreesEnroll: 'users/degrees/enroll',
  },

  role: {
    Roles: 'Roles',
  },

  userRole: {
    userRoles: 'UserRoles',
  },

  organization: {
    organizations: 'organizations',
    filter: 'Organizations/Filter',
  },

  userOrganization: {
    userOrganizations: 'UserOrganizations',
  },

  health: 'Health',

  position: 'Positions',

  courses: {
    courses: 'courses/filter',
    coursesDetail: 'courses',
  },

  institutions: {
    institutions: 'institutions',
  },

  degreeCategories: {
    degreeCategories: 'degreeCategories',
  },

  degreeTypes: {
    degreeTypes: 'degreeTypes',
  },

  courseCategories: {
    courseCategories: 'courseCategories',
  },

  courseTypes: {
    courseTypes: 'courseTypes',
  },
  plans: 'plans',
  subscriptions: 'subscriptions',
  billings: 'billings',
  paymentWebhook: 'billings/payment-webhook',
  coursesEnroll: 'users/courses/enroll',
  coursesEnrollment: 'users/courses/enrollment',

  degrees: {
    degrees: 'degrees/filter',
    degreeDetail: 'degrees',
  },

  images: 'images',
};
