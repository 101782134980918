import { orgTypes } from 'utils/proptypes';
import HeaderTable from 'components/table/headerTable';
import PaginateTable from 'components/table/paginate';
import { HiPencilAlt, HiX, HiRefresh } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { getOrganizationByFilter, updateStatusOrganizationById } from 'api/organizationApi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { initOrganization } from 'utils/initData';
import SpinnerComponent from 'components/spinner';
import { apiStatus, BUTTON_COLOR, exportFileName, pageCount } from 'utils/constants';
import * as XLSX from 'xlsx';
import OrganizationModal from 'components/modal/organizationModal';
import { isEqual } from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import { toast } from 'react-toastify';
import ButtonIconWithText from 'components/button/buttonIconWithText';
import { messageErrors } from '../utils/utils';

const OrganizationPage = () => {
  const [t] = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEntities, setTotalEntities] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [targetOrganization, setTartgetOrganization] = useState<any>();
  const [organizations, setOrganizations] = useState([initOrganization]);
  const navigate = useNavigate();

  const handleAddOrganization = () => {
    setTartgetOrganization(initOrganization);
    setOpenModal(true);
    setIsEdit(false);
  };

  const handleClickEditOrganization = (org: orgTypes) => {
    setTartgetOrganization(org);
    setIsEdit(true);
    setOpenModal(true);
  };

  const onRowClickHandler = (org: orgTypes) => {
    navigate(`/organization-setting/${org.id}`);
  };

  const handleOnClickExport = () => {
    const name = exportFileName.ORGANIZATION;

    if (!isEqual(organizations[0], initOrganization)) {
      const exportedData = organizations.map((or: any) => {
        return {
          name: or.name,
        };
      });

      const wb = XLSX.utils.json_to_sheet(exportedData);
      const wbout = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wbout, wb);
      XLSX.writeFile(wbout, name);
    }
  };

  const handleActive = async (item: orgTypes) => {
    const { status } = await updateStatusOrganizationById(item.id, {
      isActive: !item.isActive,
    });
    status === apiStatus.SUCCESS && fetchOrganizations();
  };

  const getOrganizations = useMutation('getOrganizations', {
    mutationFn: getOrganizationByFilter,
    onSuccess: ({ data }) => {
      setOrganizations(data.entities);
      setTotalEntities(data.totalEntities);
    },
    onError: (error) => {
      setOrganizations([initOrganization]);
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const fetchOrganizations = () => {
    getOrganizations.mutate({ page: currentPage, limit: pageCount, searchQuery: searchValue });
  };

  const handleSearch = () => {
    getOrganizations.mutate({ page: 0, limit: pageCount, searchQuery: searchValue });
    setCurrentPage(0);
  };

  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <div className='w-full'>
        <HeaderTable
          placeholderSearch='Search for organizations'
          buttonName='Add Organization'
          handleAddClick={handleAddOrganization}
          handleOnClickExport={handleOnClickExport}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          fetchData={handleSearch}
        />
        {!isEqual(organizations[0], initOrganization) && totalEntities === 0 && <NoResult />}
        {!isEqual(organizations[0], initOrganization) && organizations.length > 0 && (
          <div className='overflow-x-auto relative'>
            <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
              <thead className='text-sm text-gray-600 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='py-3 px-4 w-1/2'>
                  {t('organizationPage.organization')}
                </th>
                <th scope='col' className='py-3 px-4'>
                  {t('organizationPage.status')}
                </th>
                <th scope='col' className='py-3 w-6' />
                <th scope='col' className='py-3 w-6' />
              </tr>
              </thead>
              <tbody>
              {organizations.map((item: orgTypes) => {
                return (
                  <tr
                    key={`${item.id}-list-organization`}
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600'
                  >
                    <td
                      onClick={() => onRowClickHandler(item)}
                      className='py-3 px-4 font-medium text-gray-900 dark:text-white'
                    >
                      {item.name}
                    </td>
                    <td className='py-3 px-4'>
                        {item.isActive ? (
                          <aside className='flex flex-row items-center'>
                            <span className='w-3 h-3 flex bg-green-400 rounded-lg mr-2 '/>
                            {t('organizationPage.active')}
                          </aside>

                        ) : (
                          <aside className='flex flex-row items-center'>
                          <span className='w-3 h-3 flex bg-red-500 rounded-lg mr-2 '/>
                            {t('organizationPage.inactive')}
                          </aside>
                        )}
                    </td>
                    <td className='py-3'>
                    <ButtonIconWithText
                        text={t('userManagementPage.edit')}
                        onClick={() => handleClickEditOrganization(item)}
                        Icon={HiPencilAlt}
                        color={BUTTON_COLOR.BLUE}
                      />
                    </td>
                    <td className='py-3 px-4'>
                      <ButtonIconWithText
                        text={
                          item.isActive ? (
                            <span>{t('organizationPage.deactivate')}</span>
                          ) : (
                            <span>{t('organizationPage.reactivate')}</span>
                          )
                        }
                        onClick={() => handleActive(item)}
                        Icon={item.isActive ? HiX : HiRefresh}
                        color={item.isActive ? BUTTON_COLOR.RED : BUTTON_COLOR.GRAY}
                      />
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </table>
            {getOrganizations.isLoading && <SpinnerComponent />}
            <PaginateTable setCurrentPage={setCurrentPage} currentPage={currentPage} totalEntities={totalEntities} />
          </div>
        )}
      </div>
      <OrganizationModal
        headerTitle={isEdit ? t('organizationPage.editOrganization') : t('organizationPage.addOrganization')}
        openModal={openModal}
        targetData={targetOrganization}
        isEdit={isEdit}
        setOpenModal={setOpenModal}
        successFunc={fetchOrganizations}
      />
    </>
  );
};

export default OrganizationPage;
