import LanguageSelect from 'components/commonComponent/languageSelect';
import { Dropdown, Button } from 'flowbite-react';

import './header.scss';
import { HiMenu } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { routePaths, userRoles } from 'utils/constants';
import { useAuth0 } from 'components/Auth0';

const logo = require('../../assets/image/svg/YAM-logo.svg').default;
const placeholderAvatar = require('../../assets/image/svg/placeholder-avatar.svg').default;

const Header = () => {
  const { logout, loginWithRedirect, isAuthenticated, user, userRole } = useAuth0();

  const [t] = useTranslation();

  const navigate = useNavigate();
  const redirectUri = (url: string) => {
    navigate(url);
  };

  // ** URL Vars
  const location = useLocation();
  const currentURL = location.pathname;

  const signUpFunction = () => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  };
  const handleClickSignUp = () => {
    setTimeout(signUpFunction, 100);
  };
  const handleClickSignIn = () => {
    setTimeout(loginWithRedirect(), 100);
  };

  const handleLogout = () => {
    setTimeout(logout({ returnTo: routePaths.HOME }), 100);
  };

  const renderRoute = (path: string, label: string) => {
    const isActive = currentURL.includes(path);
    return (
      <li>
        <a onClick={() => redirectUri(path)} className={`pointer-item p-3 ${isActive ? 'primary' : 'text-gray-900'}`}>
          {label}
        </a>
      </li>
    );
  };

  const renderAvatar = () => {
    return (
      <>
        {!user?.image?.url && !user?.firstName && <div className="avatar">JL</div>}
        {!user?.image?.url && user?.firstName && (
          <div className="avatar">
            {user?.firstName && user?.lastName
              ? `${user?.firstName.substring(0, 1)}${user?.lastName.substring(0, 1)}`
              : user?.firstName.substring(0, 2)}
          </div>
        )}
        {user?.image?.url && (
          <img
            className="w-10 h-10 overflow-hidden rounded-full w-full h-full object-cover"
            src={user?.image?.url || placeholderAvatar}
            alt="avatar"
          />
        )}
      </>
    );
  };

  return (
    <nav className="header-page w-full bg-white shadow-md z-50 fixed">
      <div className="max-w-8xl mx-auto flex items-center justify-between py-4 sm:px-8 px-4">
        <div className="lg:w-full flex gap-2 md:gap-6 lg:gap-12 items-center justify-between">

          <a className="pointer-item" onClick={() => redirectUri(routePaths.HOME)}>
            <img src={logo} alt="logo" />
          </a>
          {/*Nav Link*/}
          <div className="flex w-full justify-between items-center" id="navbar-default">
            <ul className="flex text-sm font-medium">
              {renderRoute('/courses', t('header.courses'))}
              {renderRoute('/degrees', t('header.degrees'))}
              {(userRole === userRoles.STANDARD_USER || userRole === userRoles.SUPER_ADMIN || userRole === userRoles.ORGANIZATION_ADMIN) && (
                  <>
                    {renderRoute('/my-learning', t('header.myLearning'))}
                  </>
              )}

              {( userRole === userRoles.SUPER_ADMIN || userRole === userRoles.ORGANIZATION_ADMIN) && (
                <>
                  <li>
                    <a className="pointer-item p-3 text-gray-900" onClick={() => window.location.replace(routePaths.REDIRECT_COURSE)}>{t('userProfile.studio')}</a>
                  </li>
                  <li>
                    <a className="pointer-item p-3 text-gray-900" onClick={() => window.location.replace(routePaths.REDIRECT_ANALYTICS)}>{t('userProfile.analytics')}</a>
                  </li>
                </>
              )}
              
            </ul>
            
            <div className="hidden lg:flex group-button-header gap-2">
              {!isAuthenticated ? (
                <>
                  <Button pill={true} outline={true} color="gray" onClick={handleClickSignUp}>
                    {t('loginPage.createAccount')}
                  </Button>
                  <Button pill={true} color="success" onClick={handleClickSignIn}>
                    {t('loginPage.signIn')}
                  </Button>
                </>
              ) : (
                <div className="flex flex-1 justify-end">
                  <div className="mr-2">{renderAvatar()}</div>
                  <div className="mt-2">
                    <Dropdown
                      arrowIcon={true}
                      inline={true}
                      label={
                        <span className="font-semibold">
                          {user?.firstName} {user?.lastName}
                        </span>
                      }
                    >
                      <Dropdown.Item onClick={() => redirectUri(routePaths.PROFILE_PAGE)}>{t('userProfile.myProfile')}</Dropdown.Item>
                      {/* <Dropdown.Item>{t('userProfile.accountSecurity')}</Dropdown.Item> */}
                      {/* <Dropdown.Item onClick={() => redirectUri(routePaths.MY_DEGREES)}>{t('userProfile.myDegrees')}</Dropdown.Item>
                      <Dropdown.Item>{t('userProfile.myCourses')}</Dropdown.Item> */}
                      <Dropdown.Item onClick={() => redirectUri(routePaths.MY_LEARNING)}>{t('userProfile.myLearning')}</Dropdown.Item> 
                      <Dropdown.Item onClick={() => redirectUri(routePaths.MY_PURCHASES)}>{t('userProfile.myPurchases')}</Dropdown.Item>
                      {/* <Dropdown.Item>{t('userProfile.mySchedule')}</Dropdown.Item> */}
                      {/* <Dropdown.Item>{t('userProfile.settings')}</Dropdown.Item> */}
                      {userRole === userRoles.SUPER_ADMIN && (
                        <>
                          <Dropdown.Divider />
                          <Dropdown.Item onClick={() => window.location.replace(routePaths.REDIRECT_COURSE)}>{t('userProfile.studio')}</Dropdown.Item>
                          <Dropdown.Item onClick={() => window.location.replace(routePaths.REDIRECT_ANALYTICS)}>{t('userProfile.analytics')}</Dropdown.Item>
                          <Dropdown.Item onClick={() => redirectUri(routePaths.USERS_PAGE)}>{t('userProfile.admin')}</Dropdown.Item>

                        </>
                      )}

                      {(userRole === userRoles.ORGANIZATION_ADMIN) && (
                          <>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => window.location.replace(routePaths.REDIRECT_COURSE)}>{t('userProfile.studio')}</Dropdown.Item>
                            <Dropdown.Item onClick={() => window.location.replace(routePaths.REDIRECT_ANALYTICS)}>{t('userProfile.analytics')}</Dropdown.Item>
                          </>
                      )}


                      <Dropdown.Divider />
                      <Dropdown.Item onClick={() => handleLogout()}>
                        <span className="text-red-500">{t('logout')}</span>
                      </Dropdown.Item>
                    </Dropdown>
                  </div>
                </div>
              )}
              <LanguageSelect id="header" />
            </div>
          </div>
        </div>

        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          {!isAuthenticated ? (
            <>
              <Dropdown arrowIcon={false} inline={true} label={<HiMenu className="w-7 h-7" />}>
                <Dropdown.Item onClick={handleClickSignUp}>{t('loginPage.createAccount')}</Dropdown.Item>
                <Dropdown.Item onClick={handleClickSignIn}>{t('loginPage.signIn')}</Dropdown.Item>
              </Dropdown>
            </>
          ) : (
            <div className="flex flex-1 justify-end">
              <div className="mr-2">{renderAvatar()}</div>
              <div className="mt-2">
                <Dropdown arrowIcon={false} inline={true} label={<HiMenu className="w-7 h-7" />}>
                  <Dropdown.Item onClick={() => redirectUri(routePaths.PROFILE_PAGE)}>{t('userProfile.myProfile')}</Dropdown.Item>
                  {/* <Dropdown.Item>{t('userProfile.accountSecurity')}</Dropdown.Item> */}
                  {/* <Dropdown.Item>{t('userProfile.myDegrees')}</Dropdown.Item>
                  <Dropdown.Item>{t('userProfile.myCourses')}</Dropdown.Item> */}
                  {/* <Dropdown.Item onClick={() => redirectUri(routePaths.MY_PURCHASES)}>{t('userProfile.myPurchases')}</Dropdown.Item> */}
                  {/* <Dropdown.Item>{t('userProfile.mySchedule')}</Dropdown.Item> */}
                  {/* <Dropdown.Item>{t('userProfile.settings')}</Dropdown.Item> */}
                  {userRole === userRoles.SUPER_ADMIN && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={() => redirectUri(routePaths.USERS_PAGE)}>{t('userProfile.admin')}</Dropdown.Item>
                    </>
                  )}
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => handleLogout()}>
                    <span className="text-red-500">{t('logout')}</span>
                  </Dropdown.Item>
                </Dropdown>
              </div>
            </div>
          )}
        </button>
      </div>
    </nav>
  );
};

export default Header;
