import { useAuth0 } from 'components/Auth0';
import { Dropdown } from 'flowbite-react';
import { routePaths, userRoles } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import './adminHeader.scss';
const HeaderAdmin = (props: any) => {
  const { children } = props;
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { user, userRole, logout } = useAuth0();

  const placeholderAvatar = require('../../assets/image/svg/placeholder-avatar.svg').default;
  const redirectUri = (url: string) => {
    navigate(url);
  };

  const handleLogout = () => {
    setTimeout(logout({ returnTo: routePaths.HOME }), 100);
  };

  const userDropDown = useMemo(() => {
    return (
      <div className="flex items-center ">
        <Dropdown
          arrowIcon={true}
          inline={true}
          label={
            <div>
              {!user?.image?.url && user?.firstName && (
                <div className="avatar">
                  {user?.firstName && user?.lastName
                    ? `${user?.firstName.substring(0, 1)}${user?.lastName.substring(0, 1)}`
                    : user?.firstName.substring(0, 2)}
                </div>
              )}
              {user?.image?.url && (
                <img
                  className="w-10 h-10 overflow-hidden rounded-full w-full h-full object-cover"
                  src={user?.image?.url || placeholderAvatar}
                  alt="avatar"
                />
              )}
            </div>
          }
        >
          <Dropdown.Item onClick={() => redirectUri(routePaths.PROFILE_PAGE)}>{t('userProfile.myProfile')}</Dropdown.Item>
          <Dropdown.Item>{t('userProfile.myDegrees')}</Dropdown.Item>
          <Dropdown.Item>{t('userProfile.myCourses')}</Dropdown.Item>
          {userRole === userRoles.SUPER_ADMIN && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => redirectUri(routePaths.USERS_PAGE)}>{t('userProfile.admin')}</Dropdown.Item>
            </>
          )}
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => handleLogout()}>
            <span className="text-red-500">{t('logout')}</span>
          </Dropdown.Item>
        </Dropdown>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return (
    <div className="w-full admin-header">
      <div className="flex sm:flex-row bg-white items-center justify-between p-4 flex-wrap">
        <div className=" 2xl:w-95% xl:w-95% lg:w-90% md:w-90% sm:w-90% pr-2">{children}</div>
        <div className="2xl:w-5% xl:w-5% lg:w-10% md:w-10% sm:w-10%">
          <div className="flex flex-1 justify-center">{userDropDown}</div>
        </div>
      </div>
    </div>
  );
};
export default HeaderAdmin;
