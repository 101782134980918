import { useEffect } from 'react';
import { Button } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routePaths } from '../../utils/constants';
import './welcome.scss';
import { updateFirstLogin } from '../../api/userApi';
import { userSelector } from '../../redux/selectors';

const Welcome = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { userInfo } = useSelector(userSelector);
  useEffect(() => {
    if (userInfo?.id) {
      if (userInfo.isFirstLogin) {
        updateFirstLogin();
      } else if (!userInfo.subscriptions || userInfo.subscriptions.length === 0) {
        navigate(routePaths.SUBSCRIPTION);
      } else {
        navigate(routePaths.HOME);
      }
    }
  }, [userInfo, navigate]);

  return (
    <div className="basic-layout">
      <div className="welcome-page overflow-y-auto flex w-full h-screen p-50 ">
        <div className="flex flex-col self-center m-auto items-center justify-center box">
          <h1 className="text-5xl font-semibold text-center mb-30 tracking-tight dark:text-white"> {t('welcome.title')}</h1>
          <p className="text-2xl mb-30 text-center">{t('welcome.subTitle')}</p>
          <p className="text-lg mb-30 text-center">{t('welcome.description')}</p>
          <Button className="custom-button-style" color="info" type="button" size="lg" onClick={() => navigate(routePaths.SUBSCRIPTION)}>
            {t('welcome.chooseAPath')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
