import { HiOutlineChat, HiChevronRight } from 'react-icons/hi';
import './courseItem.scss';
import { useNavigate } from 'react-router';
import { routePaths } from 'utils/constants';
import { useTranslation } from 'react-i18next';

const CourseItem = (props: any) => {  
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { course } = props;

  const handleOnClickViewCourseDetail = (id: any) => {
    navigate(`${routePaths.COURSES}/${id}`);
  };

  return (
    <div className="mb-2 pb-5">
      <div className="w-full flex mb-2 container-degree-courses">
        <div className="w-full md:w-1/6 flex justify-center items-center course-img">
          <img className="rounded-xl" src={course?.imageUrl} alt="" />
        </div>
        <div className="w-full md:w-4/6 p-2">
          <p className="sm:text-sm md:text-base lg:text-lg xl:text-xl font-medium tracking-tight text-gray-900 dark:text-white mb-3">{course?.title}</p>
          <div className="flex flex-wrap mb-4 summary-course">
            <span className="mr-2 text-xs">{course?.description}</span>
          </div>
        </div>
        <div className="w-full md:w-1/6 p-2">
          <div className="flex items-center bg-blue-50 py-1 px-2 rounded mr-3 instructor">
            <HiOutlineChat className="w-6 h-6 mr-1" />
            <span className="text-xs">{course?.pacing}-{t('common.paced')}</span>
          </div>
          <span
              onClick={() => handleOnClickViewCourseDetail(course?.id)}
              className="flex mt-5 items-center text-sm font-semibold primary rounded cursor-pointer"
            >
              {t('degree.courseItem.viewDetails')}
              <HiChevronRight className="w-5 h-5" />
            </span>
        </div>
      </div>
      <hr className="border-gray-300" />
    </div>
  );
};
export default CourseItem;
