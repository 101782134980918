import { openedxCookieKey } from "./constants";

export function setCookie(name: string, value: string, expireMinutes = 60 * 24) {
  const d = new Date();
  d.setTime(d.getTime() + expireMinutes * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  if(name === openedxCookieKey){
    document.cookie = `${name}=${value};Domain=yam-edu.com;${expires};Max-Age=1209600;path=/; SameSite=None; Secure`;
  }else {
    document.cookie = `${name}=${value};${expires};path=/`;
  }
  
}

export function deleteCookie(name: string) {
  if (name === openedxCookieKey) {
    document.cookie = `${name}=;Domain=yam-edu.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;Max-Age=0;path=/; SameSite=None; Secure`;
  } else {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}

export function getCookie(cookieName: string) {
  const name = `${cookieName}=`;
  const cookieArray = document.cookie.split(';');
  for (const element of cookieArray) {
    let cookie = element;
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
}
