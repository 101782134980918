import { useTranslation } from 'react-i18next';
import { TextInput, Tabs, Spinner } from 'flowbite-react';
import './myDegrees.scss';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQuery } from 'react-query';
import { degreesCount, routePaths } from 'utils/constants';
import { degreesAction } from 'redux/actions';
import { getMyDegrees } from 'api/userApi';
import { useState, useEffect } from 'react';
import { degreesSelector } from 'redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import DegreeItem from 'components/commonComponent/DegreeItem';
import { HiSearch } from 'react-icons/hi';
import FooterComponent from '../../components/layouts/footer';
import NoResult from 'components/commonComponent/noResult';

const MyDegrees = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { totalMyDegrees } = useSelector(degreesSelector);

  const [t] = useTranslation();
  const handleOpenDetail = (degreeId: string, name: string) => {
    navigate(`${routePaths.DEGREES}/${name.replace(/\s+/g, '-')}/${degreeId}`);
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [myDegreesData, setMyDegreesData] = useState([]);

  const { data, isLoading } = useQuery(['getMyDegrees', currentPage], () => getMyDegrees({
    page: currentPage,
    limit: degreesCount,

  }), {
    onSuccess: ({ data }) => {
      dispatch(degreesAction.setMyDegrees(data))
    }
  }

  );

  const fetchMoreDegrees = () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    const myDegrees = data?.data?.entities
    if (myDegrees?.length >= 0) {
      setMyDegreesData(state => {
        let result = [];

        if (currentPage === 0) {
          result = myDegrees;
        } else {
          result = [...state, ...myDegrees];
        }

        return result;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    return () => {
      setMyDegreesData([]);
    };
  }, []);

  return (
    <>

      <div className="myDegree grow relative">
        <div id="scrollableDiv">
          <InfiniteScroll
            // inverse
            dataLength={myDegreesData.length}
            next={fetchMoreDegrees}
            hasMore={myDegreesData.length < totalMyDegrees}
            scrollableTarget="scrollableDiv"
            scrollThreshold={0.8}
            loader={
              <></>
            }
          >
            <div className="bg-gray-900 title">
              <div className="max-w-8xl mx-auto sm:px-8 px-4">
                <div className="text-white flex justify-between content-center py-5">
                  <div className="flex content-center">
                    <p className="text-3xl">{t('userProfile.myDegrees')}</p>
                  </div>
                  <div className="sm:w-full md:w-1/2 lg:w-1/3 rounded-3xl search-input">
                    <TextInput
                      data-testid="test-search-input"
                      rightIcon={HiSearch}
                      id="base"
                      type="text"
                      sizing="md"
                      placeholder="Search"
                      //   onChange={debouncedResults}
                      autoFocus
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="max-w-8xl mx-auto grow py-4 sm:px-8 px-4 mh-scroll">
              <div className="w-full px-1">
                <Tabs.Group aria-label="Tabs with underline" style="underline" className="tab-group" id="tab-group">
                  <Tabs.Item title="All">

                    {myDegreesData.length > 0 ? (
                      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 sm:grid-cols-2 list-degree">

                        {myDegreesData.map((degree: any) => {
                          return (
                            <div key={degree.id} className="py-2">
                              <DegreeItem
                                key={degree.degree?.id}
                                name={degree.degree?.title}
                                imageUrl={degree?.degree?.image?.url}
                                id={degree.degree?.id}
                                hasProgress
                                handleClickOnCard={handleOpenDetail}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )
                      : (
                        !isLoading && (<div className='mh-noresult'><NoResult /></div>)
                      )}



                  </Tabs.Item>
                  <Tabs.Item title="In progress">
                    <div className='mh-noresult'><NoResult /></div>
                  </Tabs.Item>
                  <Tabs.Item title="Completed">
                    <div className='mh-noresult'><NoResult /></div>

                  </Tabs.Item>
                </Tabs.Group>
              </div>

            </div>
            {myDegreesData.length < totalMyDegrees ? (
              <div className="loading-scroll">
                <Spinner aria-label="spinner example" />
              </div>
            ) : (
              !isLoading && (<FooterComponent></FooterComponent>)
            )}
          </InfiniteScroll>

        </div>
      </div>

    </>
  );
};
export default MyDegrees;
