import { userType } from '../utils/proptypes';
import { openedxCookieKey, userRoles } from 'utils/constants';
import { getUserCurrentUser } from 'api/userApi';
import { useQuery } from 'react-query';
import { userAction } from '../redux/actions';
import { useDispatch } from 'react-redux';
import { setCookie } from 'utils/cookie';
import i18n from '../@core/locales/index';

const useUser = () => {
  const dispatch = useDispatch();

  const getUserRole = (data: userType): string => {
    const { roles } = data;
    if (roles && roles.length > 0) {
      const rolesName = roles?.map((item: any) => item.displayName);
      if (rolesName?.includes(userRoles.SUPER_ADMIN)) {
        return userRoles.SUPER_ADMIN;
      } else if (rolesName?.includes(userRoles.ORGANIZATION_ADMIN)) {
        return userRoles.ORGANIZATION_ADMIN;
      } else return userRoles.STANDARD_USER;
    } else {
      return userRoles.STANDARD_USER;
    }
  };

  const { refetch: fetchCurrentUser, isLoading: isLoadingUser } = useQuery('getCurrentUser', getUserCurrentUser, {
    enabled: false,
    retry: false,
    onSuccess: ({ data }) => {
      const userRole = getUserRole(data);
      dispatch(userAction.setUserInfo({ ...data, userRole }));
      const { language } = data;
      setCookie(openedxCookieKey, language);
      i18n.changeLanguage(language);
      localStorage.setItem('currentUser', JSON.stringify(data));
    },
  });

  return {
    fetchCurrentUser,
    isLoadingUser,
  };
};

export default useUser;
