import { useTranslation } from 'react-i18next';
import { HiOutlineUpload, HiX, HiOutlinePencil } from 'react-icons/hi';
import { acceptImageTypeFile } from 'utils/constants';
import { uploadImageTypes } from 'utils/proptypes';

const FileUpload = (props: uploadImageTypes) => {
  const { handleUploadImage, imgUrl, handleRemoveImage, isEdit } = props;
  const [t] = useTranslation();

  return (
    <div className="flex justify-center items-center w-full h-56 px-5 relative">
      {imgUrl !== '' && imgUrl !== null ? (
        <div className="rounded-lg border border-gray-300 relative w-full h-56">
          {!isEdit && (
            <span
              onClick={() => handleRemoveImage()}
              className="absolute rounded-sm top-0 right-0 bg-white/50 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200"
            >
              <HiX />
            </span>
          )}

          <img className="preview  w-full h-56 object-cover rounded-lg" src={imgUrl} alt="degree-image" />
          {isEdit && (
            <label htmlFor="dropzone-file">
              <div className="flex flex-col justify-center items-center cursor-pointer ">
                <div className="w-16 h-16 absolute top-0 bottom-0 left-0 right-0 m-auto	 upload-icon rounded-full flex justify-center items-center border border-2	 border-white">
                  <HiOutlinePencil className="h-6 w-6 text-white" />
                </div>
              </div>
              <input id="dropzone-file" type="file" accept={acceptImageTypeFile} onChange={e => handleUploadImage(e)} className="hidden" />
            </label>
          )}
        </div>
      ) : (
        <label
          htmlFor="dropzone-file"
          className="flex flex-col justify-center items-center w-full h-56 text-gray-600 bg-gray-100 focus:outline-none hover:bg-gray-200 focus:ring-4 focus:ring-gray-200 font-normal rounded-lg text-xs dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
        >
          <div className="flex flex-col justify-center items-center cursor-pointer ">
            <div className="w-16 h-16 upload-icon rounded-full flex justify-center items-center mb-5">
              <HiOutlineUpload className="h-6 w-6" />
            </div>
            <p>{t('uploadImage')}</p>
          </div>
          <input id="dropzone-file" type="file" accept={acceptImageTypeFile} onChange={e => handleUploadImage(e)} className="hidden" />
        </label>
      )}
    </div>
  );
};
export default FileUpload;
