// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import userSlice from './slice/userSlice';
import roleSlice from './slice/roleSlice';
import degreesSlice from './slice/degreesSlice';
import institutionSlice from './slice/institutionSlice';
import degreeCategoriesSlice from './slice/degreeCategoriesSlice';
import degreeTypesSlice from './slice/degreeTypesSlice';
import coursesSlice from './slice/coursesSlice';
import courseCategoriesSlice from './slice/courseCategoriesSlice';
import courseTypesSlice from './slice/courseTypesSlice';
import courseDetailSlice from './slice/courseDetailSlice';
import degreeDetailSlice from './slice/degreeDetailSlice';
import ratesSlice from './slice/ratesSlice';

const rootReducer = combineReducers({
  user: userSlice.reducer,
  role: roleSlice.reducer,
  degrees: degreesSlice.reducer,
  institutions: institutionSlice.reducer,
  degreeCategories: degreeCategoriesSlice.reducer,
  degreeTypes: degreeTypesSlice.reducer,
  courses: coursesSlice.reducer,
  courseCategories: courseCategoriesSlice.reducer,
  courseTypes: courseTypesSlice.reducer,
  courseDetail: courseDetailSlice.reducer,
  degreeDetail: degreeDetailSlice.reducer,
  rates: ratesSlice.reducer,
});

export default rootReducer;
