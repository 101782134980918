import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import { guardedRouteTypes } from 'utils/proptypes';
import { perimissionProfiles } from 'utils/permission';

const GuardedRoute = ({ path, userRole }: guardedRouteTypes) => {
  const userPermissionProfile = perimissionProfiles.find(profile => profile.type === userRole);

  const userIsAuthorized = userPermissionProfile?.permittedRoutes.find(route => route === path);

  return userIsAuthorized ? <Outlet /> : <Navigate to={userPermissionProfile?.defaultRedirectUrl || ''} />;
};

GuardedRoute.propTypes = {
  path: PropTypes.string.isRequired,
};

export default GuardedRoute;
