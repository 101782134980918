import base from './baseApi';

const getPositions = () => {
  const { urls, methods, execute } = base();
  const method = methods.GET;
  const url = `${urls.position}/?page=0&limit=0`;

  const response = execute(method, url, { authorization: true }, {});

  return response;
};

export { getPositions };
