import { Label } from 'flowbite-react';
import { Field } from 'formik';

type CustomCheckboxProps = {
  id: string;
  labelName: string;
  checked?: boolean;
  onChange: Function;
  formProps?: any;
  name: string;
};
const CustomCheckbox = (props: CustomCheckboxProps) => {
  const { id, labelName, checked, onChange, formProps, name } = props;
  return (
    <>
      <div className="flex items-center">
        <Field
          id={id}
          type="checkbox"
          as="input"
          value={''}
          checked={checked}
          name={name}
          onChange={(e: any) => onChange(e, formProps)}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <Label id={`label-${id}`} className="ml-2">
          {labelName}
        </Label>
      </div>
    </>
  );
};
export default CustomCheckbox;
