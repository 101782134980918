import { Button, Modal } from 'flowbite-react';
import _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userSelector } from 'redux/selectors';
import { routePaths } from 'utils/constants';
import './renewWarningModal.scss';
import CustomModalHeader from './customModalHeader';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getCourseEnrollment } from 'api/userApi';
import { useQuery } from 'react-query';

const RenewWarningModal = (props: any) => {
  const { openModal, setOpenModal, handleRenew } = props;
  const { t } = useTranslation();
  const { userInfo } = useSelector(userSelector);
  const navigate = useNavigate();
  const [countCourse, setCountCourse] = useState(0);
  const expiredDate = _.get(userInfo, 'subscriptions[0].expiredAt', null);
  const { data } = useQuery(['getCourseEnrollment'], () => getCourseEnrollment(), {
    staleTime: Infinity,
    onError: () => {
      setCountCourse(0);
    },
  });

  useEffect(() => {
    if (data) {
      const filleredIncludeInPlan = data?.data.filter((item: any) => !item.isPurchase && item.priceUSD !== 0);
      setCountCourse(filleredIncludeInPlan.length);
    }
  }, [data]);
  
  const handleChangeSubcription = () => {
    navigate(routePaths.SUBSCRIPTION);
  };

  const toggleHandler = () => {
    setOpenModal(!openModal);
  };

  const countDateDifference = (expiredDate: string) => {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    let dayLeft = 0;
    // Calculate the difference in milliseconds
    const timeBetwen = new Date(expiredDate).getTime() - new Date().getTime();
    if (timeBetwen > 0) {
      // Convert back to days and return
      dayLeft = Math.round(timeBetwen / ONE_DAY);
    }

    return dayLeft;
  };

  return (
    <Modal show={openModal} size="lg" popup={true}>
      <CustomModalHeader title="" toggle={() => toggleHandler()} />
      <div className="renew-warning-modal">
        <div className="renew-warning-text">
          <div>
            <span className="renew-warning-text-summary">
              {expiredDate
                ? `${t('renewModalWarning.title')} ${moment(new Date(expiredDate)).format('LL')}`
                : `${t('renewModalWarning.titleNonExpiredDate')}`}
            </span>
          </div>
          <div>
            <span className="renew-warning-text-content">
              <Trans
                i18nKey="renewModalWarning.contentText1"
                values={{ countDate: countDateDifference(expiredDate) }}
                components={{
                  bold: <strong />,
                  span: <span className="color-red" />,
                }}
              />
            </span>
          </div>
          <div>
            <span className="renew-warning-text-content">
              <Trans
                i18nKey="renewModalWarning.contentText2"
                values={{ countCourse: countCourse }}
                components={{
                  bold: <strong />,
                  span: <span />,
                }}
              />
            </span>
          </div>
        </div>

        <div className="center-horizontal flex flex-row">
          <Button pill={true} color="success" onClick={handleChangeSubcription} className="button-change custom-button-style">
            {t('myPurchasesPage.changeSubscription')}
          </Button>
          <Button pill={true} color="success" onClick={handleRenew} className="button-renew custom-button-style">
            {t('myPurchasesPage.renewSubscription')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RenewWarningModal;
