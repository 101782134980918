import { Modal } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { degreeType, optionType, roleType } from 'utils/proptypes';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { createUser, updateUserById } from 'api/userApi';
import CustomModalHeader from './customModalHeader';
import FormComponent from '../form/form';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import GroupButton from 'components/button/groupButton';
import CustomSelect from 'components/commonComponent/customSelect';

type userOrganizationModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  targetData: any,
  headerTitle: string,
  organizationId?: string,
  successFunc: Function,
  isEdit: boolean,
  isEditSuperAdmin?: boolean,
  positionOptions?: any,
  listRole: roleType[],
  listDegree?: degreeType[],
};
const UserModal = (props: userOrganizationModalProps) => {
  const {
    openModal,
    setOpenModal,
    targetData,
    organizationId,
    successFunc,
    headerTitle,
    isEdit,
    isEditSuperAdmin = false,
    listRole,
    listDegree,
  } = props;

  const [t] = useTranslation();

  const ValidateSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(1, t('userManagementPage.nameLengthMin'))
      .max(100, t('userManagementPage.nameLengthMax'))
      .nullable()
      .required(t('userManagementPage.requiredField')),
      lastName: Yup.string()
      .min(1, t('userManagementPage.nameLengthMin'))
      .max(100, t('userManagementPage.nameLengthMax'))
      .nullable()
      .required(t('userManagementPage.requiredField')),
    emailAddress: Yup.string().email(t('userManagementPage.invalidEmail')).required(t('userManagementPage.requiredField')),
    roleIds: Yup.array().required(t('userManagementPage.requiredField')).min(1, t('userManagementPage.requiredField')),
  });

  type userType = {
    firstName: string,
    lastName: string,
    emailAddress: string,
    positionId?: string,
    roleIds: string[],
  };

  const initUser: userType = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    roleIds: [],
  };

  const [user, setUser] = useState(initUser);

  const roleOptions = listRole?.map((r: roleType) => ({ value: r.id, label: r.displayName }));
  const degreeOptions = listDegree?.map((r: degreeType) => ({ value: r.id, label: r.title }));

  const initOption: optionType = { value: '', label: '' };

  const [selectedOption, setSelectedOption] = useState([initOption]);
  const [selectedDegreeOptions, setSelectedDegreeOptions] = useState([initOption]);

  const handleAddAndUpdateUser = async (userPayload: any) => {
    if (isEdit) {
      await updateUserById(targetData.id, userPayload);
    } else {
      await createUser(userPayload);
    }
  };

  const mutation = useMutation('create-update-usesOrg', { mutationFn: handleAddAndUpdateUser });

  const handleSubmit = (value: any, props: any) => {
    if (value.positionId === '') value.positionId = null;
    const payload = {
      ...value,
      firstName: isEmpty(value.firstName) ? null : value.firstName,
      lastName: isEmpty(value.lastName) ? null : value.lastName,
    };
    mutation.mutate(
      { ...payload, emailVerified: true, organizationId },
      {
        onSuccess: () => {
          setOpenModal(!openModal);
          const message: string = isEdit ? t('userManagementPage.editSuccessMessage') : t('userManagementPage.createSuccessMessage');
          toast.success(message);
          props.resetForm();
          setSelectedOption([initOption]);
          successFunc();
        },
        onError: async (error: any) => {
          const message: string = `${error.response.data.errors?.[0].detail}`;
          toast.error(message);
        },
      },
    );
  };

  const cancelHandler = (props: any) => {
    setOpenModal(!openModal);
    setSelectedOption([initOption]);
    props.resetForm();
  };

  useEffect(() => {
    if (isEdit) {
      const roleIds = targetData.roles.map((role: roleType) => role.id);
      const selectedRole = roleOptions?.filter((role: optionType) => roleIds?.find((roleId: string) => role.value === roleId)) || [initOption];
      setSelectedOption(selectedRole);

      const degreeIds = targetData.degrees.map((degree: degreeType) => degree.id);
      const selectedDegrees = degreeOptions?.filter((degree: optionType) => degreeIds?.find((degreeId: string) => degree.value === degreeId)) || [
        initOption,
      ];

      setSelectedDegreeOptions(selectedDegrees);
      setUser({
        firstName: targetData.firstName,
        lastName: targetData.lastName,
        emailAddress: targetData.emailAddress,
        positionId: targetData.position ? targetData.position.id : '',
        roleIds,
      });
    } else {
      setUser({
        ...initUser,
      });
      setSelectedOption([initOption]);
      setSelectedDegreeOptions([initOption]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetData, openModal]);

  const handleChange = (options: any, props: any) => {
    const noDefaultOptions = options.filter((option: optionType) => option.value !== '' && option.label !== '');
    setSelectedOption(noDefaultOptions);
    const roleIds = noDefaultOptions.map((option: optionType) => option.value);
    props.setFieldValue('roleIds', roleIds);
  };

  const handleSelectDegree = (options: any, props: any) => {
    const noDefaultOptions = options.filter((option: optionType) => option.value !== '' && option.label !== '');
    setSelectedDegreeOptions(noDefaultOptions);
    const degreeIds = noDefaultOptions.map((option: optionType) => option.value);
    props.setFieldValue('degreeIds', degreeIds);
  };

  return (
    <Modal className="admin-modal" show={openModal} size="2xl" popup={true}>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={user}
        validationSchema={ValidateSchema}
        className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
      >
        {props => (
          <Form>
            <CustomModalHeader title={headerTitle} toggle={() => cancelHandler(props)} />
            <Modal.Body>
              <div className="flex flex-col px-4 gap-4">
                <FormComponent label={`${t('userProfilePage.firstName')} *`} id="firstName" type="text" placeholder="Jese Leos" name="firstName" />
                <FormComponent label={`${t('userProfilePage.lastName')} *`} id="lastName" type="text" placeholder="Leos" name="lastName" />
                <FormComponent
                  label={`${t('userManagementPage.email')} *`}
                  id="emailAddress"
                  type="text"
                  placeholder="example@email.com"
                  name="emailAddress"
                  disabled={isEdit}
                />
                <CustomSelect
                  formProps={props}
                  onChange={handleChange}
                  options={roleOptions}
                  isMulti={true}
                  value={selectedOption}
                  id="roleIds"
                  selectName="roleIds"
                  isDisabled={isEditSuperAdmin}
                  labelName={`${t('userManagementPage.role')} *`}
                />
                <CustomSelect
                  formProps={props}
                  onChange={handleSelectDegree}
                  options={degreeOptions}
                  isMulti={true}
                  value={selectedDegreeOptions}
                  id="degreeIds"
                  selectName="degreeIds"
                  isDisabled={isEditSuperAdmin}
                  labelName={`${t('userManagementPage.degreeProgram')}`}
                />
                <GroupButton
                  className="items-center justify-center pt-1 pb-2"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => cancelHandler(props),
                    },
                    {
                      type: 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                      isLoading: mutation.isLoading,
                    },
                  ]}
                />
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default UserModal;
