import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  degreeTypes: [],
};

const setDegreeTypes = (state: any, action: any) => {
  state.degreeTypes = action.payload;
};

export const degreeTypesSlice = createSlice({
  name: 'degreeTypes',
  initialState,
  reducers: {
    setDegreeTypes,
  },
});
export default degreeTypesSlice;
