/**
* Last Modified: June 14, 2023
* Last Modified By: Sheriff Issaka
* Modification Description: Add file header
* Description: Handles the subscription process and displays subscription plans
**/

import { useEffect, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { BiArrowBack } from 'react-icons/bi';
import { getPlans, createSubscriptions, getCalculatePrice, getExchangeRates } from '../../api/subscriptionApi';
import { CURRENCY_OPTIONS, INTERVAL, routePaths, SUBSCRIPTION_TYPE } from '../../utils/constants';
import { ratesSelector, userSelector } from '../../redux/selectors';
import './subscription.scss';
import { planType } from 'utils/proptypes';
import PaymentFlutterWaveButton from '../../components/payment/flutterwave/paymentFlutterwaveButton';
import { Button, Modal, Dropdown, Spinner } from 'flowbite-react';
import _ from 'lodash';
import useUser from 'hooks/useUser';
import { formatPrice, getLevelSubscription, compareDate, getPacing } from 'utils/utils';
import SpinnerComponent from 'components/spinner';
import { getCourseEnrollment } from 'api/userApi';
import moment from 'moment';
import { ratesAction } from 'redux/actions';

const Subscription = () => {
  const { fetchCurrentUser } = useUser();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [isOpenUpgradeModal, setIsOpenUpgradeModal] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [isOpenDowngradeModal, setIsOpenDowngradeModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [enableCalculatePrice, setEnableCalculatePrice] = useState(false);
  const [txRef, setTxRef] = useState<any>(null);
  const [bestChoiceIndex, setBestChoiceIndex] = useState(1);
  const [plans, setPlans] = useState<any[]>([]);
  const [courseAccessPriceUSD, setCourseAccessPriceUSD] = useState<number>(0);
  const [selfPacedPriceUSD, setSelfPacedPriceUSD] = useState<number>(0);
  const [instructorPacedPriceUSD, setInstructorPacedPriceUSD] = useState<number>(0);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [isLoadingCurrency, setIsLoadingCurrency] = useState(false);
  const [isLargePrice, setIsLargePrice] = useState(false);
  const [isExchangeratesError, setExchangeratesError] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(CURRENCY_OPTIONS.find(item => item.value === 'USD'));
  const [chooseSub, setChooseSub] = useState(false);
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const { userInfo } = useSelector(userSelector);
  const { rates } = useSelector(ratesSelector);

  const languageCode = i18n.language;

  const [currency, setCurrency] = useState<any>({
    COURSE_ACCESS: {
      symbol: '$',
      currency: 'USD',
      price: 0,
    },
    SELF_PACED_DEGREE_PROGRAM: {
      symbol: '$',
      currency: 'USD',
      price: 0,
    },
    INSTRUCTOR_PACED_DEGREE_PROGRAM: {
      symbol: '$',
      currency: 'USD',
      price: 0,
    },
  });

  const defaultPlan = {
    name: '',
    amount: '',
    currency: '',
    interval: '',
    duration: '',
    id: null,
  };

  const currentPlan = _.get(userInfo, 'subscriptions[0].plan', defaultPlan);
  const { type: typeCurrentPlan } = currentPlan;

  const currentSubscription = _.get(userInfo, 'subscriptions[0]');

  const paymentDate = currentSubscription?.startAt ? moment(currentSubscription?.startAt, 'YYYY-MM-DD').format('MM/DD/YYYY') : '';
  const expiredDate = currentSubscription?.expiredAt ? moment(currentSubscription?.expiredAt, 'YYYY-MM-DD').format('MM/DD/YYYY') : '';
  const { refetch: fetchPlans } = useQuery('getPlans', () => getPlans(), {
    enabled: false,
    onSuccess: ({ data }) => {
      setPlans(data.entities);
      setBestChoiceIndex(1);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  const { isLoading } = useQuery(['getExchangeRates'], () => getExchangeRates(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: data => {
      setExchangeratesError(false);
      dispatch(ratesAction.setRates(data.data.conversion_rates));
    },
    onError: () => {
      setExchangeratesError(true);
    },
  });

  const { data: priceChange, isLoading: isLoadingPriceChangce } = useQuery(
    ['getCalculatePrice', selectedPlan, currentPlan],
    () => getCalculatePrice({ subcriptionId: currentSubscription?.id, planId: selectedPlan?.id }),
    {
      enabled: enableCalculatePrice,
      staleTime: Infinity,
    },
  );

  const { data: coursesEnrollment } = useQuery(['getCourseEnrollment'], () => getCourseEnrollment(), {
    staleTime: Infinity,
  });

  const getDisplayPlanType = (type: any) => {
    if (!type) {
      return '';
    }

    if (type.includes(SUBSCRIPTION_TYPE.FREE)) {
      return t('subscription.plan.free');
    }
    if (type.includes(SUBSCRIPTION_TYPE.COURSE_ACCESS)) {
      return t('subscription.plan.courseAccess');
    }
    if (type.includes(SUBSCRIPTION_TYPE.SELF_PACED_DEGREE_PROGRAM)) {
      return t('subscription.plan.selfPaced');
    }
    if (type.includes(SUBSCRIPTION_TYPE.INSTRUCTOR_PACED_DEGREE_PROGRAM)) {
      return t('subscription.plan.instructorPaced');
    }

    return '';
  };

  const getDisplayInterVal = (interval: any) => {
    if (!interval) {
      return '';
    }
    interval = interval.toLowerCase();

    if (interval.startsWith(INTERVAL.MONTH)) {
      return INTERVAL.MONTH;
    }

    if (interval.startsWith(INTERVAL.YEAR)) {
      return INTERVAL.YEAR;
    }
    return '';
  };

  const handleOpenModalPriceChange = (plan: any, txRefDateTime: number, isCurrentPlanExpired: boolean) => {
    const currentLevel: any = getLevelSubscription(typeCurrentPlan);
    const nextLevel: any = getLevelSubscription(plan.type);
    setSelectedPlan(plan);
    setTxRef(txRefDateTime);
    if (currentLevel > nextLevel) {
      if (isCurrentPlanExpired) {
        setIsOpenDowngradeModal(true);
      } else {
        setEnableCalculatePrice(false);
        setIsOpenWarningModal(true);
      }

      // }
    } else {
      setEnableCalculatePrice(true);
      setIsOpenUpgradeModal(true);
    }
  };

  const handleSubscription = async (plan: any) => {
    try {
      setChooseSub(true);
      setIsLoadingUser(true);
      if (plan.type !== SUBSCRIPTION_TYPE.FREE) {
        await fetchCurrentUser();
        if (!userInfo?.firstName) {
          navigate(routePaths.PROFILE_PAGE);
        } else {
          const message: string = t('subscription.createdSuccess');
          toast.success(message);
          setTimeout(() => {
            window.location.href = routePaths.MY_PURCHASES;
          }, 1000);
        }
      } else {
        const result = await createSubscriptions({ userId: userInfo.id, planId: plan?.id });
        if (result.status === 201) {
          if (!userInfo?.firstName) {
            navigate(routePaths.PROFILE_PAGE);
          } else {
            await fetchCurrentUser();
            const message: string = t('subscription.createdSuccess');
            toast.success(message);
            setTimeout(() => {
              navigate(routePaths.HOME);
              navigate(-1);
            }, 1000);
          }
        } else {
          const message: string = t('subscription.createdFail');
          toast.error(message);
        }
      }
    } catch (e) {
      setIsLoadingUser(false);
      const message: string = t('subscription.createdFail');
      toast.error(message);
    }
  };

  useEffect(() => {
    if (!userInfo?.id) {
      navigate(routePaths.LOGIN_PAGE);
    } else if (userInfo?.subscriptions?.length > 0 && !userInfo?.firstName) {
      navigate(routePaths.PROFILE_PAGE);
    } else {
      fetchPlans();
    }
  }, [userInfo, navigate, fetchPlans, t]);

  useEffect(() => {
    const courseAccessPrice = plans.find(plan => plan.type === SUBSCRIPTION_TYPE.COURSE_ACCESS)?.priceUSD / 100;
    const selfPacedPrice = plans.find(plan => plan.type === SUBSCRIPTION_TYPE.SELF_PACED_DEGREE_PROGRAM)?.priceUSD / 100;
    const instructorPacedPrice = plans.find(plan => plan.type === SUBSCRIPTION_TYPE.INSTRUCTOR_PACED_DEGREE_PROGRAM)?.priceUSD / 100;

    setCourseAccessPriceUSD(courseAccessPrice);
    setSelfPacedPriceUSD(selfPacedPrice);
    setInstructorPacedPriceUSD(instructorPacedPrice);

    setCurrency({
      COURSE_ACCESS: { ...currency.COURSE_ACCESS, price: courseAccessPrice },
      SELF_PACED_DEGREE_PROGRAM: { ...currency.SELF_PACED_DEGREE_PROGRAM, price: selfPacedPrice },
      INSTRUCTOR_PACED_DEGREE_PROGRAM: { ...currency.INSTRUCTOR_PACED_DEGREE_PROGRAM, price: instructorPacedPrice },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  const handleChangeCurrency = async (selectedCurrency: any) => {
    if (isLoading) {
      setIsLoadingCurrency(true);
    } else {
      setIsLoadingCurrency(false);
    }
    setSelectedCurrency(selectedCurrency);

    if (!_.isEmpty(rates)) {
      const convertCourseAccessPrice = courseAccessPriceUSD * rates[`${selectedCurrency?.value}`];
      const convertSelfPacedPrice = selfPacedPriceUSD * rates[`${selectedCurrency?.value}`];
      const convertInstructorPacedPrice = instructorPacedPriceUSD * rates[`${selectedCurrency?.value}`];

      setCurrency({
        COURSE_ACCESS: {
          symbol: selectedCurrency.symbol,
          currency: selectedCurrency?.value,
          price: convertCourseAccessPrice,
        },
        SELF_PACED_DEGREE_PROGRAM: {
          symbol: selectedCurrency.symbol,
          currency: selectedCurrency?.value,
          price: convertSelfPacedPrice,
        },
        INSTRUCTOR_PACED_DEGREE_PROGRAM: {
          symbol: selectedCurrency.symbol,
          currency: selectedCurrency?.value,
          price: convertInstructorPacedPrice,
        },
      });
    }
  };

  useEffect(() => {
    const hasLargeValue = Object.values(currency).find((item: any) => item.price >= 100000);
    if (hasLargeValue) {
      setIsLargePrice(true);
    } else setIsLargePrice(false);
  }, [currency]);

  const renderPriceLabel = (symbol: string, price: number, currency: string) => {
    return (
      <div className={isLargePrice ? 'plan-currencies-small' : 'plan-currencies'}>
        <sup className="plan-currency">{symbol}</sup>
        <span className="plan-amount">{formatPrice(price, currency, languageCode)}</span>
      </div>
    );
  };

  const renderDropdownCurrency = (isError: boolean, currencyItem: any, type: any) => {
    if (isError) {
      return renderPriceLabel(currencyItem?.symbol, currencyItem?.price, currencyItem?.currency);
    }

    return (
      <Dropdown
        className="price-dropdown"
        arrowIcon={true}
        inline={true}
        placement="bottom"
        label={renderPriceLabel(currencyItem?.symbol, currencyItem?.price, currencyItem?.currency)}
      >
        <div className="h-48 w-24s dropdown-item">
          {CURRENCY_OPTIONS.map((item, index) => (
            <Dropdown.Item key={`${type}-${index}`} onClick={() => handleChangeCurrency(item)}>
              {item.label}
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown>
    );
  };
  const renderButton = (txRefDateTime: number, plan: any, isCurrentPlan: boolean, index: number) => {
    const currentLevel: any = getLevelSubscription(typeCurrentPlan);
    const nextLevel: any = getLevelSubscription(plan.type);
    const isCurrentPlanExpired = compareDate(currentSubscription?.expiredAt, new Date());

    // free subscription or upgrade subscription is expired
    if (typeCurrentPlan === SUBSCRIPTION_TYPE.FREE || (isCurrentPlanExpired && currentLevel < nextLevel) || currentPlan?.id === null) {
      return (
        <div className={classnames('plan-action', { free: index === 0 && index !== bestChoiceIndex, active: index === bestChoiceIndex })}>
          <PaymentFlutterWaveButton
            planId={plan.id}
            txRef={`my-yam-subscription-${plan.type}-${txRefDateTime}`}
            amount={currency[`${plan?.type}`]?.price}
            currency={currency[`${plan?.type}`]?.currency}
            label={`${t('subscription.plan.startWith')} ${getDisplayPlanType(plan.type)}`}
            callback={() => handleSubscription(plan)}
            isCurrentPlan={isCurrentPlan}
            setChooseSub={setChooseSub}
          />
        </div>
      );
    }
    // show modal downgrade or upgrade when subscription is not expired
    else {
      return (
        <div className={classnames('plan-action', { free: index === 0 && index !== bestChoiceIndex, active: index === bestChoiceIndex })}>
          <Button className="custom-button-style" disabled={isCurrentPlan} onClick={() => handleOpenModalPriceChange(plan, txRefDateTime, isCurrentPlanExpired)}>
            {isCurrentPlan ? `${t('subscription.plan.currentPlan')}` : `${t('subscription.plan.startWith')} ${getDisplayPlanType(plan.type)}`}
          </Button>
        </div>
      );
    }
  };
  const renderSubscriptionItems = useMemo(() => {
    if (plans && plans.length > 0) {
      const txRefDateTime = new Date().getTime();
      return plans.map((plan: planType, index) => {
        if (index > 3) {
          return null;
        }
        const isCurrentPlan = currentPlan?.id === plan.id;
        return (
          <div
            key={`plan_item_${index}`}
            className={classnames(
              'plan-item m-0 md:m-4',
              {
                active: index === bestChoiceIndex,
              },
              `${isCurrentPlan ? 'disable-plan' : ''}`,
            )}
          >
            {index === bestChoiceIndex && <div className="plan-item-header">{t('subscription.plan.bestChoiceForYou')}</div>}
            <div className="plan-item-body">
              <div className="plan-title leading-tight">{plan.name}</div>
              {plan.priceUSD === 0 && <div className="plan-currencies">FREE</div>}
              {plan.priceUSD > 0 && (
                <div className="relative">
                  {!isLoadingCurrency ? (
                    renderDropdownCurrency(isExchangeratesError, currency[`${plan?.type}`], plan?.type)
                  ) : (
                    <div className="loading-currency">
                      <Spinner size="md" />
                    </div>
                  )}
                </div>
              )}

              <div className="plan-interval">{plan.interval ? `per ${getDisplayInterVal(plan.interval)}` : ''}</div>

              {renderButton(txRefDateTime, plan, isCurrentPlan, index)}

              <div className="plan-description" dangerouslySetInnerHTML={{ __html: plan.description }} />
            </div>
          </div>
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans, chooseSub, currency, isLoadingCurrency, rates, isLargePrice]);

  const numberOfCourseEnroll = (enrollCourse: Array<any>, plan: any) => {
    let courseEnroll = enrollCourse.filter((item: any) => !item.isPurchase && item.priceUSD !== 0);
    const pacing = getPacing(plan.type);
    if (pacing) {
      courseEnroll = courseEnroll.filter(item => item.pacing !== pacing);
    }
    return courseEnroll.length;
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <div className="w-full relative overflow-auto">
        {!isLoadingUser && !isLoading ? (
          <>
            {isLoadingPriceChangce && (
              <div role="status" className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
                <Spinner />
              </div>
            )}
            <div className={`${isLoadingPriceChangce ? 'opacity-25' : ''} subscription-page overflow-auto w-full h-screen p-5 lg:p-50  `}>
              <div className="flex flex-col self-center items-center justify-center box w-full">
                {userInfo.subscriptions && userInfo.subscriptions.length > 0 && (
                  <div className="back flex justify-start items-center w-full pl-5 cursor-pointer mb-10 mt-10" onClick={() => navigate(-1)}>
                    <BiArrowBack /> <div className="pl-1">{t('back')}</div>
                  </div>
                )}
                <h1 className="subscription-title text-3xl lg:text-5xl font-semibold text-center mb-30 tracking-tight dark:text-white">
                  {' '}
                  {t('subscription.title')}
                </h1>
                <p className="subscription-description text-lg mb-30 text-center w-full">{t('subscription.description')}</p>
                <div className="relative g mb-30 ">
                  {!isExchangeratesError ? (
                    <Dropdown
                      className="price-dropdown"
                      arrowIcon={true}
                      inline={true}
                      placement="bottom"
                      label={
                        <div>
                          <Trans
                            i18nKey="subscription.selectPaymentCurrency"
                            values={{ currency: selectedCurrency?.value }}
                            components={{
                              italic: <i />,
                              bold: <strong />,
                              span: <span className="text-gray-400 ml-1" />,
                            }}
                          />
                        </div>
                      }
                    >
                      <div className="h-48 w-24s dropdown-item">
                        {CURRENCY_OPTIONS.map((item, index) => (
                          <Dropdown.Item key={index} onClick={() => handleChangeCurrency(item)}>
                            {item.label}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Dropdown>
                  ) : (
                    t('subscription.exChangeError')
                  )}
                </div>
                <div className="plans flex-wrap">{renderSubscriptionItems}</div>
              </div>
              {priceChange !== undefined && isOpenUpgradeModal && (
                <Modal show={isOpenUpgradeModal} size="2xl" popup={true} onClose={() => setIsOpenUpgradeModal(false)} className="modal-price-change">
                  <Modal.Header className="modal-header">
                    <div className="">{t('subscription.newSubscriptionPrice')}</div>
                  </Modal.Header>
                  <Modal.Body className="modal-body">
                    <>
                      <div>
                        <Trans
                          i18nKey="subscription.upgradeSubscriptionAlert"
                          values={{
                            selectedPlan: selectedPlan?.name,
                            priceMore: formatPrice(
                              (priceChange?.data.priceMore * rates[`${selectedCurrency?.value}`]) / 100,
                              selectedCurrency?.value || 'USD',
                              languageCode,
                            ),
                            currencySymboy: currency[`${selectedPlan?.type}`]?.symbol,
                          }}
                          components={{
                            italic: <i />,
                            bold: <strong />,
                            span: <span className="cursor-pointer font-semibold" />,
                          }}
                        />
                      </div>
                      <div className="price-overview mt-5">
                        <div className="old-plan flex justify-between">
                          <div>
                            <span>{t('subscription.oldPlan')}</span>
                            <Trans
                              i18nKey="subscription.paymentOn"
                              values={{ paymentDate: paymentDate }}
                              components={{
                                italic: <i />,
                                bold: <strong />,
                                span: <span className="text-gray-400 ml-1" />,
                              }}
                            />
                          </div>
                          <span>
                            {currency[`${selectedPlan?.type}`]?.symbol}
                            {formatPrice(
                              (currentPlan.priceUSD * rates[`${selectedCurrency?.value}`]) / 100,
                              selectedCurrency?.value || 'USD',
                              languageCode,
                            )}
                          </span>
                        </div>
                        <div className="row flex justify-between">
                          <div>
                            <span>{t('subscription.newPlan')}</span>
                            <Trans
                              i18nKey="subscription.newMonthlyPrice"
                              values={{ expiredDate: expiredDate }}
                              components={{
                                italic: <i />,
                                bold: <strong />,
                                span: <span className="text-gray-400 ml-1" />,
                              }}
                            />
                          </div>
                          <span className="flex items-center">
                            {currency[`${selectedPlan?.type}`]?.symbol}
                            {formatPrice(
                              (selectedPlan?.priceUSD * rates[`${selectedCurrency?.value}`]) / 100,
                              selectedCurrency?.value || 'USD',
                              languageCode,
                            )}
                          </span>
                        </div>
                        <div className="row flex justify-between">
                          <span className="flex items-center">{t('subscription.dayRemaining')}</span>
                          <span className="flex items-center">{priceChange?.data.dayRemain}</span>
                        </div>
                        <div className="row flex justify-between">
                          <span className="flex items-center">{t('subscription.newPlanPriceForRemaingDays')}</span>
                          <span className="flex items-center">
                            {currency[`${selectedPlan?.type}`]?.symbol}
                            {formatPrice(
                              (priceChange?.data.priceSubs * rates[`${selectedCurrency?.value}`]) / 100,
                              selectedCurrency?.value || 'USD',
                              languageCode,
                            )}
                          </span>
                        </div>
                        <div className="row flex justify-between">
                          <span className="flex items-center">{t('subscription.amountRemaining')}</span>
                          <span className="flex items-center">
                            {currency[`${selectedPlan?.type}`]?.symbol}
                            {formatPrice(
                              (priceChange?.data.priceRemain * rates[`${selectedCurrency?.value}`]) / 100,
                              selectedCurrency?.value || 'USD',
                              languageCode,
                            )}
                          </span>
                        </div>

                        <div className="row flex justify-between last">
                          <span className="flex items-center ">{t('subscription.total')}</span>
                          <span className="font-bold flex items-center">
                            {currency[`${selectedPlan?.type}`]?.symbol}
                            {formatPrice(
                              (priceChange?.data.priceMore * rates[`${selectedCurrency?.value}`]) / 100,
                              selectedCurrency?.value || 'USD',
                              languageCode,
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="mt-5">
                        <div>
                          <Trans
                            i18nKey="subscription.notification"
                            values={{
                              price: formatPrice(
                                (selectedPlan?.priceUSD * rates[`${selectedCurrency?.value}`]) / 100,
                                selectedCurrency?.value || 'USD',
                                languageCode,
                              ),
                              expiredDate: expiredDate,
                              currencySymboy: currency[`${selectedPlan?.type}`]?.symbol,
                            }}
                            components={{
                              italic: <i />,
                              bold: <strong />,
                              span: <span className="font-semibold" />,
                            }}
                          />
                        </div>
                        <div className="flex justify-center mt-5">
                          <Button
                              pill={true} color="success"
                              onClick={() => setIsOpenUpgradeModal(false)}
                              className="button-cancel mr-5 custom-button-style"
                          >
                            {' '}
                            {t('button.cancel')}
                          </Button>
                          <div className="button-paynow">
                            <PaymentFlutterWaveButton
                              planId={selectedPlan?.id}
                              txRef={`my-yam-subscription-${selectedPlan?.type}-${txRef}`}
                              amount={(priceChange?.data.priceMore * rates[`${selectedCurrency?.value}`]) / 100}
                              currency={selectedCurrency?.value}
                              label={t('button.payNow')}
                              callback={() => handleSubscription(selectedPlan)}
                              setChooseSub={setChooseSub}
                              setIsOpenWarningModal={setIsOpenWarningModal}
                              setIsOpenUpgradeModal={setIsOpenUpgradeModal}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  </Modal.Body>
                </Modal>
              )}
              {coursesEnrollment && selectedPlan && (
                <Modal show={isOpenWarningModal} size="2xl" popup={true} onClose={() => setIsOpenWarningModal(false)} className="modal-price-change">
                  <Modal.Header className="modal-header">
                    <div className="">{t('subscription.changePlan')}</div>
                  </Modal.Header>
                  <Modal.Body className="modal-body">
                    <>
                      <div className="mb-5 flex justify-center">
                        <div>
                          <Trans
                            i18nKey="subscription.downgradeAlert.expirationDate"
                            values={{ expiredDate: moment(currentSubscription.expiredAt).format('DD MMM YYYY') }}
                            components={{
                              italic: <i />,
                              bold: <strong />,
                              span: <span className="text-red-500 font-semibold" />,
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-5 flex justify-center">
                        <p className="text-center">{t('subscription.downgradeAlert.actionInsteadOf')}</p>
                      </div>
                      {!!numberOfCourseEnroll(coursesEnrollment?.data, selectedPlan) && (
                        <div className="mb-5 flex justify-center">
                          <div className="text-center">
                            <Trans
                              i18nKey="subscription.downgradeAlert.loseCourses"
                              values={{
                                numberOfCourses: numberOfCourseEnroll(coursesEnrollment?.data, selectedPlan),
                                selectedPlanName: selectedPlan?.name,
                                courses: numberOfCourseEnroll(coursesEnrollment?.data, selectedPlan) > 1 ? 'Courses' : 'Course',
                              }}
                              components={{
                                italic: <i />,
                                bold: <strong className="text-center" />,
                                span: <span className="text-red-500 font-semibold" />,
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <div className="mt-5">
                        <div className="flex justify-center mt-5">
                          <div className="button-continue">
                            <Button onClick={() => setIsOpenWarningModal(false)}>{t('button.ok')}</Button>
                          </div>
                        </div>
                      </div>
                    </>
                  </Modal.Body>
                </Modal>
              )}
              {coursesEnrollment && selectedPlan && (
                <Modal
                  show={isOpenDowngradeModal}
                  size="2xl"
                  popup={true}
                  onClose={() => setIsOpenDowngradeModal(false)}
                  className="modal-price-change"
                >
                  <Modal.Header className="modal-header">
                    <div className="">{t('subscription.changePlan')}</div>
                  </Modal.Header>
                  <Modal.Body className="modal-body">
                    <>
                      <div className="mb-5 flex justify-center">
                        <div className="text-center">
                          <Trans
                            i18nKey="subscription.downgradeAlert.loseCourses"
                            values={{
                              numberOfCourses: numberOfCourseEnroll(coursesEnrollment?.data, selectedPlan),
                              selectedPlanName: selectedPlan?.name,
                              courses: numberOfCourseEnroll(coursesEnrollment?.data, selectedPlan) > 1 ? 'Courses' : 'Course',
                            }}
                            components={{
                              italic: <i />,
                              bold: <strong className="text-center" />,
                              span: <span className="text-red-500 font-semibold" />,
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-5">
                        <Button pill={true} color="success" onClick={() => setIsOpenDowngradeModal(false)} className="button-cancel mr-5 custom-button-style">
                          {' '}
                          {t('button.cancel')}
                        </Button>
                        <div className="button-downgradePlan">
                          <PaymentFlutterWaveButton
                            planId={selectedPlan?.id}
                            txRef={`my-yam-subscription-${selectedPlan?.type}-${txRef}`}
                            amount={(selectedPlan?.priceUSD * rates[`${selectedCurrency?.value}`]) / 100}
                            currency={selectedCurrency?.value}
                            label={t('button.downgradePlan')}
                            callback={() => handleSubscription(selectedPlan)}
                            setChooseSub={setChooseSub}
                            setIsOpenWarningModal={setIsOpenWarningModal}
                            setIsOpenUpgradeModal={setIsOpenUpgradeModal}
                            setIsOpenDowngradeModal={setIsOpenDowngradeModal}
                          />
                        </div>
                      </div>
                    </>
                  </Modal.Body>
                </Modal>
              )}
            </div>
          </>
        ) : (
          <SpinnerComponent />
        )}
      </div>
    </>
  );
};

export default Subscription;
