import React, { useState, useEffect, useContext } from 'react';
import { createAuth0Client, LogoutOptions } from '@auth0/auth0-spa-js';
import * as jwtDecode from 'jwt-decode';
import { getUserCurrentUser } from 'api/userApi';
import { deleteCookie, getCookie, setCookie } from 'utils/cookie';
import { openedxCookieKey, reloginUser, tokenKey, userRoles } from 'utils/constants';
import { userType } from 'utils/proptypes';
import i18n from '../../@core/locales/index';
import { userAction } from '../../redux/actions';
import { useDispatch } from 'react-redux';

const DEFAULT_REDIRECT_CALLBACK = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

/* istanbul ignore next */
export const getTokenSilently = async () => {
  const auth0 = await createAuth0Client({
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    },
  });

  const isAuthenticated = await auth0.isAuthenticated();

  if (!!isAuthenticated) {
    return auth0.getTokenSilently();
  }

  return null;
};

export const setToken = (token: any) => {
  setCookie(tokenKey, token);
};

/* istanbul ignore next */
export const getToken = async () => {
  let token = getCookie(tokenKey);
  const decoded: any = token ? jwtDecode.default(token) : null;
  if (!decoded || decoded.exp < Date.now() / 1000) {
    token = await getTokenSilently();
  }
  return token;
};

const getUserRole = (data: userType): string => {
  const { roles } = data;
  if (roles && roles.length > 0) {
    const rolesName = roles?.map((item: any) => item.displayName);
    if (rolesName?.includes(userRoles.SUPER_ADMIN)) {
      return userRoles.SUPER_ADMIN;
    } else if (rolesName?.includes(userRoles.ORGANIZATION_ADMIN)) {
      return userRoles.ORGANIZATION_ADMIN;
    } else return userRoles.STANDARD_USER;
  } else {
    return userRoles.STANDARD_USER;
  }
};

export const Auth0Context = React.createContext({} as any);
/* istanbul ignore next */
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [user, setUser] = useState<any>();
  const [auth0Client, setAuth0] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [userRole, setUserRole] = useState<string>();
  const dispatch = useDispatch();

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      /* istanbul ignore else */
      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        let retrievedUserInfo = {};
        const userReresponse = await getUserCurrentUser();

        const { data: userInfo } = userReresponse;
        if (!userInfo || !userReresponse) {
          setUser({ ...user, ...retrievedUserInfo });
          setLoading(false);
          auth0FromHook.logout();
          return;
        } else {
          const token = await auth0FromHook.getTokenSilently();

          setToken(token);
          const userRole = getUserRole(userInfo);
          setUserRole(userRole);

          const { language } = userInfo;
          dispatch(userAction.setUserInfo({ ...userInfo, userRole }));
          setCookie(openedxCookieKey, language);
          i18n.changeLanguage(language);
          // window.gtag('set', { 'user_id': userInfo.data.id });
          setCookie(reloginUser, 'true');

          retrievedUserInfo = {
            ...userInfo,
            token,
          };
        }

        setUser({ ...user, ...retrievedUserInfo });
      }

      setLoading(false);
    };

    initAuth0();
    // eslint-disable-next-line
  }, []);

  /* istanbul ignore next */
  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  /* istanbul ignore next */
  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };
  /* istanbul ignore next */

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        userRole,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p: any) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p: any) => {
          auth0Client.loginWithRedirect(...p);
        },
        getTokenSilently: (...p: any) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p: any) => auth0Client.getTokenWithPopup(...p),
        setUser: (...p: any) => setUser({ ...p }),
        logout: () => {
          deleteCookie(tokenKey);
          deleteCookie(openedxCookieKey);
          const location: string = window.location.origin;
          const param: LogoutOptions = {
            logoutParams: {
              returnTo: location,
            },
          };
          auth0Client.logout(param);
        },
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
