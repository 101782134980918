import cn from 'classnames';
import { HiCheck, HiOutlineMinusSm } from 'react-icons/hi';

export const SUCCESS = 'success'

function _getIcon(status: string) {
  return status === SUCCESS ? HiCheck : HiOutlineMinusSm;
}

function _getClassIcon(status: string) {
  return status === SUCCESS ? 'text-green-500 h-4 w-4 mr-1' : 'text-gray-900 h-4 w-4 mr-1';
}

const ErrorItem = (props: any) => {
  const { className, text, status } = props;

  const Icon = _getIcon(status);
  const classIcon = _getClassIcon(status);

  return (
    <aside className={cn('flex items-center', className)}>
      <Icon className={classIcon} />
      <span className={status === SUCCESS ? 'text-green-500' : 'text-gray-900'}>{text}</span>
    </aside>
  );
};
export default ErrorItem;
