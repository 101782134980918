import './programItem.scss';
import { HiChevronRight, HiOutlineChat } from 'react-icons/hi';
import { useNavigate } from 'react-router';
import { routePaths } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { formatCurrencies } from '../../utils/utils';

const ProgramItem = (props: any) => {
  const { data, isDegree } = props;
  const navigate = useNavigate();
  const [t] = useTranslation();

  const placeholder = require('../../assets/image/placeholder.png');
  const coursePlaceHolder = require('../../assets/image/course-placeholder.png');
  const imageUrl = isDegree ? data.image?.url : data.imageUrl;

  const handleOnClickViewDegreeDetail = () => {
    const { id, title } = data;
    navigate(`${routePaths.DEGREES}/${title.replace(/\s+/g, '-')}/${id}`);
  };

  const handleOnClickViewCourseDetail = () => {
    const { title, edxOrg, edxNumber } = data;
    navigate(`${routePaths.COURSES}/${title.replace(/\s+/g, '-')}/${edxOrg}/${edxNumber}`);
  };

  return (
    <div
      className="card-item rounded-lg shadow-md bg-white focus:outline-none cursor-pointer"
      onClick={() => (!!isDegree ? handleOnClickViewDegreeDetail() : handleOnClickViewCourseDetail())}
    >
      <img
        style={{
          backgroundImage: `url(${coursePlaceHolder})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        className="rounded-t-lg object-cover"
        alt=""
        src={imageUrl ? imageUrl : placeholder}
      />
      <div className="flex flex-col px-3 py-2.5">
        {!!isDegree ? (
          <>
            <span className="text-sm font-normal text-gray-900 truncate">{data.institution?.name}</span>
            <span className="program-title text-lg font-semibold text-gray-900 truncate border-b border-gray-100 mb-2 pb-1">{data.title}</span>
            {/* <span className="text-xs font-normal text-gray-500 truncate">Bachelor’s Degree 3-6 years</span>
            <span className="text-xs font-normal text-gray-500 truncate">Application February 1, 2023</span> */}
            <span className="flex mt-2 items-center text-sm font-semibold primary rounded cursor-pointer">
              {t('common.viewDetails')}
              <HiChevronRight className="w-5 h-5" />
            </span>
          </>
        ) : (
          <>
            <span className="text-sm font-normal text-gray-900 truncate h-5">{data.institution?.name}</span>
            <span className="program-title text-lg font-semibold text-gray-900 truncate border-b border-gray-100 mb-2 pb-1">{data.title}</span>
            <div className="view-details h-8">
              {!!data.priceUSD && (
                <div className="flex items-center bg-green-50 px-2.5 rounded mr-3 h-8">
                  <span className="text-xs">{formatCurrencies(data.priceUSD)}</span>
                </div>
              )}
              {!data.priceUSD && (
                <div className="flex items-center bg-blue-50 py-1 px-2.5 rounded mr-3">
                  <HiOutlineChat className="w-6 h-6 mr-1" />
                  <span className="text-xs">
                    {data.pacing}-{t('common.paced')}
                  </span>
                </div>
              )}
              <span className="flex mt-1 items-center text-sm font-semibold primary rounded cursor-pointer">
                {t('common.viewDetails')}
                <HiChevronRight className="w-5 h-5" />
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProgramItem;
