import base from './baseApi';

const { urls, methods, execute } = base();

const createInstitution = (payload: {
  name: string;
}) => {
  const method = methods.POST;
  const url = urls.institutions.institutions;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const getInstitutions = async () => {
  const method = methods.GET;

  const url = `${urls.institutions.institutions}`;

  const response = await execute(method, url, { authorization: false });

  return response;
};

const updateInstitutionById = (id: any, payload: any) => {
  const method = methods.PUT;
  const url = `${urls.institutions.institutions}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const deleteInstitution = async (id: any) => {
  const method = methods.DELETE;

  const url = `${urls.institutions.institutions}/${id}`;
  
  const response = await execute(method, url, { authorization: true });

  return response;
};


export { createInstitution, getInstitutions, updateInstitutionById, deleteInstitution };
