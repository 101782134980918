/**
* Last Modified: June 14, 2023
* Last Modified By: Sheriff Issaka
* Modification Description: Add file header
* Description: Card component for handling degree enrollment
**/

import { Fragment, useEffect, useState } from 'react';
import { Card, Button, Modal, Spinner, Dropdown } from 'flowbite-react';
import { InlineWidget } from 'react-calendly';
import { get } from 'lodash';
import { getCookie } from '../../utils/cookie';
import { useTranslation } from 'react-i18next';
import { openedxCookieKey, routePaths, CURRENCY_OPTIONS, SUBSCRIPTION_TYPE, PACING } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import { getExchangeRates } from '../../api/subscriptionApi';
import { useQuery, useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ratesAction, userAction } from 'redux/actions';
import { ratesSelector, userSelector } from 'redux/selectors';
import { compareDate, formatPrice, showToast } from 'utils/utils';
import './CardDegreeEnroll.scss';
import { getUserCurrentUser, userDegreeEnroll } from '../../api/userApi';
import React from 'react';

const CardDegreeEnroll = (props: any) => {
  const {
    title,
    description,
    includeInPlan,
    price,
    isEnroll,
    isEnrollLoading,
    isSubscribed,
    isDegree,
    onGetStarted,
    isUpgrade,
    degree,
    reloadDegreeDetails,
  } = props;

  const [letUsHelpOpen, setLetUsHelpOpen] = useState(false);
  const [calendlyOpen, setCalendlyOpen] = useState(false);
  const [disableContinueWithoutMeeting, setDisableContinueWithoutMeeting] = useState(false);
  const [isExpireSubscription, setExpireSubscription] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(CURRENCY_OPTIONS.find(item => item.value === 'USD'));
  const language = getCookie(openedxCookieKey);
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rates } = useSelector(ratesSelector);
  const { userInfo } = useSelector(userSelector);

  const languageCode = i18n.language;

  const handleViewCourseOpenEdx = async () => {
    navigate(`${routePaths.MY_LEARNING}`);
  };

  const handleGetStarted = async () => {
    // Enroll course
    if (!isDegree && onGetStarted) {
      onGetStarted();
    } else {
      reloadDegreeDetails();
      setTimeout(() => {
        setLetUsHelpOpen(true);
      }, 1000);
    }
  };

  const handleEnrollNow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!userInfo?.id) {
      navigate(routePaths.LOGIN_PAGE);
    } else {
      navigate(routePaths.SUBSCRIPTION);
    }
  };

  const { mutate: degreeEnrollMutation } = useMutation('degreeEnroll', {
    mutationFn: userDegreeEnroll,
    onSuccess: () => {
      showToast('success', t('degree.degreeEnrollSuccessful'));
      window.location.reload();
    },
    onError: () => {
      showToast('error', t('degree.degreeEnrollError'));
    },
  });

  const handleContinueWithoutMeeting = () => {
    const payload = { degreeId: degree?.id };
    degreeEnrollMutation(payload);
  };

  const { isLoading } = useQuery(['getExchangeRates'], () => getExchangeRates(), {
    staleTime: Infinity,
    onSuccess: data => {
      dispatch(ratesAction.setRates(data?.data?.conversion_rates));
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const { data: userInfoData } = await getUserCurrentUser();
      dispatch(userAction.setUserInfo({ ...userInfoData }));
      const subscriptions = get(userInfoData, 'subscriptions', []);
      const expiredDate = get(userInfoData, 'subscriptions[0].expiredAt', null);
      subscriptions.forEach((subscription: any) => {
        const type = get(subscription, 'plan.type');
        if (type.includes(SUBSCRIPTION_TYPE.FREE) || type.includes(SUBSCRIPTION_TYPE.COURSE_ACCESS)) {
          setDisableContinueWithoutMeeting(true);
        } else if (type.includes(SUBSCRIPTION_TYPE.SELF_PACED_DEGREE_PROGRAM)) {
          if (degree?.pacing === PACING.INSTRUCTOR) {
            setDisableContinueWithoutMeeting(true);
          } else {
            setDisableContinueWithoutMeeting(false);
          }
        } else if (type.includes(SUBSCRIPTION_TYPE.INSTRUCTOR_PACED_DEGREE_PROGRAM)) {
          setDisableContinueWithoutMeeting(false);
        }
      });

      if (compareDate(expiredDate, new Date())) {
        setDisableContinueWithoutMeeting(true);
        setExpireSubscription(true);
      }
    };
    fetchData().then(() => {
      //
    });
  }, [degree, letUsHelpOpen, dispatch]);

  const renderButton = (isSubscribed: any, isEnroll: any) => {
    if (isEnrollLoading) {
      return (
        <div className="w-full mb-2 button-enroll">
          <Button>
            <div className="mr-3">
              <Spinner size="sm" light={true} />
            </div>
            {t('loading')} ...
          </Button>
        </div>
      );
    }

    if (isEnroll && !isExpireSubscription) {
      return (
        <div className="w-full mb-2 button-enroll">
          <Button pill={true} color="success" onClick={handleViewCourseOpenEdx} className="custom-button-style">
            {t('enrollCard.viewYourCourse')}
          </Button>
        </div>
      );
    }

    if (!isSubscribed) {
      return (
        <div className="w-full mb-2 button-enroll">
          <Button pill={true} color="success" onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleEnrollNow(e)} className="custom-button-style">
            {t('enrollCard.enrollNow')}
          </Button>
        </div>
      );
    } else if (isUpgrade) {
      // check if is upgrade
      return (
        <div className="w-full mb-2 button-started">
          <Button pill={true} color="success" onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleEnrollNow(e)} className="custom-button-style">
            {t('enrollCard.upgradePlan')}
          </Button>
        </div>
      );
    } else {
      return (
        <div className="w-full mb-2 button-started">
          <Button pill={true} color="success" onClick={handleGetStarted} className="custom-button-style">
            {t('enrollCard.getStarted')}
          </Button>
        </div>
      );
    }
  };

  const renderCalendlyModal = () => {
    let url = '';
    if (language === 'fr') {
      url = 'https://calendly.com/yamedu/yam-advisor-french';
    } else {
      url = 'https://calendly.com/yamedu/yam-advisor-english';
    }
    return (
      <Fragment>
        <Modal className="basic-modal" size="2xl" show={calendlyOpen} onClose={() => setCalendlyOpen(false)}>
          <Modal.Header className="bg-transparent">Get Started</Modal.Header>
          <Modal.Body>
            <div className="space-y-6 w-full">
              <InlineWidget
                url={url}
                pageSettings={{
                  backgroundColor: 'ffffff',
                  hideEventTypeDetails: false,
                  hideLandingPageDetails: false,
                  primaryColor: '2D839B',
                  textColor: '111928',
                  hideGdprBanner: false,
                }}
                iframeTitle={'Yam Calendly'}
              />
            </div>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  };

  const renderLetUsHelpModal = () => {
    return (
      <Fragment>
        <Modal
          className="basic-modal let-us-help"
          size="md"
          show={letUsHelpOpen}
          onClose={() => {
            setLetUsHelpOpen(false);
          }}
        >
          <Modal.Header className="let-us-help-header bg-transparent" />
          <Modal.Body>
            <div className="pr-4 pl-4 space-y-4 w-full flex flex-col justify-center items-center">
              <div className="let-us-help-title">{t('enrollCard.letUsHelp')}</div>
              <div className="let-us-help-message p-6">{t('enrollCard.meetWithAnAdvisor')}</div>
              <div className="">
                <Button
                  className="pl-3 pr-3 custom-button-style"
                  onClick={() => {
                    setCalendlyOpen(true);
                    setLetUsHelpOpen(false);
                  }}
                >
                  {t('enrollCard.scheduleAnAppointment')}
                </Button>
              </div>
              {!disableContinueWithoutMeeting && (
                <div className="">
                  <Button
                    className="pl-3 pr-3 custom-button-style"
                    color="light"
                    onClick={() => {
                      handleContinueWithoutMeeting();
                    }}
                  >
                    {t('enrollCard.continueWithoutMeeting')}
                  </Button>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  };

  const handleChangeCurrency = (item: any) => {
    setSelectedCurrency(item);
  };

  return (
    <div className="w-11/12 card-enroll">
      {renderCalendlyModal()}
      {renderLetUsHelpModal()}
      <Card href="#">
        <div className="container-card">
          <div className="information">
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{title}</h5>
            <p className="text-sm mb-3 mt-3">{description}</p>
          </div>
          <div className="enroll">
            {renderButton(isSubscribed, isEnroll)}
            {price && (
              <div className="flex justify-center">
                <p>
                  {includeInPlan ? (
                    <strong>{t('coursePage.free')}</strong>
                  ) : (
                    <div className="mt-2 relative">
                      {!isLoading ? (
                        <Dropdown
                          className="price-dropdown"
                          arrowIcon={true}
                          inline={true}
                          placement="bottom"
                          label={
                            <div className="font-bold">
                              <span>{selectedCurrency?.symbol}</span>
                              <span>{formatPrice(rates[`${selectedCurrency?.value}`] * price, selectedCurrency?.value || 'USD', languageCode)}</span>
                            </div>
                          }
                        >
                          <div className="h-48 w-24 dropdown-item">
                            {CURRENCY_OPTIONS.map((item, index) => (
                              <Dropdown.Item key={index} onClick={() => handleChangeCurrency(item)}>
                                {item.label}
                              </Dropdown.Item>
                            ))}
                          </div>
                        </Dropdown>
                      ) : (
                        <div className="loading-currency">
                          <Spinner size="md" />
                        </div>
                      )}
                    </div>
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
        <hr className="border-gray-300" />
      </Card>
    </div>
  );
};
export default CardDegreeEnroll;
