/* eslint-disable no-unused-vars */
import { DashboardPage, OrganizationPage, UsersPage, VersionPage } from 'pages';
// import Home from 'pages/home/home';
import NotFoundPage from 'pages/notFound';
import { routePaths } from 'utils/constants';
import { routeType } from 'utils/proptypes';
import StudentProfile from 'pages/profile/student-profile';
// import Subscription from '../pages/subscription/subscription';
// import Logout from 'pages/logout/logout';
// import Login from 'pages/login/login';
import AdminCourse from '../pages/admin/courses/courses';
import AdminDegrees from 'pages/admin/degrees/degrees';
import AddDegree from 'pages/admin/degrees/addDegree';
import EditDegree from 'pages/admin/degrees/editDegree';
import Login from 'pages/login/login';
import Logout from 'pages/logout/logout';
import Home from 'pages/home/home';
import Subscription from 'pages/subscription/subscription';
import UserManagementPage from 'pages/userManagement';
import categorie from 'pages/admin/mycategorie/categorie';
import Institutions from 'pages/admin/myinstitutions/institutions'; 

const routes: routeType[] = [
  {
    path: routePaths.VERSION_PAGE,
    component: VersionPage,
    isGuarded: false,
    children: [],
  },
  {
    path: routePaths.DASHBOARD_PAGE,
    component: DashboardPage,
    isGuarded: false,
    children: [],
  },
  {
    path: routePaths.USERS_PAGE,
    component: UserManagementPage,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: routePaths.PROFILE_PAGE,
    component: StudentProfile,
    isGuarded: true,
    children: [],
  },
  {
    path: routePaths.ORGANIZATION_PAGE,
    component: OrganizationPage,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: routePaths.NOT_FOUND_PAGE,
    component: NotFoundPage,
    isGuarded: false,
    children: [],
  },
  {
    path: routePaths.USERS_PAGE,
    component: UsersPage,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: routePaths.ADMIN_CATEGORIE,
    component: categorie,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: routePaths.ADMIN_INSTITUTIONS,
    component: Institutions,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  }, 
  // {
  //   path: routePaths.ORGANIZATION_SETTING,
  //   component: OrganizationSettingPage,
  //   layout: 'MainLayout',
  //   isGuarded: true,
  //   children: [],
  // },
  {
    path: routePaths.USERS_PAGE,
    component: UsersPage,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: routePaths.AMDIN_DEGREES,
    component: AdminDegrees,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },

  {
    path: routePaths.ADMIN_COURSES,
    component: AdminCourse,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: routePaths.ADD_DEGREE,
    component: AddDegree,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },

  {
    path: routePaths.EDIT_DEGREE,
    component: EditDegree,
    layout: 'MainLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: routePaths.HOME,
    component: Home,
    layout: 'UserLayout',
    isGuarded: false,
    children: [],
  },
  // {
  //   path: routePaths.INSTITUTIONS,
  //   component: Home,
  //   layout: 'UserLayout',
  //   isGuarded: false,
  //   children: [],
  // },
  {
    path: routePaths.SUBSCRIPTION,
    component: Subscription,
    layout: 'UserLayout',
    isGuarded: false,
    children: [],
  },

  {
    path: routePaths.LOGIN_PAGE,
    layout: 'UserLayout',
    component: Login,
    isGuarded: false,
    children: [],
  },
  {
    path: routePaths.LOGOUT_PAGE,
    component: Logout,
    layout: 'UserLayout',
    isGuarded: false,
    children: [],
  },
];

export default routes;
