import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rates: {},
};

const setRates = (state: any, action: any) => {
  state.rates = action.payload;
};

export const ratesSlice = createSlice({
  name: 'rates',
  initialState,
  reducers: {
    setRates,
  },
});
export default ratesSlice;
