/**
* Last Modified: June 14, 2023
* Last Modified By: Sheriff Issaka
* Modification Description: Add file header
* Description: Handles payment with flutterwave
**/

import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import { Button } from 'flowbite-react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../redux/selectors';
import { createBilling, createPaymentWebhook } from 'api/subscriptionApi';
import { useNavigate } from 'react-router-dom';
import { routePaths } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
const logo = require('../../../assets/image/svg/YAM-logo.svg') as string;
import '../../../pages/courses/CardCourseEnroll.scss'

const PaymentFlutterWaveButton = (props: any) => {
  const {
    currency = 'USD',
    paymentOptions = 'card, bank, account, googlepay, applepay, mpesa, mobilemoneyghana, mobilemoneyfranco, mobilemoneyuganda, mobilemoneyrwanda, mobilemoneyzambia',
    amount = 0,
    callback,
    planId,
    courseId,
    txRef,
    isCurrentPlan,
    setChooseSub = () => {},
    setIsOpenWarningModal,
    setIsOpenUpgradeModal,
    setIsOpenDowngradeModal,
    handleCreatedBill = null,
  } = props;
  const publicKey = `${process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY}`;
  const { userInfo } = useSelector(userSelector);
  const navigate = useNavigate();
  const [payment, setPayment] = useState();
  const [t] = useTranslation();

  useEffect(() => {
    if (payment) {
      handleAfterPayment(payment).then(r => console.log(r));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);


  // initialize flutterwave
  const config = {
    public_key: publicKey,
    tx_ref: txRef,
    amount: amount,
    currency: currency,
    payment_options: paymentOptions,
    customer: {
      email: userInfo?.emailAddress,
      phone_number: '',
      name: `${userInfo?.firstName} ${userInfo?.lastName}`,
    },
    customizations: {
      title: 'My Yam',
      description: 'Payment for subscription',
      logo: logo,
    },
  };

  console.log(config)

  // call flutterwave to handle payment
  const handlePayment = useFlutterwave(config);

  const handleCreateBilling = async () => {
    if (!userInfo?.id) {
      navigate(routePaths.LOGIN_PAGE);
      return;
    }

    const bill: any = await createBilling({
      planId: planId,
      courseId: courseId,
      txRef: config.tx_ref,
      currency: currency,
    });

    return bill.data;
  };


  const handleAfterPayment = async (response: any) => {

    console.log(response)
    if( (response.status === 'successful') || (response.status === 'À succès') ) {
      await createPaymentWebhook(response);

      /*try{
        const billDetail = await createPaymentWebhook(response);
        if (billDetail.status !== 201 || billDetail.data.status !== 'successful') {
          throw new Error();
        }
      } catch (e) {
        const message: string = t('flutterwave.paymentFail');
        toast.error(message);
        return;
      }*/

      const message: string = t('Payment successfully');
      toast.success(message);
      await callback(response);
      return;
    }else{
      const message: string = t('flutterwave.createdFail');
      toast.error(message);
      throw new Error();
    }/**/
  };

  /*const handleAfterPayment = async (response: any) => {
    try {
      if ((response.status !== 'successful')) {
        throw new Error();
      }

    } catch (e) {
      const message: string = t('flutterwave.paymentFail');
      toast.error(message);
      return;
    }

    try{
      const billDetail = await createPaymentWebhook(response);
      if (billDetail.status !== 201 || billDetail.data.status !== 'successful') {
        throw new Error();
      }
    } catch (e) {
      const message: string = t('flutterwave.paymentFail');
      toast.error(message);
      return;
    }
    try {
      await callback(response);
    } catch (e) {
      const message: string = t('flutterwave.createdFail');
      toast.error(message);
    }
  };*/

  return (
    <Button
      pill={true}
      disabled={isCurrentPlan}
      className="custom-button-style"
      onClick={() => {
        if (amount > 0) {
          handleCreateBilling().then(bill => {
            config.amount = bill.chargedAmount;
            if (handleCreatedBill) {
              handleCreatedBill();
            }
            handlePayment({
              callback: (response: any) => {

                console.log(response)

                setPayment(response);
                closePaymentModal();
              },
              onClose: () => {
                setChooseSub(true);
                setIsOpenWarningModal && setIsOpenWarningModal(false);
                setIsOpenWarningModal && setIsOpenUpgradeModal(false);
                setIsOpenDowngradeModal && setIsOpenDowngradeModal(false);
              },
            });
          });
        } else {
          callback();
        }
      }}
    >
      {props.label}
    </Button>
  );
};
export default PaymentFlutterWaveButton;
