const userSelector = (state: any) => state.user;
const roleSelector = (state: any) => state.role;
const degreesSelector = (state: any) => state.degrees;
const institutionSelector = (state: any) => state.institutions;
const degreeCategoriesSelector = (state: any) => state.degreeCategories;
const degreeTypesSelector = (state: any) => state.degreeTypes;
const coursesSelector = (state: any) => state.courses;
const courseCategoriesSelector = (state: any) => state.courseCategories;
const courseTypesSelector = (state: any) => state.courseTypes;
const courseDetailSelector = (state: any) => state.courseDetail;
const degreeDetailSelector = (state: any) => state.degreeDetail;
const ratesSelector = (state: any) => state.rates;

export {
  userSelector,
  roleSelector,
  coursesSelector,
  institutionSelector,
  courseCategoriesSelector,
  courseTypesSelector,
  courseDetailSelector,
  degreesSelector,
  degreeCategoriesSelector,
  degreeTypesSelector,
  degreeDetailSelector,
  ratesSelector,
};
