import { Table } from 'flowbite-react';
import './dataTable.scss';

const DataTable = (props: any) => {
  const { data, columns, onRowClicked } = props;
  return (
    <div className="data-table">
      <Table striped={true}>
        <Table.Head>
          {columns.map((item: any) => (
            <Table.HeadCell key={item.name}>{item.name}</Table.HeadCell>
          ))}
        </Table.Head>
        <Table.Body className="divide-y">
          {data?.map((item: any) => {
            return (
              <Table.Row
                key={item.id}
                className={`bg-white dark:border-gray-700 dark:bg-gray-900 ${!item.isEmpty ? 'cursor-pointer' : ''}`}
                onClick={() => onRowClicked(item)}
              >
                {columns.map((col: any) => {
                  return <Table.Cell key={item.name}>{item[col.selector]}</Table.Cell>;
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default DataTable;
