import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  institutions: [],
};

const setInstitutions = (state: any, action: any) => {
  state.institutions = action.payload;
};

export const institutionSlice = createSlice({
  name: 'institutions',
  initialState,
  reducers: {
    setInstitutions,
  },
});
export default institutionSlice;
