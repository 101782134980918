import { useState } from "react";
import { components } from "react-select";

const InputOption = (initProps: any) => {

    const {
        getStyles,
        isDisabled,
        isFocused,
        isSelected,
        children,
        innerProps,
        ...rest
    } = initProps
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex "
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <input type="checkbox" onChange={() => { }} checked={isSelected} className="mr-2 input-checkbox-course" />
            {children}
        </components.Option>
    );
};

export default InputOption