/**
* Last Modified: June 16, 2023
* Last Modified By: Sheriff Issaka
* Modification Description: Fix bug with course login navigation
* Description: Handles navigation to course login page
**/
import Summary from 'components/commonComponent/summary';
import { Tabs } from 'flowbite-react';
import './courseDetail.scss';
import CardCourseEnroll from './CardCourseEnroll';
import { useDispatch, useSelector } from 'react-redux';
import { courseDetailSelector, userSelector } from 'redux/selectors';
import { courseDetailAction } from 'redux/actions';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getCourseDetail } from 'api/coursesApi';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import SpinnerComponent from 'components/spinner';

const OverviewTab = (props: any) => {
  const { course } = props;

  // if edx course overview contains local path'd images, replace them with full paths
  const openedxUrl = process.env.REACT_APP_OPENEDX_URL;
  const replacedOverview = course?.courseOpenEdxData?.overview.replaceAll('src="/static', 'src="' + openedxUrl + '/static');

  return (
    <>
      <div className="openedx-details border border-gray-300 p-4">
        <div
          dangerouslySetInnerHTML={{
            __html: replacedOverview,
          }}
        />
      </div>
    </>
  );
};

const CourseDetail = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
  const [t] = useTranslation();
  const { course } = useSelector(courseDetailSelector);
  const { userInfo } = useSelector(userSelector);
  const { data, isLoading } = useQuery(
    ['getCourseDetail', params.edxOrg, params.edxNumber],
    () => getCourseDetail({ edxOrg: params.edxOrg, edxNumber: params.edxNumber }),
  );

  useEffect(() => {
    if (data) {
      dispatch(courseDetailAction.setCourseDetail(data?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleViewCourseOpenEdx = async () => {
    // TODO: remove comment after more testing
    // currently goes here:
    // https://edxdev.yam-edu.com/courses/course-v1:TestingUniversity+QA102+2023_SUMMER/course/?tpa_hint=oa2-auth0-plugin

    // should go here:
    // https://edxdev.yam-edu.com/login?next=%2Fcourses%2Fcourse-v1%3ATestingUniversity%2BQA102%2B2023_SUMMER%2Fcourse%2F

    const edxCourseId: any = course.edxCourseId;
    const openedxUrl = process.env.REACT_APP_OPENEDX_URL;

    const encodedCourseId = encodeURIComponent(edxCourseId);
    const courseUrl = `${openedxUrl}/login?next=%2Fcourses%2F${encodedCourseId}%2Fcourse%2F`;

    window.location.href = courseUrl;

  };

  return (
    <>
      {!isLoading && course ? (
        <>
          <Helmet>
            <title>{course.title ? `${course.title} | ${t('title.YAMEducation')} ` : t('title.default')}</title>
            <meta name="description" content={course?.courseOpenEdxData?.short_description} />
          </Helmet>
          <Summary item={course} />
          <div className="container-card-enroll">
            <CardCourseEnroll course={course} onViewCourseOpenEdx={handleViewCourseOpenEdx} userInfo={userInfo} />
          </div>
          <div className="course-details-tabs max-w-8xl py-6 sm:px-14 px-12  grow container-course">
            <Tabs.Group aria-label="Tabs with underline">
              <Tabs.Item title="Overview">
                <OverviewTab course={course} />
              </Tabs.Item>
            </Tabs.Group>
          </div>
        </>
      ) : (
        <SpinnerComponent />
      )}
    </>
  );
};
export default CourseDetail;
