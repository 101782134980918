import { useAuth0 } from 'components/Auth0';
import { routePaths } from 'utils/constants';
import { deleteCookie } from 'utils/cookie';

const Logout = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    deleteCookie("token"); 
    deleteCookie("sessionId");          //Delete the OpenEdx cookie that keeps users signed in.
    deleteCookie("studio_session_id");  //Delete the OpenEdx Studio cookie that keeps users signed in.
    setTimeout(logout({ returnTo: routePaths.HOME }), 100);
  };

  return <>{handleLogout()}</>;
};

export default Logout;
