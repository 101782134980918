import { HiPencilAlt } from 'react-icons/hi';
import HeaderTable from 'components/table/headerTable';
import PaginateTable from 'components/table/paginate';
import { exportFileName, pageCount, BUTTON_COLOR } from 'utils/constants';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { courseType } from 'utils/proptypes';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import NoResult from 'components/commonComponent/noResult';
import { toast } from 'react-toastify';
import SpinnerComponent from 'components/spinner';
import 'styles/styles.scss';
import './courses.scss';
import ButtonIconWithText from 'components/button/buttonIconWithText';
import { messageErrors } from 'utils/utils';
import { getCourses as getCoursesApi } from '../../../api/coursesApi';
import CourseEditModal from '../../../components/modal/courseEditModal';
import { getCourseCategories } from '../../../api/courseCatetoriesApi';
import { courseCategoriesAction } from '../../../redux/actions';
import { courseCategoriesSelector } from '../../../redux/selectors';

const AdminCourse = () => {
  const [t] = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEntities, setTotalEntities] = useState(0);
  const [courses, setCourses] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const [courseCategoriesOptions, setCourseCategoriesOptions] = useState();
  const dispatch = useDispatch();

  const { courseCategories } = useSelector(courseCategoriesSelector);

  const getCourses = useMutation('getCourses', {
    mutationFn: getCoursesApi,
    onSuccess: ({ data }) => {
      setCourses(data.entities);
      setTotalEntities(data.totalEntities);
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const getCourseCategoriesMutation = useMutation('getCourseCategories', {
    mutationFn: getCourseCategories,
    onSuccess: ({ data }) => {
      dispatch(courseCategoriesAction.setCourseCategories(data.entities));
    },
  });

  const fetchData = () => {
    getCourses.mutate({ page: currentPage, limit: pageCount, searchQuery: searchValue });
    getCourseCategoriesMutation.mutate();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    setCourseCategoriesOptions(courseCategories.map((item: any) => ({
      label: item.name,
      value: item.id,
    })));
  }, [courseCategories]);

  const handleSearch = () => {
    getCourses.mutate({ page: 0, limit: pageCount, searchQuery: searchValue });
    setCurrentPage(0);
  };

  const handleClickAdd = () => {
    return false;
  };

  const handleClickEdit = (item: courseType) => {
    setOpenModal(true);
    setModalData(item);
    return true;
  };

  const handleExport = () => {
    const name = exportFileName.ADMIN_COURSES;
    if (totalEntities) {
      const exportedData = courses.map((course: courseType) => {
        return {
          'Course name': course.title,
          Institution: course.institution?.name,
          Pacing: course.pacing,
          'Created By': `${course.createdBy?.firstName} ${course.createdBy?.lastName}`,
          Category: course.courseCategory?.name,
        };
      });
      const wb = XLSX.utils.json_to_sheet(exportedData);
      const wbout = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wbout, wb, 'courses');
      XLSX.writeFile(wbout, name);
    }
  };

  return (
    <div className="admin-courses-page w-full">
      <HeaderTable
        placeholderSearch="Search"
        buttonName="Add Course"
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        handleAddClick={handleClickAdd}
        handleOnClickExport={handleExport}
        fetchData={handleSearch}
      />

      {totalEntities === 0 && <NoResult />}
      {totalEntities && (
        <div className="overflow-x-auto relative">
          <table className="w-full text-left dark:text-gray-400">
            <thead className="text-sm text-gray-600 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="py-3 px-4">
                  {t('admin.courses.courseName')}
                </th>
                <th scope="col" className="py-3 px-4 hidden-mobile">
                  {t('admin.courses.institution')}
                </th>
                <th scope="col" className="py-3 px-4 hidden-mobile-tablet">
                  {t('admin.courses.pacing')}
                </th>
                <th scope="col" className="py-3 hidden-mobile-tablet">
                  {t('admin.courses.createdBy')}
                </th>
                <th scope="col" className="py-3 hidden-mobile-tablet">
                  {t('admin.courses.category')}
                </th>
                <th scope="col" className="py-3 w-6" />
              </tr>
            </thead>
            <tbody>
              {courses?.map((item: courseType, key) => {
                return (
                  <tr
                    key={`${key}-list-user`}
                    className="font-medium text-gray-900 bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600"
                  >
                    <td className="py-3 px-4 break-word dark:text-white font-semibold">{item.title || ''}</td>
                    <td className="py-3 px-4 hidden-mobile font-medium text-gray-900 dark:text-white">{item?.institution?.name}</td>
                    <td className="hidden-mobile-tablet py-3 px-4 font-medium text-gray-900 dark:text-white">{item.pacing}</td>
                    <td className="hidden-mobile-tablet py-3 px-4">{`${item?.createdBy?.firstName || ''} ${item?.createdBy?.lastName || ''}`}</td>
                    <td className="hidden-mobile-tablet py-3 px-4">{item?.courseCategory?.name}</td>
                    <td className="actions py-3 pr-5">
                      <ButtonIconWithText
                        text={t('userManagementPage.edit')}
                        onClick={() => handleClickEdit(item)}
                        className={`cursor-pointer p-0.5 text-white bg-blue-600 
                      border-gray-300 hover:bg-blue-700 focus:ring-4 
                      focus:ring-blue-300 focus:!ring-2 group flex h-min 
                      w-fit items-center justify-center text-center 
                      font-medium focus:z-10 rounded-lg`}
                        Icon={HiPencilAlt}
                        color={BUTTON_COLOR.BLUE}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {getCourses.isLoading && <SpinnerComponent />}
          <PaginateTable
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalEntities={totalEntities}
            isLoadingTable={getCourses.isLoading}
          />
        </div>
      )}
      <CourseEditModal headerTitle="Edit Category"
                       openModal={openModal}
                       setOpenModal={setOpenModal}
                       modalData={modalData}
                       courseCategories={courseCategoriesOptions}
                       successFunc={fetchData}
      />
    </div>
  );
};

export default AdminCourse;
