import axios from 'axios';
import { httpMethods } from 'utils/constants';
// eslint-disable-next-line
import { headerType } from 'utils/proptypes';
import urls from './urls';
import i18next from 'i18next';
import { getToken } from 'components/Auth0';

const BaseApi = () => {
  const execute = async (method: string, endpoint: string, headers: headerType = {}, body: object = {}) => {
    const token = await getToken();
    const language = i18next.language || window.localStorage.i18nextLng;

    const requestHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(headers.authorization && {
        authorization: generateAuthToken(token),
      }),
      'accept-language': language
    };
    axios.defaults.baseURL = urls.baseUrl;

    const requestConfig = {
      url: endpoint,
      method,
      ...(method === httpMethods.GET && { params: body }),
      data: body,
      headers: requestHeaders,
    };

    return axios(requestConfig);
  };

  const generateAuthToken = (token: any) => {
    return `Bearer ${token}`;
  };

  return {
    methods: httpMethods,
    urls,
    execute,
  };
};

export default BaseApi;
