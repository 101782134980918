// eslint-disable-next-line
import { propsUserLayoutType } from 'utils/proptypes';
import FooterComponent from './footer';
import Header from './header';
import './userLayout.scss';

const UserLayout = (props: propsUserLayoutType) => {
  const { children, hideFooter } = props;
  return (
    <div className="user-layout">
      {/* Header */}
      <Header />

      <div className='children grow'>
        {children}

      </div>
      {/* Footer */}
      {!hideFooter && <FooterComponent />}
    </div>
  );
};
export default UserLayout;
