import Home from 'pages/home/home';
import Courses from 'pages/courses/courses';
import MyPurchases from 'pages/myPurchase/myPurchases';
import CourseDetail from 'pages/courses/courseDetail';
import Degrees from 'pages/degrees/degrees';
import { routePaths } from 'utils/constants';
import { routeType } from 'utils/proptypes';
import DegreeDetail from 'pages/degrees/degreeDetail';
import Logout from 'pages/logout/logout';
import Login from 'pages/login/login';
import MyLearning from 'pages/myLearning/myLearning';
import Subscription from '../pages/subscription/subscription';
import MyDegrees from 'pages/myDegrees/myDegrees';

import CoursEnroleList from 'pages/CoursEnroleList';

const userRoutes: routeType[] = [
  {
    path: routePaths.HOME,
    component: Home,
    layout: 'UserLayout',
    isGuarded: false,
    children: [],
  },
  {
    path: routePaths.COURSES,
    component: Courses,
    layout: 'UserLayout',
    hideFooter: true,
    isGuarded: true,
    title: 'courses',
    children: [],
  },
  {
    path: routePaths.COURSE_DETAIL,
    component: CourseDetail,
    layout: 'UserLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: routePaths.DEGREES,
    component: Degrees,
    layout: 'UserLayout',
    hideFooter: true,
    isGuarded: true,
    title: 'degrees',
    children: [],
  },
    {
    path: routePaths.CoursEnroleList,
    component: CoursEnroleList,
    layout: 'UserLayout',
    hideFooter: false,
    isGuarded: true,
    title: 'degrees',
    children: [],
  },                                                                                                         
  {
    path: routePaths.DEGREE_DETAIL,
    component: DegreeDetail,
    layout: 'UserLayout',
    isGuarded: true,
    children: [],
  },
  {
    path: routePaths.MY_PURCHASES,
    component: MyPurchases,
    layout: 'UserLayout',
    isGuarded: false,
    children: [],
  },
  {
    path: routePaths.MY_LEARNING,
    component: MyLearning,
    layout: 'UserLayout',
    isGuarded: false,
    children: [],
  },
  {
    path: routePaths.LOGIN_PAGE,
    component: Login,
    isGuarded: false,
    children: [],
  },
  {
    path: routePaths.LOGOUT_PAGE,
    component: Logout,
    isGuarded: false,
    children: [],
  },
  {
    path: routePaths.SUBSCRIPTION,
    component: Subscription,
    layout: 'UserLayout',
    isGuarded: false,
    children: [],
  },
  {
    path: routePaths.MY_DEGREES,
    component: MyDegrees,
    layout: 'UserLayout',
    hideFooter: true,
    isGuarded: false,
    children: [],
  },

  {
    path: routePaths.MY_DEGREES,
    component: MyDegrees,
    layout: 'UserLayout',
    hideFooter: true,
    isGuarded: false,
    children: [],
  },
];

export default userRoutes;
