import _ from 'lodash';
import { Navbar, Dropdown } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { routePaths } from 'utils/constants';
import { userRoles } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from 'components/Auth0';

const NavBarComponent = () => {
  const [t] = useTranslation();
  const { logout: handleLogout, user: userInfo, userRole } = useAuth0();
  const { firstName, lastName } = userInfo;

  const orgId = _.get(userInfo, 'organizations[0].id', 'orgId');

  const navigate = useNavigate();
  const redirectUri = (url: string) => {
    navigate(url);
  };

  return (
    <Navbar fluid={false} rounded={true}>
      <div className="flex flex-1 justify-end py-1">
        <div className="mr-2">
          <span className="block text-sm text-right capitalize">{firstName && lastName ? `${firstName} ${lastName}` : userInfo?.nickname}</span>
          <span className="block truncate text-sm text-gray-500 text-right">{userInfo?.email}</span>
        </div>
        <Dropdown
          arrowIcon={false}
          inline={true}
          label={
            <img
              className="w-10 h-10 overflow-hidden rounded-full w-full h-full object-cover"
              src={userInfo?.image?.url || userInfo?.picture}
              alt="avatar"
            />
          }
        >
          <Dropdown.Item onClick={() => redirectUri(routePaths.PROFILE_PAGE)}>{t('navbar.userProfile')}</Dropdown.Item>
          {userRole === userRoles.SUPER_ADMIN && (
            <>
              <Dropdown.Item onClick={() => redirectUri(routePaths.USERS_PAGE)}>{t('navbar.userManagement')}</Dropdown.Item>
              <Dropdown.Item onClick={() => redirectUri(routePaths.ORGANIZATION_PAGE)}>{t('navbar.organization')}</Dropdown.Item>
            </>
          )}
          {userRole === userRoles.ORGANIZATION_ADMIN && (
            <Dropdown.Item onClick={() => redirectUri(`/organization-setting/${orgId}`)}>{t('navbar.organizationSettings')}</Dropdown.Item>
          )}
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => handleLogout()}>
            <span className="text-red-500">{t('logout')}</span>
          </Dropdown.Item>
        </Dropdown>
        <Navbar.Toggle />
      </div>
    </Navbar>
  );
};
export default NavBarComponent;
