import { queryFilter, queryUserOrganizationFilter } from 'utils/proptypes';
import base from './baseApi';

const { urls, methods, execute } = base();
const getUserByFilter = async (queryFilter: queryFilter) => {
  const { page, limit, searchQuery } = queryFilter;

  const method = methods.GET;

  const url = `${urls.user.filter}?page=${page}&limit=${limit}&searchQuery=${searchQuery}`;

  const response = await execute(method, url, { authorization: true });

  return response;
};

const getUserById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.user.filter}/${id}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getUserCurrentUser = () => {
  const method = methods.GET;
  const url = `${urls.user.getCurrentUser}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const updateUserById = (id: any, payload: any) => {
  const method = methods.PUT;
  const url = `${urls.user.users}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const updateCurrentUser = (payload: any) => {
  const method = methods.PATCH;
  const url = `${urls.user.users}/currentUser`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const createUser = (payload: {
  emailAddress: string;
  emailVerified?: boolean;
  firstName: string;
  lastName: string;
  positionId?: string;
  roleId?: string;
  organizationId?: string;
}) => {
  const method = methods.POST;
  const url = urls.user.users;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const changeEmailAddress = (id: string, emailAddress: string) => {
  const method = methods.GET;
  const url = `${urls.user.setRoles}/?id=${id}&emailAddress=${emailAddress}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getUsersOrganization = (queryFilter: queryUserOrganizationFilter) => {
  const { page, limit, searchQuery, organizationId } = queryFilter;
  const method = methods.GET;
  const url = `${urls.user.userOrganization}/${organizationId}/?page=${page}&limit=${limit}&searchQuery=${searchQuery}`;

  const response = execute(method, url, { authorization: true });

  return response;
};

const updateActiveUserById = (id: string, payload: { isActive: boolean }) => {
  const method = methods.PATCH;
  const url = `${urls.user.users}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const setRolesForUser = (payload: { userId: string; roleIds: string[] }) => {
  const method = methods.GET;
  const url = urls.user.setRoles;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const updateUpdatedProfile = async (
  id: string,
  payload: {
    updatedProfile?: boolean;
  },
) => {
  const method = methods.PATCH;
  const url = `${urls.user.users}/${id}`;
  const response = await execute(method, url, { authorization: true }, payload);

  return response;
};

const updateFirstLogin = async () => {
  const method = methods.PATCH;
  const url = `${urls.user.users}/currentUser`;
  const response = await execute(method, url, { authorization: true }, { isFirstLogin: false });

  return response;
};

const updateUserImage = (payload: { id: string; file?: any }) => {
  const { id, file } = payload;
  const method = methods.POST;
  const url = `${urls.user.users}/${id}/uploadimage`;
  const response = execute(method, url, { authorization: true }, file);
  return response;
};

const changePassword = async (payload: any) => {
  const method = methods.PATCH;
  const url = urls.user.changePassword;
  const response = await execute(method, url, { authorization: false }, payload);
  return response;
};

const getUserByVerifiedCode = async (code: any) => {
  const method = methods.GET;
  const url = urls.user.getUserByVerifiedCode;
  const response = await execute(method, url + '/' + code, { authorization: false });
  return response;
};

const changeLanguage = async (payload: any) => {
  const method = methods.PATCH;
  const url = urls.user.getCurrentUser;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const getSubscriptionsByUserId = async (userId: string) => {
  const method = methods.GET;
  const url = `${urls.user.users}/${userId}/subscriptions?page=0&limit=1&withDeleted=false`;
  const response = await execute(method, url, { authorization: true });
  return response;
};

const getCourseEnrollment = async () => {
  const method = methods.GET;
  const url = `${urls.user.courseEnrollment}`;
  const response = await execute(method, url, { authorization: true });
  return response;
};

const getMyDegrees = async (queryFilter: queryFilter) => {
  const method = methods.GET;
  const url = `${urls.user.getMyDegrees}`;
  const response = await execute(method, url, { authorization: true }, queryFilter);
  return response;
};

const getMyBillings = async (queryFilter: queryFilter) => {
  const method = methods.GET;
  const url = `${urls.user.getMyBillings}`;
  const response = await execute(method, url, { authorization: true }, queryFilter);
  
  return response;
};

const userDegreeEnroll = async (payload: any) => {
  const method = methods.PUT;

  const url = urls.user.degreesEnroll;

  const response = await execute(method, url, { authorization: true }, payload);

  return response;
};

export {
  createUser,
  getUserByFilter,
  getUserById,
  getUserCurrentUser,
  updateUserById,
  changeEmailAddress,
  setRolesForUser,
  updateActiveUserById,
  getUsersOrganization,
  changePassword,
  getUserByVerifiedCode,
  updateUpdatedProfile,
  updateUserImage,
  updateFirstLogin,
  changeLanguage,
  getSubscriptionsByUserId,
  updateCurrentUser,
  getCourseEnrollment,
  getMyDegrees,
  getMyBillings,
  userDegreeEnroll,
};
