import cn from 'classnames';

const ButtonIconWithText = (props: any) => {
  const { className, text, onClick, Icon, color } = props;
  return (
    <button
      onClick={() => onClick()}
      className={cn(
        'cursor-pointer px-2.5 h-9 text-white text-sm font-medium focus:ring-4 focus:ring-blue-300 focus:!ring-2 group flex h-min w-fit items-center justify-center text-center focus:z-10 rounded-lg',
        {
          [color]: color,
          [className]: className,
        },
      )}
    >
      {Icon && <Icon className='h-4 w-4' />}
      <span className={cn("hidden-mobile-tablet", {"ml-2": Icon, "mx-2.5": !Icon})}>{text}</span>
    </button>
  );
};
export default ButtonIconWithText;
