import React, { useState, useEffect } from 'react';
import '../home/home.scss';
import ProgramItem from 'components/commonComponent/programItem';
import Select from 'react-select';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'flowbite-react';
import { useMutation } from 'react-query';
import { getDegrees } from 'api/degreesApi';
import { getInstitutions } from 'api/institutionApi';
import { getDegreeCategories } from 'api/degreeCatetoriesApi';
import { getDegreeTypes } from 'api/degreeTypesApi';
import { degreesCount, routePaths, status } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { degreesAction, institutionAction, degreeCategoriesAction, degreeTypesAction } from 'redux/actions';
import { degreesSelector, institutionSelector, degreeCategoriesSelector, degreeTypesSelector } from 'redux/selectors';
import './degrees.scss';
import { useNavigate } from 'react-router-dom';
import FooterComponent from '../../components/layouts/footer';
import { useTranslation } from 'react-i18next';
import NoResult from 'components/commonComponent/noResult';
import SpinnerComponent from 'components/spinner';

const Degrees = () => {
  const navigate = useNavigate();
  const { degrees, totalEntities } = useSelector(degreesSelector);
  const { institutions } = useSelector(institutionSelector);
  const { degreeCategories } = useSelector(degreeCategoriesSelector);
  const { degreeTypes } = useSelector(degreeTypesSelector);

  const dispatch = useDispatch();
  const [degreesData, setDegreesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [institutionOptions, setInstitutionOptions] = useState([]);
  const [degreeCategoriesOptions, setDegreeCategoriesOptions] = useState([]);
  const [degreeTypesOptions, setDegreeTypesOptions] = useState([]);
  const [t] = useTranslation();

  const initOption: any = { institutionId: null, degreeCategoryId: null, degreeTypeId: null };

  const [selectedFilter, setSelectedFilter] = useState(initOption);

  const { mutate: getDegreesMutation, isLoading } = useMutation('getDegrees', {
    mutationFn: getDegrees,
    retry: false,
    onSuccess: ({ data }) => {
      dispatch(degreesAction.setDegrees(data));
    },
  });

  const getInstitutionMutation = useMutation('getInstitutions', {
    mutationFn: getInstitutions,
    onSuccess: ({ data }) => {
      dispatch(institutionAction.setInstitutions(data.entities));
    },
  });

  const getDegreeCategoriesMutation = useMutation('getDegreeCategories', {
    mutationFn: getDegreeCategories,
    onSuccess: ({ data }) => {
      dispatch(degreeCategoriesAction.setDegreeCategories(data.entities));
    },
  });

  const getDegreeTypesMutation = useMutation('getDegreeTypes', {
    mutationFn: getDegreeTypes,
    onSuccess: ({ data }) => {
      dispatch(degreeTypesAction.setDegreeTypes(data.entities));
    },
  });

  const fetchDegrees = (queryParam = {}) => {
    const tempFilter: any = {};

    Object.keys(selectedFilter).forEach(key => {
      if (selectedFilter[key]) {
        tempFilter[key] = selectedFilter[key]?.value;
      }
    });
    const initParam = {
      page: currentPage,
      limit: degreesCount,
      status: status.ACTIVE,
      ...tempFilter,
      ...queryParam,
    };

    getDegreesMutation(initParam);
  };

  const fetchMoreDegrees = () => {
    fetchDegrees({ page: currentPage + 1 });
    setCurrentPage(currentPage + 1);
  };

  const handleOpenDetail = (courseId: string) => {
    navigate(`${routePaths.DEGREES}/${courseId}`);
  };

  useEffect(() => {
    if (degrees?.length >= 0) {
      setDegreesData(state => {
        let result = [];

        if (currentPage === 0) {
          result = degrees;
        } else {
          result = [...state, ...degrees];
        }

        return result;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [degrees]);

  useEffect(() => {
    setCurrentPage(0);
    fetchDegrees({ page: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  useEffect(() => {
    getInstitutionMutation.mutate();
    getDegreeCategoriesMutation.mutate();
    getDegreeTypesMutation.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const institutionsTemp = institutions.map((item: any) => ({
      label: item.name,
      value: item.id,
    }));
    setInstitutionOptions(institutionsTemp);
  }, [institutions]);

  useEffect(() => {
    const degreeCategoriesTemp = degreeCategories.map((item: any) => ({
      label: item.name,
      value: item.id,
    }));
    setDegreeCategoriesOptions(degreeCategoriesTemp);
  }, [degreeCategories]);

  useEffect(() => {
    const degreeTypesTemp = degreeTypes.map((item: any) => ({
      label: item.name,
      value: item.id,
    }));
    setDegreeTypesOptions(degreeTypesTemp);
  }, [degreeTypes]);

  useEffect(() => {
    return () => {
      setDegreesData([]);
    };
  }, []);

  return (
    <div className="degrees">
      <div id="scrollableDiv">
        <InfiniteScroll
          // inverse
          dataLength={degreesData.length}
          next={fetchMoreDegrees}
          hasMore={degreesData.length < totalEntities}
          scrollableTarget="scrollableDiv"
          scrollThreshold={0.8}
          loader={<></>}
        >
          <div className="w-full mx-auto max-screen lg:px-0 px-6 h-full relative">
            <div className='filter fixed' >
              <div className='bg-filter'>
                <div className="w-full  md:w-full xl:w-1/2 flex py-5 degrees__nav ">
                  <div className="w-1/3 pr-3 degrees__nav-item">
                    <Select
                      isSearchable={false}
                      isClearable
                      options={institutionOptions}
                      placeholder={t('common.institution')}
                      onChange={values =>
                        setSelectedFilter((state: any) => ({
                          ...state,
                          institutionId: values,
                        }))
                      }
                    />
                  </div>
                  <div className="w-1/3 pr-3 degrees__nav-item">
                    <Select
                      isSearchable={false}
                      isClearable
                      options={degreeCategoriesOptions}
                      placeholder={t('common.category')}
                      onChange={values =>
                        setSelectedFilter((state: any) => ({
                          ...state,
                          degreeCategoryId: values,
                        }))
                      }
                    />
                  </div>
                  <div className="w-1/3 pr-3 degrees__nav-item">
                    <Select
                      isSearchable={false}
                      isClearable
                      options={degreeTypesOptions}
                      placeholder={t('common.degreeType')}
                      onChange={values =>
                        setSelectedFilter((state: any) => ({
                          ...state,
                          degreeTypeId: values,
                        }))
                      }
                    />
                  </div>
                </div></div>

            </div>
            {isLoading && degreesData.length === 0 ? (<SpinnerComponent />) : (

              <>
                {degreesData.length > 0 ? (<div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 sm:grid-cols-2 mb-10 mh-scroll px-1">
                  {degreesData.map((degree: any) => {
                    return (
                      <div key={degree.id} className="py-2">
                        <ProgramItem key={degree.id} data={degree} isDegree={true} handleOpenDetail={() => handleOpenDetail(degree.id)} />
                      </div>
                    );
                  })}
                </div>) : (
                  !isLoading && (<div className='mh-noresult'><NoResult /></div>)
                )}
              </>
            )}

          </div>
          {degreesData.length < totalEntities && degreesData.length !== 0 ? (
            <div className="loading-scroll">
              <Spinner aria-label="spinner example" />
            </div>
          ) : (
            !isLoading && (<FooterComponent></FooterComponent>)
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Degrees;
