import base from './baseApi';

const { urls, methods, execute } = base();
const getDegreeCategories = async () => {
  const method = methods.GET;

  const url = `${urls.degreeCategories.degreeCategories}`;

  const response = await execute(method, url, { authorization: false });

  return response;
};

export { getDegreeCategories };
