import { useAuth0 } from 'components/Auth0';
import { useTranslation } from 'react-i18next';

const logo = require('../assets/image/svg/logo.svg').default;

const LoginPage = () => {
  const [t] = useTranslation();
  const { loginWithRedirect } = useAuth0();

  const handleClickButton = () => {
    setTimeout(loginWithRedirect(), 100);
  };

  return (
    <div className="p-4 sm:p-0 flex items-center justify-center h-screen">
      <div className="p-50 w-screen bg-white shadow dark:bg-gray-800">
        <img className="w-15 h-15 md:rounded-none  mx-auto" src={logo} alt='logo'/>
        <h5 className="text-2xl sm:text-3xl text-center mt-6 mb-6 font-semibold tracking-tight text-gray-900 dark:text-white">
          {t('loginPage.signInToMileMarker')}
        </h5>
        <button
          onClick={() => handleClickButton()}
          className="w-full inline-block px-6 mb-6 py-2.5 bg-blue-600 text-white 
          font-normal text-base leading-normal rounded-md shadow-md hover:bg-blue-700
          hover:shadow-lg focus:bg-blue-800 focus:shadow-lg focus:shadow-outline 
          active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
        >
          {t('login')}
        </button>
        <p className="font-normal text-center text-sm text-gray-900 dark:text-gray-400 px-4">
          {t('loginPage.notRegistered')}?{' '}
          <button onClick={handleClickButton} className="text-blue-600">
            {t('loginPage.createAccount')}
          </button>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
