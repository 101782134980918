import { queryFilter } from 'utils/proptypes';
import base from './baseApi';

const { urls, methods, execute } = base();
const getCourses = async (queryFilter: queryFilter) => {
  const method = methods.GET;

  const url = `${urls.courses.courses}`;
 
  const response = await execute(method, url, { authorization: false }, queryFilter);

  return response;
};

const getCourseDetail = async (queryFilter: queryFilter) => {
  const { edxOrg, edxNumber } = queryFilter;
  const method = methods.GET;
  const url = `${urls.courses.coursesDetail}/edxOrg/${edxOrg}/edxNumber/${edxNumber}`;
  return await execute(method, url, { authorization: true });
};

const updateCourseById = (id: any, payload: any) => {
  const method = methods.PUT;
  const url = `${urls.courses.coursesDetail}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const enrollCourse = async (payload: { edxCourseId: string, userAuth0Token: string | null }) => {
  const { urls, methods, execute } = base();
  const method = methods.PATCH;
  const url = urls.coursesEnroll;
  const response = await execute(method, url, { authorization: true }, payload);

  return response;
};

export { getCourses, getCourseDetail, updateCourseById, enrollCourse };
