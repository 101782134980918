import { useAuth0 } from 'components/Auth0';

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  const handleRedirectLogin = () => {
    setTimeout(loginWithRedirect(), 100);
  };

  return <>{handleRedirectLogin()}</>;
};

export default Login;
