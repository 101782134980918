import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  course: {},
};

const setCourseDetail = (state: any, action: any) => {
  state.course = action.payload;
};

const courseDetailSlice = createSlice({
  name: 'courseDetail',
  initialState,
  reducers: {
    setCourseDetail,
  },
});

export default courseDetailSlice;
