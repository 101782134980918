import { HiPencilAlt,HiTrash } from 'react-icons/hi';
import HeaderTable from 'components/table/headerTable';
import { BUTTON_COLOR, exportFileName} from 'utils/constants';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { initInstitution } from 'utils/initData';
import { useTranslation } from 'react-i18next';
import {  institutionType } from 'utils/proptypes';
import SpinnerComponent from 'components/spinner';
import { isEqual } from 'lodash';
import ButtonIconWithText from '../../../components/button/buttonIconWithText';
import { getPlans } from '../../../api/subscriptionApi';
import { toast } from 'react-toastify';
import { messageErrors } from 'utils/utils';
import {  deleteInstitution, getInstitutions } from 'api/institutionApi';
import InstitutionModal from 'components/modal/institutionModal';
import * as XLSX from 'xlsx';
//Delete modal
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react' 
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
const Institutions = () => {

  const [t] = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [Instituion, setInstitution] = useState([initInstitution]);
  const [targetInstitution, setTargetInstitution] = useState(initInstitution);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [deleteId, setDeleteId] = useState();
  const [show, setShow] = useState(false);
 
const cancelButtonRef = useRef(null)
const handleClickDelete =(id:any)=>{
  setDeleteId(id)
  setShow(true)}
const handleClose=()=>{
  setShow(false)
}
  const handleDeleteItem=async()=>{
    // const newArray = categories.filter((item: categoryTypes)=>item.id !==deleteId);
    //
    // setCategories(newArray);

    await deleteInstitution(deleteId)
    
      const resetData = Instituion.filter((c: institutionType) => deleteId !== c.id);
      setInstitution(resetData)
      const message: string = t('institutionpage.createDeleteMessage');
      toast.error(message);


    setShow(false)
  }

  const handleClickAddUser = () => {
    setTargetInstitution(initInstitution);
    setOpenModal(true);
    setIsEdit(false);
  };

  const getInstitution = useMutation('getInstitutions', {
    mutationFn: getInstitutions,
    onSuccess: ({ data }) => {
      setInstitution(data.entities);
    },
    onError: error => {
      setInstitution([initInstitution]);
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const fetchData = () => {
    getInstitution.mutate();
  };

  const handleSearch = () => {
    setCurrentPage(0);
  };

  useEffect(() => {
    fetchData();
    // console.log(categories)
    // eslint-disable-next-line
  }, [currentPage]);

  const handleClickEdit = (item: institutionType) => {
    setTargetInstitution(item);
    setIsEdit(true);
    setOpenModal(true);
  };

  // const handleClickDelete = async (item: institutionType) => {
  //   await deleteInstitution(item.id)
  //   const resetData = Instituion.filter((c: institutionType) => item.id !== c.id);
  //   setInstitution(resetData)
  //   const message: string = t('institutionPage.createDeleteMessage');
  //   toast.error(message);
  // }


  const { refetch: fetchPlans } = useQuery('getPlans', () => getPlans(), {
    enabled: false,
    onSuccess: ({ data }) => {
      if (Array.isArray(data.entities)) {
        // const options = data.entities.map((item: any) => {
        //   return { value: item.id, label: item.name, interval: item.interval, duration: item.duration };
        // });
      }
    },
  });

 
  useEffect(() => {
    fetchPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportInstitutionHandler = () => {
    const name = exportFileName.USER;
    if (Instituion[0] !== initInstitution && Instituion.length > 0) {
      const exportedData = Instituion.map((Instituion: institutionType) => {
        return {
          ' name': Instituion.name,
          
        };
      });
      const wb = XLSX.utils.json_to_sheet(exportedData);
      const wbout = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wbout, wb, 'test');
      XLSX.writeFile(wbout, name);
    }
  };
  return (
    <div className="admin-courses-page w-full">

<Transition.Root show={show} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto justify-content-center "
                    initialFocus={cancelButtonRef}
                    onClose={handleClose}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block a  align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationCircleIcon
                                            className="h-6 w-6 text-red-600"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg leading-6 font-medium text-gray-900"
                                        > 
                                           <h2>{t('institutionPage.ModalHeaderDeleteInstitution')}</h2>
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500"> {t('institutionPage.createMessageContentModalDelete')}
                                               
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                                    
                                     
                                    <ButtonIconWithText 
                        text={t('institutionPage.ModaalBTNDeleteD')}
                        onClick={()=>handleDeleteItem()}
                        Icon={HiTrash}
                        color={BUTTON_COLOR.RED}
                       /> 
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={handleClose}
                                        ref={cancelButtonRef}
                                    >
                                    {t('institutionPage.ModaalBTNDelete')}
                                       
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
      <HeaderTable
        placeholderSearch="Search for users"
        buttonName="Add Institution"
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        handleAddClick={handleClickAddUser}
        handleOnClickExport={exportInstitutionHandler}
        fetchData={handleSearch}
      />

      {!isEqual(Instituion[0], initInstitution) && Instituion.length > 0 && (
        <div className="overflow-x-auto relative">
          <table className="w-full text-sm text-left dark:text-gray-400">
            <thead className="text-sm text-gray-600 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="py-3 px-4">
                  {t('institutionPage.Institution')}
                </th>
                <th scope="col" className="py-3 w-6" />
                <th scope="col" className="py-3 w-6" />
              </tr>
            </thead>
            <tbody>
              {Instituion?.map((item: any, key) => {
                
                return (
                  <tr
                    key={`${key}-list-user`}
                    className="font-medium text-gray-900 bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600"
                  >
                    <td className="hidden-mobile-tablet py-3 px-4">{item?.name}</td>
                    <td className="py-3 flex">
                      <ButtonIconWithText
                        text={t('userManagementPage.edit')}
                        onClick={() => handleClickEdit(item)}
                        className="edit-btn cursor-pointer p-0.5 text-white bg-blue-600
                      border-gray-300 hover:bg-blue-700 focus:ring-4
                      focus:ring-blue-300 focus:!ring-2 group flex h-min
                      w-fit items-center justify-center text-center
                      font-medium focus:z-10 rounded-lg mr-2"
                        Icon={HiPencilAlt}
                        color={BUTTON_COLOR.BLUE}
                      />
                      <ButtonIconWithText
                        text={t('institutionPage.Delete')}
                        onClick={() => handleClickDelete(item.id)}
                        Icon={HiTrash}
                        color={BUTTON_COLOR.RED}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {getInstitution.isLoading && <SpinnerComponent />}
        </div>
      )}
      <InstitutionModal
        headerTitle={isEdit ? t('institutionPage.editInstitution') : t('institutionPage.addInstitution')}
        openModal={openModal}
        isEdit = {isEdit}
        setOpenModal={setOpenModal}
        targetData={targetInstitution}
        successFunc={fetchData}
      />  
      
    </div>
  );
};
export default Institutions;