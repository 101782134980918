/**
* Last Modified: June 14, 2023
* Last Modified By: Sheriff Issaka
* Modification Description: Add file header
* Description: File contains API functions for managing plans, subscriptions, billings, and payment webhooks.
**/

import base from './baseApi';
import { queryFilter } from 'utils/proptypes';
import axios from 'axios';
import { httpMethods } from 'utils/constants';

const getPlans = () => {
  const { urls, methods, execute } = base();
  const method = methods.GET;
  const url = `${urls.plans}/?page=0&limit=0`;

  const response = execute(method, url, { authorization: true }, {});

  return response;
};

const createSubscriptions = async (payload: { userId: string; planId: string }) => {
  const { urls, methods, execute } = base();
  const method = methods.POST;
  const url = urls.subscriptions;
  const response = await execute(method, url, { authorization: true }, payload);

  return response;
};

const createSubscriptionAdmin = async (payload: { planId: string; userId: string; expiredAt: string }) => {
  const { urls, methods, execute } = base();
  const method = methods.POST;
  const url = `${urls.subscriptions}/admin`;
  const response = await execute(method, url, { authorization: true }, payload);

  return response;
};

const createBilling = async (payload: { planId: string; txRef: string; courseId?: string; currency?: string }) => {
  const { urls, methods, execute } = base();
  const method = methods.POST;
  const url = `${urls.billings}`;
  const response = await execute(method, url, { authorization: true }, payload);
  return response;
};

const createPaymentWebhook = async (payload: any) => {
  const { urls, methods, execute } = base();
  const method = methods.POST;
  const url = `${urls.paymentWebhook}`;
  const response = await execute(method, url, { authorization: true }, payload);
  return response;
};

const getBillById = async (queryFilter: queryFilter) => {
  const { id } = queryFilter;
  const { urls, methods, execute } = base();
  const method = methods.GET;
  const url = `${urls.billings}/${id}`;
  const response = await execute(method, url, { authorization: true });

  return response;
};

const getCalculatePrice = async (query: { subcriptionId: string; planId: string }) => {
  const { urls, methods, execute } = base();
  const method = methods.GET;
  const url = `${urls.subscriptions}/calculatePrice`;
  const response = await execute(method, url, { authorization: true }, query);

  return response;
};

const getExchangeRates = async () => {
  const requestConfig = {
    url: `${process.env.REACT_APP_EXCHANGE_RATES_URL}/${process.env.REACT_APP_EXCHANGE_RATES_API_KEY}/latest/USD`,
    method: httpMethods.GET,
    headers: {},
  };
  const response = axios(requestConfig);
  return response;
};

export {
  getPlans,
  createSubscriptions,
  createSubscriptionAdmin,
  createBilling,
  createPaymentWebhook,
  getBillById,
  getCalculatePrice,
  getExchangeRates,
};
