/**
* Last Modified: June 14, 2023
* Last Modified By: Sheriff Issaka
* Modification Description: Add file header
* Description: Handle utility functions such as formatting dates, currencies, and toast messages
**/

import dayjs from 'dayjs';
import { Slide, toast, TypeOptions } from 'react-toastify';
import _ from 'lodash';
import { BILLING_STATUS, listLanguage, PACING, PLAN_INTERVAL, SUBSCRIPTION_TYPE, SUBSCRIPTION_TYPE_DISPLAY } from './constants';

const formatDate = (date: string) => {
  const datejs = dayjs(date);
  const $d = datejs.toDate();
  const $D = datejs.date();
  const $M = datejs.month();
  const $y = datejs.year();

  const dateTime = $d.toString().split(' ')[4];

  const splitTime = dateTime.split(':');

  return `${$D > 9 ? $D : '0' + $D}/${$M + 1 > 9 ? $M + 1 : '0' + ($M + 1)}/${$y} ${
    parseInt(splitTime[0]) > 12 ? parseInt(splitTime[0]) - 12 : splitTime[0]
  }:${splitTime[1]}${parseInt(splitTime[0]) > 12 ? 'pm' : 'am'}`;
};

const formatCurrencies = (amount: number, format: string = '$') => {
  return `${format}${amount.toFixed(2)}`;
};

const formatPrice = (price: number, currency: string, languageCode: string) => {
  let localsString: string = '';

  switch (languageCode) {
    case 'en':
      localsString = 'en-US';
      break;
    case 'fr':
      localsString = 'fr-FR';
      break;
    default:
      localsString = 'en-US';
      break;
  }

  return price.toLocaleString(localsString, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: currency,
  });
};

const checkLowerUpper = (value: string) => {
  return /(?=.*[A-Z])(?=.*[a-z]).*$/.test(value);
};

const checkNumberDymbol = (value: string) => {
  return /(?=.*\d)(?=.*\W+).*$/.test(value);
};

const checkLeast8Char = (value: string) => {
  return value.length >= 8;
};

const checkNoSpaces = (value: string) => {
  return /^\S+$/.test(value);
};

const validatiePassword = (value: string) => {
  return checkLowerUpper(value) && checkNumberDymbol(value) && checkLeast8Char(value) && checkNoSpaces(value);
};

const renderLanguage = (value: string) => {
  return listLanguage.find(x => {
    return x.code === value;
  })?.label;
};

const showToast = (key: TypeOptions, message: any) => {
  if (key === 'success' || key === 'error' || key === 'warning') {
    return toast[key](message, { transition: Slide, autoClose: 1500, icon: true });
  }
};

const messageErrors = (error: any, translation: any) => {
  if ([500, 404].includes(error?.response?.status)) {
    return translation('generalErrors');
  } else {
    const message = error?.response?.data?.errors[0]?.detail;
    return (_.isArray(message) ? message[0] : message) || translation('generalErrors');
  }
};

const getLevelSubscription = (planType: string) => {
  switch (planType) {
    case SUBSCRIPTION_TYPE.FREE:
      return 1;
    case SUBSCRIPTION_TYPE.COURSE_ACCESS:
      return 2;
    case SUBSCRIPTION_TYPE.SELF_PACED_DEGREE_PROGRAM:
      return 3;
    case SUBSCRIPTION_TYPE.INSTRUCTOR_PACED_DEGREE_PROGRAM:
      return 4;
    default:
      return 0;
  }
};

const getLevelNameSubscription = (level: number) => {
  switch (level) {
    case 1:
      return SUBSCRIPTION_TYPE_DISPLAY.FREE;
    case 2:
      return SUBSCRIPTION_TYPE_DISPLAY.COURSE_ACCESS;
    case 3:
      return SUBSCRIPTION_TYPE_DISPLAY.SELF_PACED_DEGREE_PROGRAM;
    case 4:
      return SUBSCRIPTION_TYPE_DISPLAY.INSTRUCTOR_PACED_DEGREE_PROGRAM;
    default:
      return SUBSCRIPTION_TYPE_DISPLAY.FREE;
  }
};

const getInterval = (interval: string) => {
  switch (interval) {
    case 'YEARLY':
      return PLAN_INTERVAL.YEARLY;
    case 'QUARTERLY':
      return PLAN_INTERVAL.QUARTERLY;
    case 'MONTHLY':
      return PLAN_INTERVAL.MONTHLY;
    case 'WEEKLY':
      return PLAN_INTERVAL.WEEKLY;
    case 'DAILY':
      return PLAN_INTERVAL.DAILY;
    default:
      return PLAN_INTERVAL.HOURLY;
  }
};

const getBillingStatus = (status: string) => {
  switch (status) {
    case BILLING_STATUS.SUCCESSFUL:
      return 'Paid';
    case BILLING_STATUS.FAILED:
      return 'Failed';
    case BILLING_STATUS.REFUND:
      return 'Refund';
    default:
      return '';
  }
};

const getLevelPacing = (pacing: string) => {
  switch (pacing) {
    case PACING.SELF:
      return 2;
    case PACING.INSTRUCTOR:
      return 4;
    default:
      return 0;
  }
};

const getPacing = (planType: string) => {
  switch (planType) {
    case SUBSCRIPTION_TYPE.COURSE_ACCESS:
      return PACING.SELF;
    case SUBSCRIPTION_TYPE.SELF_PACED_DEGREE_PROGRAM:
      return PACING.SELF;
    case SUBSCRIPTION_TYPE.INSTRUCTOR_PACED_DEGREE_PROGRAM:
      return PACING.INSTRUCTOR;
    default:
      return null;
  }
};

const compareDate = (dateCompare: any, dateSource: any) => {
  return new Date(dateCompare) <= dateSource;
};

const NUMBER_EXPRIED_DAY: any = 5;

const isPlanExpired = (expiredDate: any) => {
  if (!expiredDate) {
    return true;
  }

  const toDay = new Date();
  const limitDate = toDay.setDate(toDay.getDate() + NUMBER_EXPRIED_DAY);
  return compareDate(expiredDate, new Date(limitDate));
};

export {
  formatDate,
  formatCurrencies,
  showToast,
  checkLowerUpper,
  checkNumberDymbol,
  checkLeast8Char,
  checkNoSpaces,
  validatiePassword,
  messageErrors,
  renderLanguage,
  getLevelSubscription,
  getLevelNameSubscription,
  getLevelPacing,
  isPlanExpired,
  compareDate,
  getInterval,
  getBillingStatus,
  formatPrice,
  getPacing,
};
