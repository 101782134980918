import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  courseTypes: [],
};

const setCourseTypes = (state: any, action: any) => {
  state.courseTypes = action.payload;
};

export const courseTypesSlice = createSlice({
  name: 'courseTypes',
  initialState,
  reducers: {
    setCourseTypes,
  },
});
export default courseTypesSlice;
