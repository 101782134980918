import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
// eslint-disable-next-line
import { paginateType } from 'utils/proptypes';
import { useEffect, useState } from 'react';
import { pageCount } from 'utils/constants';
import { useTranslation } from 'react-i18next';

const PaginateTable = (props: paginateType) => {
  const { setCurrentPage, currentPage, totalEntities, isLoadingTable } = props;
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(pageCount);
  const [t] = useTranslation();
  const totalPage = Number(totalEntities % pageCount > 0 ? (totalEntities / pageCount).toFixed() : (totalEntities / pageCount - 1).toFixed());

  useEffect(() => {
    if (totalEntities < pageCount) setTo(totalEntities);
    else setTo(pageCount);
  }, [totalEntities]);

  useEffect(() => {
    if (currentPage === 0) setFrom(1);
  }, [currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPage && to !== totalEntities) {
      setCurrentPage(currentPage + 1);
      setFrom(from + pageCount);

      if (to + pageCount > totalEntities) setTo(totalEntities);
      else setTo(to + pageCount);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      setFrom(from - pageCount);

      if (from - pageCount === 1) setTo(pageCount);
      else setTo(to - pageCount);

      if (to === totalEntities) {
        setTo(from - 1);
      }
    }
  };

  return (<>

    {!isLoadingTable && (
      <div className="w-full flex flex-row items-center border-b border-gray-300">
        <div className="inline-flex mt-2 mb-2 xs:mt-0">
          <button
            disabled={isLoadingTable}
            onClick={() => handlePreviousPage()}
            className="cursor-pointer inline-flex items-center py-2 px-2 text-sm font-medium text-gray-900  rounded-l hover:bg-gray-100 bg-white "
          >
            <HiChevronLeft className="h-5 w-5" />
          </button>
          <button
            disabled={isLoadingTable}
            onClick={() => handleNextPage()}
            className="cursor-pointer inline-flex items-center py-2 px-2 text-sm font-medium text-gray-900 rounded-r  hover:bg-gray-100 bg-white "
          >
            <HiChevronRight className="h-5 w-5" />
          </button>
        </div>
        <span className="text-sm text-gray-700 dark:text-gray-400 px-2">
          {t('paginate.showing')}
          <span className="font-semibold text-gray-900 dark:text-white ml-1 ">{`${from} - ${to}`}</span>
          <span className="ml-1">{t('paginate.of')}</span>
          <span className="font-semibold text-gray-900 dark:text-white ml-1">{totalEntities}</span>
        </span>
      </div>
    )
    }
  </>

  );
};
export default PaginateTable;
