import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import routes from 'router/routes';
import GuardedRoute from 'router/guardedRoute';
import { routeType } from 'utils/proptypes';
import MainLayout from '../components/layouts/mainLayout';
import { BasicLayout, UpdateProfile, ChangePassword, NotFoundPage } from 'pages';
import { Spinner } from 'flowbite-react';
import { listLanguage, openedxCookieKey, routePaths } from 'utils/constants';
import UserLayout from 'components/layouts/userLayout';
import userRoutes from './userRoutes';
import Welcome from '../pages/welcome/welcome';
import { getCookie, setCookie } from 'utils/cookie';
import i18n from '../@core/locales/index';
import { useAuth0 } from 'components/Auth0';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Router = () => {
  const [t] = useTranslation();
  const { user: userInfo, isAuthenticated, loading, userRole } = useAuth0();

  useEffect(() => {
    // if user is logged in
    // No need to change anything
    if (!userInfo || !userInfo.language) {
      const pathname = window.location.pathname;
      // check language in pathname
      const arrayPath = pathname.split('/');

      // arrayPath[1].length === 2; 2 is length of language code
      // please consider to change it to 3 or more if we implement other language with code is more than 2 character
      if (arrayPath.length >= 2 && arrayPath[1].length === 2) {
        const currentLang = arrayPath[1];
        const targetLanguage = listLanguage.find((x: any) => {
          return x.code === currentLang;
        });

        if (targetLanguage) {
          setCookie(openedxCookieKey, currentLang);
          i18n.changeLanguage(currentLang);
        }
      } else {
        // use cookie when path in url do not contain /fr or /en
        const languageCode = getCookie(openedxCookieKey);
        if (languageCode) {
          i18n.changeLanguage(languageCode);
        } else {
          setCookie(openedxCookieKey, 'en');
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  if (loading || (isAuthenticated && !userInfo?.id)) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  const renderUserLayout = (route: routeType, key: number) => {
    if (isAuthenticated && userInfo) {
      if (userInfo.isFirstLogin || (!userInfo.subscriptions || userInfo.subscriptions.length === 0)) {
        return <Route path={routePaths.HOME} element={<Welcome />} key={`route-key-welcome`} />;
      }
    }

    return ['', 'en', 'fr'].map(language => {
      let pathString = '';
      if (route.path === routePaths.HOME) {
        pathString = `${route.path}${language}`;
      } else if (language.length > 0) {
        pathString = `/${language}${route.path}`;
      } else if (language.length === 0) {
        pathString = route.path;
      }

      let Layout: any;
      if (route.layout === 'UserLayout') {
        Layout = UserLayout;
      } else Layout = BasicLayout;

      return (
        <Route
          path={pathString}
          element={
            <Layout hideFooter={route.hideFooter}>
              <Helmet>
                <title>{route.title ? t(`title.${route.title}`) : t('title.default')}</title>
              </Helmet>
              <route.component />
            </Layout>
          }
          key={`user-route-${language}-${key}`}
        />
      );
    });
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path={routePaths.CHANGE_PASSWORD} element={<ChangePassword />} key={`route-key-change-password`} />
        {
          userRoutes.map((userRoute, userRouteKey) => {
            return renderUserLayout(userRoute, userRouteKey);
          })
        }
        {/* {!isAuthenticated && <Route path="*" element={<Navigate replace to={routePaths.LOGIN_PAGE} />} key={`route-key-navigate-login`} />} */}
        {isAuthenticated && userInfo.updatedProfile === null && (
          <>
            <Route path={routePaths.PROFILE_PAGE} element={<UpdateProfile isNavigatedFromDashboard={true} />} key={`route-key-update-profile`} />
            <Route
              path={routePaths.DASHBOARD_PAGE}
              element={<Navigate replace to={routePaths.PROFILE_PAGE} />}
              key={`route-key-navigate-update-profile`}
            />
          </>
        )}
        {isAuthenticated && 
          routes.map((route, key) => {
            if (route.isGuarded) {
              return (
                <Route path={route.path} element={<GuardedRoute path={route.path} userRole={userRole} />} key={`guarded-route-key-${key}`}>
                  {getFinalRoute(route, key)}
                </Route>
              );
            }
            return getFinalRoute(route, key);
          })}
        <Route path="*" element={<NotFoundPage />} key={`route-key-not-found`} />
      </Routes>
    </BrowserRouter>
  );
};

const getFinalRoute = (route: routeType, key: number) => {
  if (route.children.length > 0) {
    route.children.forEach((child, childKey) => {
      return <Route path={child.path} element={<child.component />} key={`child-route-key-${childKey}`} />;
    });
  }
  let Layout: any;
  if (route.layout === 'MainLayout') {
    Layout = MainLayout;
  } else Layout = BasicLayout;

  return (
    <Route
      path={route.path}
      element={
        <Layout>
          <route.component />
        </Layout>
      }
      key={`route-key-${key}`}
    />
  );
};

export default Router;
