// eslint-disable-next-line
import { categoryTypes, institutionType, orgTypes, userType } from './proptypes';

const initUser: userType = {
  id: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  position: { id: '', name: '' },
  roles: [],
  isActive: true,
};

const initCategory: categoryTypes = {
  id: '',
  name: '',
  isActive: true,
};

const initInstitution: institutionType = {
  id: "",
  name: ""
}

const initOrganization: orgTypes = {
  id: '',
  name: '',
  isActive: true,
};
export { initUser, initOrganization, initCategory, initInstitution };
