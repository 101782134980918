import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  degrees: [],
  totalEntities: 0,
  myDegrees: [],
  totalMyDegrees: 0,
};

const setDegrees = (state: any, action: any) => {
  state.degrees = action.payload.entities;
  state.totalEntities = action.payload.totalEntities;
};

const setMyDegrees = (state: any, action: any) => {
  state.myDegrees = action.payload.entities;
  state.totalMyDegrees = action.payload.totalEntities;
};

export const degreesSlice = createSlice({
  name: 'degrees',
  initialState,
  reducers: {
    setDegrees,
    setMyDegrees,
  },
});
export default degreesSlice;
