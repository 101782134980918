/**
* Last Modified: June 16, 2023
* Last Modified By: Sheriff Issaka
* Modification Description: Add file header
* Description: Handles the extraction of the user's courses
**/

import { useTranslation } from 'react-i18next';
import { TextInput, Tabs } from 'flowbite-react';
import { HiSearch } from 'react-icons/hi';
import './myLearning.scss';
import { useNavigate } from 'react-router-dom';
import { routePaths } from 'utils/constants';
import ProgramItem from '../../components/commonComponent/programItem';
import { getCourseEnrollment } from 'api/userApi'
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';

const Courses = (props: any) => {

  const { courses, handleOnClickViewCourseDetail } = props;

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 sm:grid-cols-2">
      {courses?.map((course: any) => {
        return (
          <div key={course?.id} className="py-2">
            <ProgramItem 
            key={course?.id} 
            data={course} 
            isDegree={false}
            handleOpenDetail={handleOnClickViewCourseDetail} />
          </div>
        );
      })}
    </div>
  );
};
const MyLearning = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const [courseEntitties, setCourseEntitties] = useState<any[]>([])

  const { data } = useQuery(['getCourseEnrollment'], () => getCourseEnrollment(), {
    staleTime: Infinity,
    onError: () => {
      setCourseEntitties([]);
    },
  });

  useEffect(() => {
    setCourseEntitties(data?.data)
  }, [data])

  const handleOnClickViewCourseDetail = (id: any) => {
    navigate(`${routePaths.COURSES}/${id}`);
  };

  return (
    <>
      <div className='myLearning grow'>
        <div className='bg-gray-900'>
          <div className='max-w-8xl mx-auto sm:px-8 px-4'>

            <div className=" text-white flex justify-between content-center py-5 px-2">
              <div className="flex content-center">
                <p className="text-3xl">{t('userProfile.myLearning')}</p>
              </div>
              <div className="sm:w-full md:w-1/2 lg:w-1/3 rounded-3xl search-input">
                <TextInput
                  data-testid="test-search-input"
                  rightIcon={HiSearch}
                  id="base"
                  type="text"
                  sizing="md"
                  placeholder="Search course"
                  //   onChange={debouncedResults}
                  autoFocus
                />
              </div>
            </div>
          </div>

        </div>
        <div className='max-w-8xl mx-auto grow  py-4 sm:px-8 px-4'>
          <div className="w-full px-1  ">
            <Tabs.Group aria-label="Tabs with underline" style="underline" className="tab-group">
              <Tabs.Item title="My Courses">
                <Courses courses={courseEntitties} handleOnClickViewCourseDetail={handleOnClickViewCourseDetail} />
              </Tabs.Item>
            </Tabs.Group>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyLearning;
