import { useTranslation } from 'react-i18next';
import { HiDocumentDownload, HiDotsVertical, HiOutlineCog, HiPlus } from 'react-icons/hi';
import { searchMaxLength } from 'utils/constants';
import './headerTable.scss';

// eslint-disable-next-line
type headerTableType = {
  placeholderSearch: string,
  buttonName: string,
  handleAddClick: Function,
  handleOnClickExport: Function,
  searchValue: string,
  setSearchValue: Function,
  fetchData?: Function,
  hideAddButton?: boolean,
};
const HeaderTable = (props: headerTableType) => {
  const [t] = useTranslation();
  const { placeholderSearch, buttonName, handleAddClick, handleOnClickExport, setSearchValue, searchValue, fetchData, hideAddButton } = props;

  const handleSearch = (e: any) => {
    const { key } = e;
    if (key === 'Enter') {
      fetchData && fetchData();
    }
  };

  return (
    <div className="admin-header-table flex flex-col sm:flex-row bg-white items-center justify-between p-4 flex-wrap shadow-md">
      <div className="w-full mb-4 sm:mb-0 text-gray-600 flex flex-1 flex-row items-center">
        <input
          className="w-1/2 border-1 border-gray-300 bg-white h-9 rounded-lg text-sm focus:outline-none"
          type="search"
          name="search"
          maxLength={searchMaxLength}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={placeholderSearch}
          onKeyPress={e => handleSearch(e)}
        />
        <span className="text-lg text-gray-200 ml-3 mr2">|</span>
        <div className="cursor-pointer text-gray-500 w-9 h-9 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-white dark:bg-gray-600 focus:!ring-2 group flex h-min w-fit items-center justify-center p-0.5 ml-1 text-center font-medium focus:z-10 rounded-lg">
          <HiOutlineCog className="h-6 w-6" />
        </div>
        <div className="cursor-pointer text-gray-500 w-9 h-9 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-white dark:bg-gray-600 focus:!ring-2 group flex h-min w-fit items-center justify-center p-2.5 text-center font-medium focus:z-10 rounded-lg">
          <HiDotsVertical className="h-6 w-6" />
        </div>
      </div>
      <div className="flex w-full sm:w-auto justify-start sm:justify-end flex-row gap-2">
        {!hideAddButton && (
          <button
            onClick={() => handleAddClick()}
            className="add-action w-60 sm:w-auto bg-blue-600 hover:bg-blue-700 px-2.5 h-9 text-white text-sm font-medium cursor-pointer focus:ring-4 focus:ring-blue-300 focus:!ring-2 group flex h-min w-fit items-center justify-center text-center focus:z-10 rounded-lg"
          >
            <HiPlus className="mr-1 h-4 w-4" />
            {buttonName}
          </button>
        )}
        <button
          onClick={() => handleOnClickExport()}
          className=" px-2.5 h-9 bg-white border border-gray-200 hover:bg-blue-100 hover:border-blue-600 text-gray-900 text-sm font-medium cursor-pointer focus:ring-4 focus:ring-blue-300 focus:!ring-2 group flex h-min w-fit items-center justify-center text-center focus:z-10 rounded-lg"
        >
          <HiDocumentDownload className="mr-1 h-4 w-4" />
          {t('export')}
        </button>
      </div>
    </div>
  );
};
export default HeaderTable;
